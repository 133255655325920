import { AxiosInstance } from "axios";
import { ZamiFile } from "../types";

export default class FileClient {
  constructor(private axios: AxiosInstance) {}

  async getFileUrl(fileId: string) {
    const response = await this.axios.get<{ url: string }>(
      `/file/${fileId}/url`
    );

    if (response.status === 200) {
      return response.data.url;
    }

    throw new Error("Failed to get file url");
  }

  async uploadFile(file: File) {
    const formData = new FormData();
    formData.append("file", file);

    const response = await this.axios.post<ZamiFile>(
      "/files/upload",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.status === 201) {
      return response.data;
    }

    throw new Error("Failed to upload file");
  }

  async uploadBlob(data: FormData) {
    const response = await this.axios.post<ZamiFile>("/files/upload", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.status === 201) {
      return response.data;
    }

    throw new Error("Failed to upload file");
  }
}
