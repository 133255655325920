import React from "react";
import styled from "styled-components";

import Button from "ui/Button";
import Badge from "ui/Badge";
import Header from "ui/Header";
import Input from "ui/Input";

import ContactsList from "./ContactsList";
import ContactFormModal from "./ContactFormModal";
import EmptyContactsView from "./EmptyContactsView";
import Box from "ui/Box";
import ContactsLabelFilter from "./ContactsLabelFilter";
import { useContacts } from "common/src/dexie/contacts";
import { useAppData } from "common/src/lib/appData";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0rem 2rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const SearchWrapper = styled.div`
  .search-input {
    max-width: 320px;
  }
`;

const Contacts = () => {
  const [isContactModalOpen, setContactModalOpen] = React.useState(false);
  const appData = useAppData();

  const {
    searchQuery,
    contacts,
    setSearchQuery,
    selectedLabels,
    setSelectedLabels,
  } = useContacts();

  const filteredContacts = React.useMemo(() => {
    return contacts ?? [];
  }, [contacts]);

  if (contacts === undefined) {
    return null;
  }

  return (
    <>
      <ContactFormModal
        open={isContactModalOpen}
        onOpenChange={setContactModalOpen}
        workspaceId={appData.workspace.id}
        onSubmitted={async () => {
          setSearchQuery("");
        }}
      />
      <Wrapper>
        <Header
          actions={
            <Button
              size="md"
              kind="primary"
              icon="plus"
              onClick={() => setContactModalOpen(true)}
            >
              Agregar contacto
            </Button>
          }
          badge={
            contacts.length !== undefined ? (
              <Badge
                label={`${contacts.length} contacto${
                  contacts.length !== 1 ? "s" : ""
                }`}
                size="lg"
                type="pill"
                variant="success"
              />
            ) : null
          }
          title="Contactos"
        >
          <SearchWrapper>
            <Box flex>
              <Box marginRight="1rem">
                <div className="search-input">
                  <Input
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Buscar contacto"
                    iconLeft="search"
                  />
                </div>
              </Box>

              <ContactsLabelFilter
                labels={selectedLabels}
                onChange={setSelectedLabels}
              />
            </Box>
          </SearchWrapper>
        </Header>

        <Container>
          {filteredContacts.length >= 1 ? (
            <ContactsList
              contacts={filteredContacts}
              onContactUpdated={async () => {
                setSearchQuery("");
              }}
              onEndReach={() => {
                // if (searchResults?.hasMore) {
                // fetchContacts({
                //   query: searchQuery,
                //   page: searchResults.page + 1,
                //   appendResults: true,
                // });
                // }
              }}
            />
          ) : (
            <EmptyContactsView setContactModalOpen={setContactModalOpen} />
          )}
        </Container>
      </Wrapper>
    </>
  );
};

export default Contacts;
