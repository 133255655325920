import React from "react";
import styled, { css } from "styled-components";
import Box from "ui/Box";
import Icon from "ui/Icon";
import whatsappBackground from "assets/whatsapp-bg.png";
import Text from "ui/Text";
import Field from "ui/Field";
import Input from "ui/Input";
import Button from "ui/Button";
import IconButton from "ui/IconButton";
import { BorderlessInput, CommandBarHeader, MenuItem } from "./common";

const WhatsappTempleCommandBarWindowWrapper = styled.div`
  min-height: 320px;
  display: flex;
  flex-direction: column;
`;

const ColumnWrapper = styled.div<{ isColumn: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.isColumn &&
    css`
      border-right: solid 1px ${(props) => props.theme.colors.gray200};
    `}
`;

const MessageBubble = styled(Box)`
  background-color: white;
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
  border-radius: 6px;
`;

const Tag = styled.div`
  display: inline-block;
  padding: 2px 10px;
  font-weight: 500;
  border-radius: 16px;
  background-color: ${(props) => props.theme.colors.primary50};
  color: ${(props) => props.theme.colors.primary700};
`;

const Form = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const SendWhatsappTemplateForm = ({
  variableValues,
  onVariableValuesChanged,
  onSubmit,
  isSubmitting,
}: {
  variableValues: Record<string, string>;
  onVariableValuesChanged: (newVariableValues: Record<string, string>) => void;
  onSubmit: () => void;
  isSubmitting: boolean;
}) => {
  const templateData = {} as any;

  return (
    <ColumnWrapper isColumn>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <Box padding="1rem" cols={1} flex direction="column">
          <Box cols={1}>
            {Object.keys(templateData.variableNames).map((key, i) => {
              return (
                <Field label={templateData.variableNames[key]}>
                  <Input
                    autoFocus={i === 0}
                    value={variableValues[key] ?? ""}
                    required
                    onChange={(e) =>
                      onVariableValuesChanged({
                        ...variableValues,
                        [key]: e.target.value,
                      })
                    }
                  />
                </Field>
              );
            })}
          </Box>
          <Box marginTop="1rem">
            <Button type="submit" block disabled={isSubmitting}>
              {isSubmitting ? "Enviando..." : "Enviar mensaje"}
            </Button>
          </Box>
        </Box>
      </Form>
    </ColumnWrapper>
  );
};

interface TemplatePreviewProps {
  template: any;
  variableValues: Record<string, string>;
}

const getTemplateBodyElements = (
  body: string,
  variables: Record<string, string>,
  variableValues: Record<string, string>
): React.ReactNode[] => {
  for (const variableKey of Object.keys(variables)) {
    if (body.includes(variableKey)) {
      const parts = body.split(
        new RegExp(
          `(${variableKey.replace(/\{/g, "\\{").replace(/\}/g, "\\}")})`,
          "g"
        )
      );

      return parts
        .map((part) => {
          if (part === variableKey) {
            if (variableValues[variableKey]) {
              return <Text inline>{variableValues[variableKey]}</Text>;
            }
            return <Tag>{variables[variableKey]}</Tag>;
          }

          return getTemplateBodyElements(part, variables, variableValues);
        })
        .flat();
    }
  }
  return [<Text inline>{body}</Text>];
};

export const TemplatePreview = ({
  template,
  variableValues,
}: TemplatePreviewProps) => {
  const templateData = template.data();
  return (
    <Box
      cols={1}
      style={{ backgroundImage: `url(${whatsappBackground})` }}
      padding="1.5rem"
    >
      {templateData.components.map((component: any) => {
        if (component.type === "BODY") {
          return (
            <MessageBubble padding="0.75rem 0.5rem">
              {getTemplateBodyElements(
                component.text,
                templateData.variableNames,
                variableValues
              )}
            </MessageBubble>
          );
        }
        return null;
      })}
    </Box>
  );
};

export const PickWhatsappTemplateCommandWindow = ({
  contactId,
  onSend,
}: {
  contactId: string;
  onSend: () => void;
}) => {
  const [templates] = React.useState<any[]>([]);
  const [highlightedTemplateIndex, setHighlightedTemplateIndex] =
    React.useState<number>(0);
  const [selectedTemplateIndex, setSelectedTemplateIndex] =
    React.useState<number>();
  const [variableValues, setVariableValues] = React.useState<
    Record<string, string>
  >({});
  const [isSending, setSending] = React.useState(false);

  const handleSend = async () => {
    setSending(true);
    // await functions.sendWhatsappTemplate(
    //   templates[selectedTemplateIndex!].id,
    //   contactId,
    //   variableValues
    // );
    onSend();
  };

  const templateForPreview =
    templates[selectedTemplateIndex ?? highlightedTemplateIndex];

  return (
    <WhatsappTempleCommandBarWindowWrapper>
      <CommandBarHeader>
        {selectedTemplateIndex !== undefined ? (
          <>
            <IconButton
              icon="arrowLeft"
              onClick={() => setSelectedTemplateIndex(undefined)}
            />
            <Text weight="medium" marginLeft="0.5rem">
              {templates[selectedTemplateIndex].data().name}
            </Text>
          </>
        ) : (
          <>
            <Icon icon="file" stroke="gray500" />
            <BorderlessInput placeholder="Buscar plantilla" autoFocus />
          </>
        )}
      </CommandBarHeader>

      <Box flex cols={1}>
        {selectedTemplateIndex === undefined ? (
          <ColumnWrapper isColumn={Boolean(highlightedTemplateIndex)}>
            {templates.map((template, i) => (
              <MenuItem
                onMouseEnter={() => setHighlightedTemplateIndex(i)}
                highlighted={highlightedTemplateIndex === i}
                onClick={() => setSelectedTemplateIndex(i)}
                key={template.id}
              >
                {template.data().name}
              </MenuItem>
            ))}
          </ColumnWrapper>
        ) : (
          <SendWhatsappTemplateForm
            variableValues={variableValues}
            onVariableValuesChanged={(newVariableValues) =>
              setVariableValues(newVariableValues)
            }
            isSubmitting={isSending}
            onSubmit={handleSend}
          />
        )}

        {templateForPreview && (
          <TemplatePreview
            template={templateForPreview}
            variableValues={variableValues}
          />
        )}
      </Box>
    </WhatsappTempleCommandBarWindowWrapper>
  );
};
