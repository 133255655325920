import { AxiosInstance } from "axios";
import { InstagramAccount } from "../types";
import { DataUpdateSubscriber } from "../client";

export interface FBInstagramAccount {
  id: string;
  username: string;
  profile_pic: string;
  igId: string;
  pageId: string;
}

export default class InstagramApiClient {
  constructor(
    private axios: AxiosInstance,
    private dataUpdatesSubscriber: () => DataUpdateSubscriber | undefined
  ) {}

  async getInstagramAccountsFromAccessToken(accessToken: string) {
    const resp = await this.axios.get<
      {
        access_token: string;
        name: string;
        id: string;
        instagram_business_account?: {
          id: string;
        };
      }[]
    >("/facebook-pages", {
      params: {
        access_token: accessToken,
      },
    });

    let instagramAccounts: (FBInstagramAccount & { pageId: string })[] = [];

    for (const page of resp.data) {
      if (page.instagram_business_account !== undefined && page.access_token) {
        const result = await this.axios.get<{
          data: FBInstagramAccount[];
        }>(`https://graph.facebook.com/v16.0/${page.id}/instagram_accounts`, {
          params: {
            access_token: page.access_token,
            fields: "id,username,profile_pic",
          },
        });

        instagramAccounts = [
          ...instagramAccounts,
          ...result.data.data.map((ig) => ({
            ...ig,
            igId: page.instagram_business_account!.id,
            pageId: page.id,
          })),
        ];
      }
    }

    return instagramAccounts;
  }

  async connectInstagramAccount(params: {
    accessToken: string;
    fbPageId: string;
    fbIgAccountId: string;
  }) {
    const response = await this.axios.post<{
      instagram_account: InstagramAccount;
    }>("/instagram/connect", {
      fb_user_access_token: params.accessToken,
      fb_page_id: params.fbPageId,
      fb_ig_account_id: params.fbIgAccountId,
    });

    if (response.status === 400) {
      const data = response.data as {
        error?: "third_party_messages_not_allowed";
      };

      if (data.error === "third_party_messages_not_allowed") {
        return {
          ok: false,
          thirdPartyMessagesBlocked: true,
          error: "third_party_messages_not_allowed",
        };
      }

      return {
        ok: false,
        error: "unknown_error",
      };
    } else if (response.status !== 200) {
      return {
        ok: false,
        error: "unknown_error",
      };
    }

    this.dataUpdatesSubscriber()?.onInstagramAccounts([
      response.data.instagram_account,
    ]);

    return {
      ok: true,
      instagramAccount: response.data.instagram_account,
    };
  }

  async disconnectInstagramAccount(id: string) {
    const response = await this.axios.delete(`/instagram/${id}`);

    if (response.status !== 200) {
      throw new Error("Failed to disconnect Instagram account");
    }
  }
}
