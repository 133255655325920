import { AxiosInstance } from "axios";
import { Team } from "../types";
import { DataUpdateSubscriber } from "../client";

export default class TeamsClient {
  constructor(
    private readonly axios: AxiosInstance,
    private dataUpdatesSubscriber: () => DataUpdateSubscriber | undefined
  ) {}

  public async createTeam(payload: { name: string; emoji: string }) {
    const response = await this.axios.post<Team>("/teams", payload);

    if (response.status !== 201) {
      throw new Error("Failed to create team");
    }

    this.dataUpdatesSubscriber()?.onTeams([response.data]);
  }

  async updateTeam(id: string, payload: { name: string; emoji: string }) {
    const response = await this.axios.put<Team>(`/teams/${id}`, payload);

    if (response.status !== 200) {
      throw new Error("Failed to update team");
    }

    this.dataUpdatesSubscriber()?.onTeams([response.data]);
  }

  async deleteTeam(id: string) {
    const response = await this.axios.delete(`/teams/${id}`);

    if (response.status !== 200) {
      throw new Error("Failed to delete team");
    }

    this.dataUpdatesSubscriber()?.onTeamsDeleted([id]);
  }
}
