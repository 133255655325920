import { WorkspaceMember } from "common/src/types";
import { useAppData } from "common/src/lib/appData";
import React, { FormEvent } from "react";
import styled from "styled-components";
import Box from "ui/Box";
import Button from "ui/Button";
import Field from "ui/Field";
import Icon from "ui/Icon";
import Input from "ui/Input";
import Modal from "ui/Modal";
import Select from "ui/Select";
import { useApiClient } from "common";

const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  border: solid 1px ${(props) => props.theme.colors.gray200};
  border-radius: 10px;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TeamsSelect = (props: {
  value: unknown;
  onChange: (val: unknown) => void;
}) => {
  const appData = useAppData();

  const options = Object.values(appData.teams).map((team) => ({
    label: `${team.emoji} ${team.name}`,
    value: team.id,
  }));

  return (
    <Select
      options={options}
      isMulti={true}
      value={props.value}
      onChange={props.onChange}
    />
  );
};

export default function TeamMemberModal({
  member,
  open,
  onOpenChange,
  onTeamMemberInvited,
}: {
  member?: WorkspaceMember | undefined;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onTeamMemberInvited: () => any | Promise<any>;
}) {
  const apiClient = useApiClient();
  const appData = useAppData();
  const [email, setEmail] = React.useState("");
  const [submitting, setSubmitting] = React.useState(false);
  const [roleId, setRoleId] = React.useState<string | null>(null);
  const [teams, setTeams] = React.useState<{ value: string; label: string }[]>(
    []
  );

  React.useEffect(() => {
    if (member) {
      if (member.isSuperuser) {
        setRoleId("admin");
      } else {
        setRoleId(member.roleId ?? null);
      }

      setTeams(
        member.teams
          ?.filter((teamId) => appData.teams[teamId] !== undefined)
          .map((teamId) => ({
            value: teamId,
            label: `${appData.teams[teamId]?.emoji} ${appData.teams[teamId]?.name}`,
          })) ?? []
      );
    }
  }, [member, appData.teams]);

  React.useEffect(() => {
    if (!open) {
      setTeams([]);
    }
  }, [open]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      if (member === undefined) {
        await apiClient.members.inviteTeamMembers([
          {
            email,
            role_id: roleId !== null && roleId !== "admin" ? roleId : undefined,
            superuser: roleId === "admin",
            teams: teams.map((option) => option.value),
          },
        ]);
      } else {
        await apiClient.members.updateMember(member.id, {
          roleId: roleId !== null && roleId !== "admin" ? roleId : undefined,
          isSuperuser: roleId === "admin",
          teams: teams.map((option) => option.value),
        });
      }
      await onTeamMemberInvited();
    } catch (err) {
    } finally {
      handleCloseModal();
    }
    setSubmitting(false);
  };

  const roleOptions = React.useMemo(() => {
    return [
      {
        value: "admin",
        label: "Administrador",
      },
      ...Object.values(appData.roles).map((role) => ({
        label: role?.name,
        value: role.id,
      })),
    ];
  }, [appData.roles]);

  const roleSelectValue = React.useMemo(() => {
    if (!roleId) return null;

    if (roleId === "admin") {
      return { label: "Administrador", value: "admin" };
    }

    const roleData = appData.roles[roleId];
    return { label: roleData.name, value: roleId };
  }, [roleId, appData]);

  const handleCloseModal = () => {
    onOpenChange(false);
  };

  return (
    <Modal open={open} onOpenChange={onOpenChange}>
      <form onSubmit={handleSubmit}>
        <IconWrapper>
          <Icon icon={member ? "edit" : "usersPlus"} />
        </IconWrapper>
        <Modal.Title>
          {member ? member.name : "Invitar miembro al equipo"}
        </Modal.Title>
        <Box marginTop="1.25rem">
          {!member && (
            <Field label="Correo electrónico">
              <Input
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Field>
          )}

          <Field label="Rol">
            <Select
              options={roleOptions}
              value={roleSelectValue}
              required
              onChange={(roleOption: any) => {
                setRoleId(roleOption.value);
              }}
            />
          </Field>

          <Field label="Equipos">
            <TeamsSelect
              value={teams}
              onChange={(val) => {
                setTeams(val as { value: string; label: string }[]);
              }}
            />
          </Field>
        </Box>

        <Box marginTop="2rem" flex>
          <Box marginRight="0.75rem" cols={1}>
            <Button
              type="button"
              block
              kind="secondary"
              size="md"
              onClick={handleCloseModal}
            >
              Cancelar
            </Button>
          </Box>
          <Box cols={1}>
            {member ? (
              <Button
                isLoading={submitting}
                block
                kind="primary"
                size="md"
                type="submit"
              >
                {submitting ? "Modificando..." : "Modificar"}
              </Button>
            ) : (
              <Button
                isLoading={submitting}
                block
                kind="primary"
                size="md"
                type="submit"
              >
                {submitting ? "Invitando..." : "Invitar"}
              </Button>
            )}
          </Box>
        </Box>
      </form>
    </Modal>
  );
}
