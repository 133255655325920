import React from "react";
import ProfilePic from "components/ProfilePic";
import styled from "styled-components";
import Button from "ui/Button";
import Text from "ui/Text";
import ChatComposer from "./ChatComposer";
import { Message as MessageType } from "common/src/types";
import { useCommandBar } from "components/commandBar";
import { useAppData } from "common/src/lib/appData";
import Box from "ui/Box";
import IconButton from "ui/IconButton";
import ConversationMessages from "./ConversationMessages";
import { useImagePreview } from "lib/imagePreview";
import { ComposerRef } from "./composer";
import { posthog } from "posthog-js";
import { IDBConversation } from "common/src/dexie/conversations";
import { useIDBConversationActions } from "common/src/dexie/conversations";
require("dayjs/locale/es");

const HeaderWrapper = styled.div`
  padding: 1rem;
  border-bottom: solid 1px ${(props) => props.theme.colors.gray200};
  background-color: white;
  display: flex;
  align-items: center;
  height: 4.5rem;
`;

const ConversationWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.gray25};
  min-height: 0px;
`;

const Actions = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const AssignButton = ({
  onClick,
  assignee,
}: {
  onClick: () => void;
  assignee: string;
}) => {
  const appData = useAppData();

  let inner = null;

  if (
    assignee.startsWith("wm:") &&
    appData.workspaceMembers[assignee.split(":")[1]]
  ) {
    const assigneeId = assignee.split(":")[1];
    const assigneeData = appData.workspaceMembers[assigneeId];
    inner = (
      <Box flex>
        <ProfilePic
          size="xxs"
          id={assigneeId}
          name={assigneeData.name}
          showStatusIcon
          status={assigneeData.presence}
        />
        <Text size="text_sm" weight="semibold" marginLeft="0.5rem">
          {assigneeData.name}
        </Text>
      </Box>
    );
  } else if (
    assignee.startsWith("t:") &&
    appData.teams[assignee.split(":")[1]]
  ) {
    const assigneeId = assignee.split(":")[1];
    const assigneeData = appData.teams[assigneeId];
    inner = (
      <Box flex>
        <Text size="text_sm" weight="semibold">
          {assigneeData.emoji} {assigneeData.name}
        </Text>
      </Box>
    );
  } else {
    inner = (
      <Box>
        <Text size="text_sm" weight="semibold">
          Sin asignar
        </Text>
      </Box>
    );
  }

  return (
    <Button
      kind="secondary"
      size="md"
      onClick={onClick}
      disabled={!appData.permissions.canAssignConversations}
      style={{ marginRight: "0.50rem" }}
    >
      {inner}
    </Button>
  );
};

const Header = ({ conversation }: { conversation: IDBConversation }) => {
  const [isClosing, setClosing] = React.useState(false);
  const commandBar = useCommandBar();
  const appData = useAppData();
  const convActions = useIDBConversationActions();
  const imagePreview = useImagePreview();
  const handleCloseClicked = async () => {
    try {
      setClosing(true);
      await convActions.closeConversation(conversation);
      posthog.capture("Conversation Closed", {
        conversationId: conversation.id,
        channel: conversation.data.lastMessage?.message?.channel,
      });
    } catch (err) {
      console.log(err);
      setClosing(false);
    }
  };

  const conversationData = conversation.data;

  return (
    <HeaderWrapper>
      <Box
        style={{ cursor: "pointer" }}
        onClick={() => {
          if (conversationData.contact.profilePicture) {
            imagePreview.setPreview({
              type: "image",
              fileId: conversationData.contact.profilePicture.id,
              contact: {
                id: conversationData.contact.id,
                data: conversationData.contact,
              },
            });
          }
        }}
      >
        <ProfilePic
          id={conversationData.contact.id}
          name={conversationData.contact.name}
          fileId={conversationData.contact.profilePicture?.id}
          showChannelBadgeFor={conversationData.lastMessage?.message?.channel}
        />
      </Box>
      <Text size="text_lg" weight="semibold" color="gray900" marginLeft="1rem">
        {conversationData.contact.name}
      </Text>
      <Actions>
        {conversationData.closed && (
          <>
            <Box>
              <Button
                size="md"
                kind="secondary"
                icon="inbox"
                onClick={async () => {
                  convActions.openConversation(conversation);
                }}
              >
                Reabrir
              </Button>
            </Box>
          </>
        )}

        {!conversationData.closed && (
          <>
            {conversationData &&
              (conversationData.assignee ||
                appData.permissions.canAssignConversations) &&
              !conversationData.contact.zamiId && (
                <AssignButton
                  assignee={conversationData.assignee}
                  onClick={() =>
                    commandBar.openAssignConversation(conversation)
                  }
                />
              )}
            {!conversationData.snoozedUntil && (
              <Box marginRight="0.50rem">
                <IconButton
                  size="md"
                  icon="bellSnooze"
                  kind="secondary"
                  onClick={() => {
                    commandBar.openToSnoozeConversation(conversation);
                  }}
                />
              </Box>
            )}
            <IconButton
              size="md"
              icon={isClosing ? "oval" : "check"}
              kind="primary-dark"
              onClick={handleCloseClicked}
              disabled={isClosing}
            />
          </>
        )}
      </Actions>
    </HeaderWrapper>
  );
};

const ChatWindow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  padding: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Conversation = ({ conversation }: { conversation?: IDBConversation }) => {
  const composerRef = React.useRef<ComposerRef>();
  const [quotedMessage, setQuotedMessage] = React.useState<MessageType>();

  React.useEffect(() => {
    setQuotedMessage(undefined);
  }, [conversation?.id]);

  return (
    <ConversationWrapper>
      {conversation && (
        <>
          <Header conversation={conversation} />
          <ChatWindow>
            <ConversationMessages
              contact={conversation.data.contact}
              conversationId={conversation.id}
              onReplyClicked={(message) => {
                setQuotedMessage(message);
                composerRef.current?.focus();
              }}
            />
          </ChatWindow>
          <ChatComposer
            conversation={conversation}
            quotedMessage={quotedMessage}
            ref={composerRef}
            onQuotedMessageClear={() => setQuotedMessage(undefined)}
          />
        </>
      )}
    </ConversationWrapper>
  );
};

export default React.memo(Conversation);
