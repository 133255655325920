import { useApiClient } from "common";
import { useAppData } from "common/src/lib/appData";
import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import styled, { css } from "styled-components";
import Box from "ui/Box";
import Button from "ui/Button";
import Dropdown from "ui/Dropdown";
import Icon from "ui/Icon";
import Modal from "ui/Modal";
import Text from "ui/Text";

const colors = [
  {
    label: "Verde",
    color: "#12B76A",
  },
  {
    label: "Verde Oscuro",
    color: "#027A48",
  },
  {
    label: "Rojo",
    color: "#F04438",
  },
  {
    label: "Rojo Oscuro",
    color: "#B42318",
  },
  {
    label: "Rosado",
    color: "#F670C7",
  },
  {
    label: "Amarillo",
    color: "#FAC515",
  },
  {
    label: "Naranja",
    color: "#F79009",
  },
  {
    label: "Violeta",
    color: "#875BF7",
  },
  {
    label: "Turquesa",
    color: "#2ED3B7",
  },
  {
    label: "Azul",
    color: "#0BA5EC",
  },
];

const Container = styled.div<{ focused: boolean }>`
  position: relative;
  min-height: 32px;
  display: flex;
  align-items: center;
  border: solid 1px transparent;
  border-radius: 8px;
  padding: 0.5rem 0.75rem;

  ${(props) =>
    props.focused &&
    css`
      &:hover {
        border: solid 1px transparent;
      }
    `}

  &:hover {
    border: solid 1px ${(props) => props.theme.colors.gray300};
  }
`;

const InputWrapper = styled.div<{ focused?: boolean }>`
  background-color: white;
  position: absolute;
  z-index: 1;
  top: -1px;
  left: -1px;
  right: -1px;
  border: solid 1px transparent;
  border-radius: 8px;

  &:hover {
    border: solid 1px ${(props) => props.theme.colors.gray300};
  }

  ${(props) =>
    props.focused &&
    css`
      border: solid 1px ${(props) => props.theme.colors.gray300};
    `}
`;

const Input = styled.input`
  padding: 0.5rem 0;
  border-radius: 8px;
  border: none;
  flex: 1;
  min-width: 50px;

  &::placeholder {
    font-size: 14px;
    line-height: 20px;
    color: ${(props) => props.theme.colors.gray500};
  }

  &:focus {
    outline: none;
  }
`;

const MenuWrapper = styled.div`
  border-top: solid 1px ${(props) => props.theme.colors.gray300};
  padding: 0.5rem 0.75rem;
  max-height: 320px;
  overflow-y: auto;
`;

const CreateTagButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition-duration: 200ms;
  padding: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background-color: ${(props) => props.theme.colors.gray50};
    border-radius: 8px;
  }
`;

const PlusWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.gray100};
  border-radius: 50%;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  cursor: pointer;
  margin-right: 0.25rem;
`;

function CreateTagButton(props: { label: string; onClick: () => void }) {
  return (
    <CreateTagButtonWrapper onClick={props.onClick}>
      <PlusWrapper>
        <Icon stroke="gray600" icon="plus2" size={12} />
      </PlusWrapper>
      <Text
        size="text_sm"
        color="gray700"
        style={{ minWidth: 0, textOverflow: "ellipsis", overflow: "hidden" }}
      >
        Crear{" "}
        <Text inline size="text_sm" weight="semibold" color="gray900">
          {props.label}
        </Text>
      </Text>
    </CreateTagButtonWrapper>
  );
}

const TagOptionWrapper = styled.div`
  padding: 0.5rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding-right: 1.5rem;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.gray50};
  }
`;

function TagOption(props: {
  color: string;
  label: string;
  onClick?: () => void;
}) {
  return (
    <TagOptionWrapper onClick={props.onClick}>
      <div
        style={{
          width: 8,
          height: 8,
          borderRadius: 4,
          backgroundColor: props.color,
          marginRight: 8,
        }}
      />
      <Text
        size="text_sm"
        weight="medium"
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          flex: 1,
          textOverflow: "ellipsis",
        }}
      >
        {props.label}
      </Text>
    </TagOptionWrapper>
  );
}

const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  margin-right: 0.5rem;
  background-color: red;
`;

const TagWrapper = styled.div`
  border: solid 1px ${(props) => props.theme.colors.gray200};
  border-radius: 24px;
  padding: 0rem 6px;
  display: flex;
  align-items: center;
  max-width: 130px;
  margin: 0.25rem;

  &:first-child {
    margin-left: 0rem;
  }
`;

export function Label(props: { onRemove?: () => void; labelId: string }) {
  const appData = useAppData();
  const label = appData.labels[props.labelId];

  if (!label) {
    return null;
  }

  return (
    <TagWrapper>
      <Dot style={{ backgroundColor: label?.color }} />
      <Text
        size="text_xs"
        weight="medium"
        style={{
          marginRight: 4,
          flex: 1,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {label?.label}
      </Text>
      {props.onRemove !== undefined && (
        <div style={{ cursor: "pointer" }} onClick={props.onRemove}>
          <Icon icon="x" stroke="gray400" size={10} />
        </div>
      )}
    </TagWrapper>
  );
}

const TagsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const FlexContents = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 44px;
  padding: 0rem 0.75rem;
`;

const DotsWrapper = styled.div`
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  transition-duration: 200ms;

  &:hover {
    background-color: ${(props) => props.theme.colors.gray50};
  }
`;

function DeleteLabelModal(props: {
  labelId: string | undefined;
  onOpenChange: (open: boolean) => void;
}) {
  const apiClient = useApiClient();
  return (
    <Modal open={props.labelId !== undefined} onOpenChange={props.onOpenChange}>
      <Modal.Title>
        ¿Estás seguro de que quieres eliminar esta etiqueta?
      </Modal.Title>
      <Modal.Description style={{ marginTop: 4 }}>
        También se eliminará la etiqueta de todos los contactos que la tienen
        asignada. Esta acción no se puede deshacer.
      </Modal.Description>

      <Modal.Actions>
        <Box flex justifyContent="flex-end">
          <Box marginRight="0.5rem">
            <Button
              size="md"
              kind="secondary"
              onClick={() => props.onOpenChange(false)}
            >
              Cancelar
            </Button>
          </Box>
          <Button
            size="md"
            kind="danger"
            onClick={async () => {
              try {
                await apiClient.labels.deleteLabel(props.labelId!);
                props.onOpenChange(false);
              } catch (Err) {}
            }}
          >
            Eliminar etiqueta
          </Button>
        </Box>
      </Modal.Actions>
    </Modal>
  );
}

export default function InlineTagsInput(props: {
  onCreate: (label: { input: string; color: string }) => Promise<unknown>;
  onRemove: (labelID: string) => Promise<unknown>;
  onAssign: (labelID: string) => Promise<unknown>;
  labels: string[];
}) {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [focused, setFocused] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = React.useState("");
  const [isCreatingNewTag, setCreatingNewTag] = React.useState(false);
  const [labelToDelete, setLabelToDelete] = React.useState<string>();
  const appData = useAppData();

  const filteredLabels = React.useMemo(() => {
    return Object.keys(appData.labels).filter((labelId) => {
      if (props.labels.includes(labelId)) {
        return false;
      }

      if (inputValue.length > 0 && !labelId.includes(inputValue)) {
        return false;
      }

      return true;
    });
  }, [props.labels, appData.labels, inputValue]);

  const canCreateTag = inputValue.length > 0;

  const handleCreate = async (color: string) => {
    await props.onCreate({
      input: inputValue,
      color,
    });
    setInputValue("");
    setCreatingNewTag(false);
    setFocused(false);
  };

  React.useEffect(() => {
    if (!focused) {
      setInputValue("");
      setCreatingNewTag(false);
    }
  }, [focused]);

  return (
    <>
      <DeleteLabelModal
        labelId={labelToDelete}
        onOpenChange={() => setLabelToDelete(undefined)}
      />
      <Container
        focused={focused}
        onClick={() => {
          if (!focused) {
            setFocused(true);
          }
        }}
      >
        <TagsWrapper>
          {props.labels.length === 0 ? (
            <Text size="text_sm" color="gray500">
              Añade una etiqueta
            </Text>
          ) : (
            <>
              {props.labels.map((label) => (
                <Label labelId={label} />
              ))}
            </>
          )}
          {/* <Tag /> */}
        </TagsWrapper>
        {focused && (
          <OutsideClickHandler
            onOutsideClick={() => {
              if (!dropdownOpen) {
                console.log("outside");
                setFocused(false);
              }
            }}
          >
            <InputWrapper focused={focused}>
              {!isCreatingNewTag && (
                <FlexContents>
                  {props.labels.map((label) => (
                    <Label
                      labelId={label}
                      onRemove={() => {
                        console.log("yak");
                        props.onRemove(label);
                      }}
                    />
                  ))}
                  <Input
                    autoFocus
                    onFocus={() => setFocused(true)}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    ref={inputRef}
                    placeholder="Añade una etiqueta"
                  />
                </FlexContents>
              )}

              {focused && (filteredLabels.length > 0 || canCreateTag) && (
                <MenuWrapper
                  style={{
                    borderTop: isCreatingNewTag ? "none" : undefined,
                  }}
                >
                  {!isCreatingNewTag && (
                    <>
                      <div>
                        {filteredLabels.map((labelKey) => {
                          if (props.labels.includes(labelKey)) {
                            return null;
                          }

                          if (
                            inputValue.length > 0 &&
                            !labelKey.includes(inputValue)
                          ) {
                            return null;
                          }

                          const labelData = appData.labels[labelKey];

                          return (
                            <div style={{ position: "relative" }}>
                              <TagOption
                                label={labelData.label}
                                color={labelData.color}
                                onClick={async () => {
                                  await props.onAssign(labelKey);
                                  setInputValue("");
                                  setFocused(false);
                                }}
                                key={labelKey}
                              />

                              <div
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  bottom: 0,
                                  display: "flex",
                                  alignItems: "center",
                                  right: 0,
                                }}
                              >
                                <Dropdown
                                  onOpenChange={(isOpen) => {
                                    if (isOpen) {
                                      setDropdownOpen(true);
                                    } else {
                                      setDropdownOpen(false);
                                    }
                                  }}
                                  trigger={
                                    <DotsWrapper>
                                      <Icon icon="dotsVertical" />
                                    </DotsWrapper>
                                  }
                                >
                                  <Dropdown.Item
                                    onClick={(e) => {
                                      console.log("sup");
                                      setLabelToDelete(labelKey);
                                      setDropdownOpen(false);
                                      setFocused(false);
                                    }}
                                  >
                                    <Icon icon="trash" stroke="gray700" />
                                    <Text
                                      size="text_sm"
                                      weight="medium"
                                      style={{ marginLeft: 8 }}
                                    >
                                      Eliminar
                                    </Text>
                                  </Dropdown.Item>
                                </Dropdown>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      {canCreateTag && (
                        <CreateTagButton
                          label={inputValue}
                          onClick={() => setCreatingNewTag(true)}
                        />
                      )}
                    </>
                  )}

                  {isCreatingNewTag && (
                    <>
                      {colors.map((color) => (
                        <TagOption
                          label={color.label}
                          color={color.color}
                          onClick={() => handleCreate(color.color)}
                        />
                      ))}
                    </>
                  )}
                </MenuWrapper>
              )}
            </InputWrapper>
          </OutsideClickHandler>
        )}
      </Container>
    </>
  );
}
