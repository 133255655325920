import React from "react";
import { ObjectId } from "bson";
import dayjs from "dayjs";
import ProfilePic from "components/ProfilePic";
import styled, { css } from "styled-components";
import Text from "ui/Text";
import Badge from "ui/Badge";
import { useHistory } from "react-router-dom";
import Box from "ui/Box";
import { useAppData } from "common/src/lib/appData";
import { MessageChannel } from "common/src/types";
import ConversationListFilterDropdown from "./ConversationListFilterDropdown";
import DocumentTitle from "react-document-title";
import Favicon from "react-favicon";
import { useTimeAgoString } from "lib/time";
import { NewConversationButton } from "components/commandBar/newConversation";
import Icon from "ui/Icon";
import { Label } from "./InlineTagsInput";
import { IDBConversation } from "common/src/dexie/conversations";
import { useChat } from "./context";
import { useLiveQuery } from "dexie-react-hooks";
import { useIDB } from "common/src/dexie/idb";

const Wrapper = styled.div`
  width: 22.5rem;
  border-right: solid 1px ${(props) => props.theme.colors.gray200};
  display: flex;
  flex-direction: column;
`;

const ConversationWrapper = styled.div<{ isOpen: boolean }>`
  cursor: pointer;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto;
  padding: 1rem;
  border-bottom: solid 1px ${(props) => props.theme.colors.gray200};

  ${(props) =>
    props.isOpen &&
    css`
      background-color: ${props.theme.colors.gray50};
    `}
`;

const NameWrapper = styled.div`
  margin-bottom: 4px;
`;

const ConversationLastMessage = styled.div`
  grid-column: 2/4;
  grid-row: 2;
`;

const ProfilePicWrapper = styled.div`
  grid-column: 1;
  grid-row: 1/3;
  padding-right: 0.75rem;
`;

const Header = styled.div`
  height: 4.5rem;
  border-bottom: solid 1px ${(props) => props.theme.colors.gray200};
  padding: 1rem;
  display: flex;
  align-items: center;
`;

const ConversationsWrapper = styled.div`
  flex: 1;
  overflow: auto;
`;

const HambugerIconWrapper = styled.div`
  margin-left: -0.75rem;
  padding-left: 0.25rem;
  padding-right: 6px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  cursor: pointer;
`;

const Conversation = React.forwardRef<
  HTMLDivElement,
  {
    isOpen: boolean;
    inbox: string;
    conversation: IDBConversation;
  }
>(({ conversation, isOpen, inbox }, ref) => {
  const history = useHistory();
  const appData = useAppData();
  const idb = useIDB();

  const handleConversationClicked = () => {
    history.push(`/chat/${inbox}/${conversation.id}`);
  };

  const contact = useLiveQuery(async () => {
    const idbContact = await idb.contacts
      .where("id")
      .equals(conversation.data.contact.id)
      .first();

    return idbContact?.data ?? conversation.data.contact;
  }, [conversation.data.contact.id]);

  const lastMessageText = React.useMemo(() => {
    if (conversation.data.lastMessage?.isSystem) {
      return "";
    }

    if (conversation.data.lastMessage?.message?.type === "text") {
      return conversation.data.lastMessage.message?.body;
    } else if (conversation.data.lastMessage?.message?.type === "audio") {
      return "audio";
    } else if (conversation.data.lastMessage?.message?.type === "image") {
      return "image";
    } else if (conversation.data.lastMessage?.message?.type === "sticker") {
      return "sticker";
    } else if (conversation.data.lastMessage?.message?.type === "deleted") {
      return "Mensaje eliminado";
    } else if (
      conversation.data.lastMessage?.message?.type === "story_mention"
    ) {
      return "Te mencionó en una historia";
    } else if (conversation.data.lastMessage?.message?.type === "video") {
      return "video";
    }

    return "Nueva conversación";
  }, [conversation.data.lastMessage]);

  const timeAgoString = useTimeAgoString(
    conversation.data.lastMessage
      ? dayjs(conversation.data.lastMessage.time)
      : dayjs(new ObjectId(conversation.id).getTimestamp())
  );

  const badgeLabel = React.useMemo(() => {
    const lastMessageChannel = conversation.data.lastMessage?.message?.channel;
    if (
      lastMessageChannel === "instagram" &&
      Object.keys(appData.instagramAccounts).length > 1
    ) {
      const instagramAccount = Object.values(appData.instagramAccounts).find(
        (igAccount) =>
          igAccount.instagramId ===
          conversation.data.contact.instagramAccount
            ?.workspaceInstagramAccountIGSID
      );
      return instagramAccount?.username;
    } else if (
      lastMessageChannel === "whatsapp_web" &&
      Object.keys(appData.whatsappWebAccounts).filter(
        (key) => appData.whatsappWebAccounts[key].status === "active"
      ).length > 1
    ) {
      return appData.whatsappWebAccounts[conversation.data.channelId ?? ""]
        ?.name;
    }

    return undefined;
  }, [conversation, appData.instagramAccounts, appData.whatsappWebAccounts]);

  return (
    <ConversationWrapper
      onClick={handleConversationClicked}
      isOpen={isOpen}
      ref={ref}
    >
      <ProfilePicWrapper>
        <ProfilePic
          id={conversation.data.contact.id}
          name={conversation.data.contact.name}
          fileId={conversation.data.contact.profilePicture?.id}
          showChannelBadgeFor={conversation.data.channel as MessageChannel}
        />
      </ProfilePicWrapper>
      <NameWrapper>
        <Text className="name" weight="semibold" color="gray700">
          {conversation.data.contact.name}
        </Text>
      </NameWrapper>
      <Text size="text_xs" color="gray600">
        {timeAgoString}
      </Text>
      <ConversationLastMessage>
        <Text
          size="text_sm"
          color="gray600"
          style={{
            overflow: "hidden",
            WebkitLineClamp: 2,
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
          }}
        >
          {lastMessageText}
        </Text>
      </ConversationLastMessage>

      {(badgeLabel !== undefined ||
        ((contact ?? conversation.data.contact).labels?.length ?? 0) > 0) && (
        <Box
          marginTop="0.25rem"
          flex
          justifyContent="flex-end"
          alignItems="center"
          style={{ gridColumn: "1/4" }}
        >
          {((contact ?? conversation.data.contact).labels?.length ?? 0) > 0 && (
            <Box flex>
              {(contact ?? conversation.data.contact).labels!.map((labelId) => (
                <Label key={labelId} labelId={labelId} />
              ))}
            </Box>
          )}
          {badgeLabel !== undefined && (
            <Badge
              label={badgeLabel}
              size="sm"
              variant={
                conversation.data.lastMessage.channel === "instagram"
                  ? "pink"
                  : "success"
              }
            />
          )}
        </Box>
      )}
    </ConversationWrapper>
  );
});

const ConversationList = ({
  conversations,
  inbox,
  selectedConversationId,
  onInboxesToggle,
  shouldInboxesFloat,
}: {
  selectedConversationId?: string;
  shouldInboxesFloat: boolean;
  onInboxesToggle: () => void;
  inbox: string;
  conversations: IDBConversation[];
}) => {
  const appData = useAppData();
  const chat = useChat();
  const observerTarget = React.useRef(null);
  const [conversationDisplayLimit, setConversationDisplayLimit] =
    React.useState(50);

  let inboxName = "";

  const onEndReached = React.useCallback(() => {
    console.log("end reached");
    setConversationDisplayLimit((prev) => prev + 50);
  }, [setConversationDisplayLimit]);

  console.log(conversationDisplayLimit);

  const hasLoadedConversations = conversations.length > 0;

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const isIntersecting = entries[0].isIntersecting;

      if (isIntersecting && hasLoadedConversations) {
        onEndReached();
      }
    });

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [onEndReached, hasLoadedConversations]);

  React.useEffect(() => {
    setConversationDisplayLimit(50);
  }, [
    chat.conversationsOrderFilter,
    chat.conversationsStatusFilter,
    inboxName,
  ]);

  if (inbox === "inbox") {
    inboxName = "Bandeja de entrada";
  }

  if (inbox === "unassigned") {
    inboxName = "Sin asignar";
  }

  if (inbox === "pendingResponse") {
    inboxName = "Sin responder";
  }

  if (inbox.startsWith("assigned-")) {
    const assigneeId = inbox.split("assigned-")[1];
    inboxName = appData.workspaceMembers[assigneeId].name;
  }

  if (inbox.startsWith("team-")) {
    const assigneeId = inbox.split("team-")[1];
    inboxName = appData.teams[assigneeId].name;
  }

  return (
    <DocumentTitle
      title={
        conversations.length > 0 ? `(${conversations.length}) Zami` : "Zami"
      }
    >
      <Wrapper>
        <Favicon
          alertCount={conversations.length > 9 ? "+" : conversations.length}
          url={`${window.location.origin}/favicon.ico`}
        />
        <Header>
          <Box flex alignItems="center" cols={1}>
            {shouldInboxesFloat && (
              <HambugerIconWrapper onClick={onInboxesToggle}>
                <Icon icon="hambugerMenu" size={16} />
              </HambugerIconWrapper>
            )}
            {/* {shouldInboxesFloat && (
              <IconButton
                icon="layoutLeft"
                size="md"
                onClick={onInboxesToggle}
              />
            )} */}
            <Text marginRight="0.5rem" weight="semibold" size="text_lg">
              {inboxName}
            </Text>
            <Badge
              label={conversations.length.toString()}
              variant="success"
              size="sm"
              type="pill"
            />
          </Box>
          <Box flex alignItems="center">
            <Box marginRight="0.5rem">
              <NewConversationButton />
            </Box>
            <ConversationListFilterDropdown />
          </Box>
        </Header>
        <ConversationsWrapper>
          {conversations.slice(0, conversationDisplayLimit).map((conv) => (
            <Conversation
              inbox={inbox}
              key={conv.id}
              conversation={conv}
              isOpen={selectedConversationId === conv.id}
            />
          ))}

          <div style={{ position: "relative" }}>
            {" "}
            <div
              ref={observerTarget}
              style={{
                position: "absolute",
                bottom: 0,
                width: 40,
                height: "70vh",
                backgroundColor: "transparent",
                pointerEvents: "none",
              }}
            />
          </div>
        </ConversationsWrapper>
      </Wrapper>
    </DocumentTitle>
  );
};

export default ConversationList;
