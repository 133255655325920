import { FBInstagramAccount } from "../client/instagram";
import { useApiClient } from "../context";
import React from "react";
import { useAppData } from "./appData";
import { useConfig } from "./config";

export function useConnectInstagram() {
  const apiClient = useApiClient();
  const config = useConfig();
  const [accessToken, setAccessToken] = React.useState<string>();
  const [loginLoading, setLoginLoading] = React.useState(false);
  const [instagramConnectionStatuses, setInstagramConnectionStatuses] =
    React.useState<Record<string, "loading" | undefined>>({});
  const appData = useAppData();
  const [
    instagramMessagesBlockedModalOpen,
    setInstagramMessagesBlockedModalOpen,
  ] = React.useState(false);
  const [instagramAccounts, setInstagramAccounts] = React.useState<
    (FBInstagramAccount & { pageId: string })[]
  >([]);

  React.useEffect(() => {
    if (window.FB) {
      FB.init({
        appId: config.fbAppId,
        cookie: true,
        xfbml: true,
        version: "v16.0",
      });
    }
  }, [config.fbAppId]);

  const startFbOauth = React.useCallback(() => {
    FB.login(
      (response) => {
        const accessToken = response.authResponse?.accessToken;
        if (accessToken) {
          setLoginLoading(true);
          setAccessToken(accessToken);
        }
      },
      {
        scope:
          "instagram_basic,instagram_manage_messages,pages_manage_metadata,pages_read_engagement,pages_show_list",
      }
    );
  }, [setLoginLoading, setAccessToken]);

  const handleConnectIgAccount = React.useCallback(
    async (fbPageId: string, fbIgAccountId: string) => {
      setInstagramConnectionStatuses((prev) => ({
        ...prev,
        [fbIgAccountId]: "loading",
      }));

      try {
        await apiClient.instagram.connectInstagramAccount({
          accessToken: accessToken!,
          fbPageId: fbPageId,
          fbIgAccountId: fbIgAccountId,
        });
      } finally {
        setInstagramConnectionStatuses((prev) => {
          const copy = { ...prev };
          delete copy[fbIgAccountId];
          return copy;
        });
      }
    },
    [accessToken, apiClient]
  );

  const connectedInstagramAccounts = React.useMemo(() => {
    return Object.values(appData.instagramAccounts).reduce((acc, curr) => {
      return {
        ...acc,
        [curr.instagramId]: true,
      };
    }, {} as Record<string, boolean>);
  }, [appData.instagramAccounts]);

  React.useEffect(() => {
    if (accessToken) {
      (async () => {
        const instagramAccounts =
          await apiClient.instagram.getInstagramAccountsFromAccessToken(
            accessToken
          );
        setInstagramAccounts(instagramAccounts);
        setLoginLoading(false);
      })();
    }
  }, [accessToken]);

  const forceAccessToken = React.useCallback(
    (token: string) => {
      setLoginLoading(true);
      setAccessToken(token);
    },
    [setAccessToken, setLoginLoading]
  );

  return {
    loginLoading,
    startFbOauth,
    handleConnectIgAccount,
    instagramAccounts,
    connectedInstagramAccounts,
    instagramConnectionStatuses,
    instagramMessagesBlockedModalOpen,
    setInstagramMessagesBlockedModalOpen,
    setAccessToken: forceAccessToken,
    accessToken,
  };
}
