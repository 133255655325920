import React from "react";
import styled from "styled-components";
import Button from "ui/Button";
import { BorderlessInput, CommandBarHeader, MenuItem } from "./common";
import { useCommandBar } from ".";
import Icon from "ui/Icon";
import Box from "ui/Box";
import ProfilePic from "components/ProfilePic";
import Text from "ui/Text";
import { useHistory } from "react-router-dom";
import { useContacts } from "common/src/dexie/contacts";

const Wrapper = styled.div`
  min-height: 320px;
  display: flex;
  flex-direction: column;
`;

const ScrollWindow = styled.div`
  max-height: 320px;
  overflow: auto;
`;

export const NewConversationButton = () => {
  const commandBar = useCommandBar();

  return (
    <div>
      <Button
        icon="compose"
        size="sm"
        kind="secondary"
        style={{ padding: "0.5rem" }}
        onClick={() => {
          commandBar.openCommandBarAtStage("newConversation");
        }}
      />
    </div>
  );
};

const NewConversationCommandWindow = ({ onExit }: { onExit: () => void }) => {
  const { contacts, searchQuery, setSearchQuery } = useContacts();
  // const [contacts, setContacts] =
  //   React.useState<firebase.firestore.QueryDocumentSnapshot<Contact>[]>();
  const history = useHistory();

  React.useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onExit();
      }
    };
    document.addEventListener("keyup", listener);

    return () => document.removeEventListener("keyup", listener);
  }, [onExit]);

  return (
    <Wrapper>
      <CommandBarHeader>
        <Icon icon="search" />
        <BorderlessInput
          autoFocus
          placeholder="¿A quién le quieres escribir?"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ flex: 1 }}
        />
      </CommandBarHeader>

      <ScrollWindow>
        {contacts.map((contactData) => {
          return (
            <MenuItem
              key={contactData.id}
              onClick={() => {
                history.push(`/chat/inbox/new:conv:${contactData.id}`);
                onExit();
              }}
            >
              <Box flex alignItems="center">
                <ProfilePic
                  id={contactData.id}
                  name={contactData.name}
                  fileId={contactData.profilePicture?.id}
                  size="xs"
                />
                <Text size="text_md" marginLeft="0.5rem">
                  {contactData.name}
                </Text>
              </Box>
            </MenuItem>
          );
        })}
      </ScrollWindow>
    </Wrapper>
  );
};

export default NewConversationCommandWindow;
