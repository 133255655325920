import React from "react";
import styled from "styled-components";
import QRCode from "react-qr-code";
import Box from "ui/Box";
import { ReactComponent as WhatsappLogo } from "assets/channelLogos/whatsapp.svg";
import { useConnectWhatsappWebAccount } from "common/src/lib/whatsapp";
import Text from "ui/Text";
import Button from "ui/Button";

import {
  Breadcrumbs,
  BreadcrumbChevron,
  BreadcrumbCurrentPage,
  BreadcrumbLink,
} from "ui/Breadcrumbs";
import Icon from "ui/Icon";
import { useHistory } from "react-router-dom";

const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  max-width: 70rem;
  padding: 0rem 2rem;
  margin: 0 auto;
`;

const Heading = styled.div`
  padding-top: 2rem;
  padding-bottom: 1.5rem;
  border-bottom: solid 1px ${(props) => props.theme.colors.gray200};
  margin-bottom: 2rem;
`;

const GrayBox = styled(Box)`
  background-color: ${(props) => props.theme.colors.gray50};
  margin-right: 1.5rem;
  padding: 2rem 1.5rem;
  border-radius: 12px;

  &:last-child {
    margin-right: 0;
  }
`;

// export function useConnectWhatsappWebAccount() {
//   const auth = useAuth();
//   const appData = useAppData();
//   const [whatsappWebAccountDoc, setWhatsappWebAccountDoc] =
//     React.useState<firebase.firestore.DocumentSnapshot<WhatsappWebAccount>>();
//   const [whatsappWebAccountRef, setWhatsappWebAccountRef] =
//     React.useState<firebase.firestore.DocumentReference<WhatsappWebAccount>>();

//   React.useEffect(() => {
//     (async () => {
//       if (whatsappWebAccountRef === undefined) {
//         // TODO: Implement
//         // const whatsappAccount = await db.whatsappWebAccounts.add({
//         //   workspaceId: auth.workspaceId!,
//         //   createdBy: appData.workspaceMemberSelf.id,
//         //   name: "default",
//         //   status: "new",
//         //   instanceName: "big-panda",
//         // });
//         // setWhatsappWebAccountRef(whatsappAccount);
//       }
//     })();
//   }, [whatsappWebAccountRef, auth.workspaceId, appData.workspaceMemberSelf]);

//   React.useEffect(() => {
//     return whatsappWebAccountRef?.onSnapshot((snap) => {
//       setWhatsappWebAccountDoc(snap);
//     });
//   }, [whatsappWebAccountRef]);

//   const handleRefreshQR = () => {
//     whatsappWebAccountRef?.update({
//       status: "new",
//     });
//   };

//   const qrCode = whatsappWebAccountDoc?.data()?.qr;

//   return { handleRefreshQR, qrCode, whatsappWebAccountDoc };
// }

const ConnectWhatsappWebAccount = () => {
  const history = useHistory();
  const { timedOut, success, qrCode, restart } = useConnectWhatsappWebAccount();
  return (
    <Wrapper>
      <Heading>
        <Breadcrumbs marginBottom="20px">
          <BreadcrumbLink to="/settings/channels">Canales</BreadcrumbLink>
          <BreadcrumbChevron />
          <BreadcrumbCurrentPage>Whatsapp</BreadcrumbCurrentPage>
        </Breadcrumbs>
        <Box flex alignItems="center">
          <WhatsappLogo />
          <Box marginLeft="20px" cols={1}>
            <Text size="display_sm" weight="semibold">
              Whatsapp
            </Text>
            <Text size="text_md" color="gray600">
              Recibe y responde a tus mensajes con Zami.
            </Text>
          </Box>
        </Box>
      </Heading>

      {!success ? (
        <Box flex>
          <GrayBox cols={1}>
            <Text size="text_xl" weight="semibold" marginBottom="1.5rem">
              Pasos para conectar WhatsApp
            </Text>
            <Text size="text_md" color="gray600" marginBottom="1.25rem">
              1. Abre WhatsApp en tu teléfono.
            </Text>
            <Box marginBottom="1.25rem">
              <Text size="text_md" color="gray600" inline>
                2. Toca Menú
              </Text>{" "}
              <Icon
                icon="whatsappDots"
                style={{ display: "inline", verticalAlign: "middle" }}
              />{" "}
              <Text
                size="text_md"
                color="gray600"
                marginBottom="1.25rem"
                inline
              >
                o Configuración
              </Text>{" "}
              <Icon
                icon="whatsappSettings"
                style={{ display: "inline", verticalAlign: "middle" }}
              />{" "}
              <Text
                size="text_md"
                color="gray600"
                marginBottom="1.25rem"
                inline
              >
                y selecciona Dispositivos vinculados.
              </Text>
            </Box>
            <Text size="text_md" color="gray600" marginBottom="1.25rem">
              3. Toca Vincular un dispositivo
            </Text>
            <Text size="text_md" color="gray600">
              4. Cuando se active la cámara, apunta tu teléfono hacia esta
              pantalla para escanear el código.
            </Text>
          </GrayBox>

          <GrayBox cols={1} flex alignItems="center" justifyContent="center">
            {!qrCode && <Icon icon="oval" fill="gray900" />}
            {qrCode && <QRCode value={qrCode} />}
            {timedOut && (
              <Button size="sm" icon="refresh" onClick={restart}>
                Actualizar código QR
              </Button>
            )}
          </GrayBox>
        </Box>
      ) : (
        <Box alignItems="center" flex direction="column">
          <Text size="text_xl" weight="semibold" marginBottom="0.75rem">
            ¡WhatsApp fue conectado correctamente!
          </Text>

          <Text size="text_md" weight="regular" marginBottom="1.5rem">
            Comienza a comunicarte con tus clientes a través de WhatsApp.
          </Text>

          <Button
            size="sm"
            icon="send"
            onClick={() => history.push("/chat/inbox")}
          >
            Ir al chat
          </Button>
        </Box>
      )}
    </Wrapper>
  );
};

export default ConnectWhatsappWebAccount;
