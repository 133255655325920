import React from "react";
import { useState } from "react";

import styled from "styled-components";

import Avatar from "ui/Avatar";
import Dropdown from "ui/Dropdown";
import Icon from "ui/Icon";
import { Table, THead, TBody, Tr, Td, Th, TableWrapper } from "ui/Table";

import { MessageChannel } from "common/src/types";
import { Contact } from "common/src/types";

import ChannelBadges from "./ChannelBadges";
import ContactFormModal from "./ContactFormModal";
import DeleteContactModal from "./DeleteContactModal";
import { useHistory } from "react-router-dom";
import { Label } from "components/chat/InlineTagsInput";
import Box from "ui/Box";
import { useAppData } from "common/src/lib/appData";

type ContactListProps = {
  contacts: ({ id: string } & Contact)[];
  onEndReach?: () => void;
  onContactUpdated?: () => void | Promise<void>;
};

type CurrentContactModal = {
  action: "edit" | "delete";
  contact: Contact | null;
  contactId?: string;
};

const DropdownTrigger = styled.button`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  height: 40px;
  width: 40px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.gray50};
  }
`;

const ContactRow = ({
  contact: c,
  onEditClick,
  onDeleteClick,
}: {
  contact: ContactListProps["contacts"][number];
  onEditClick?: () => void;
  onDeleteClick?: () => void;
}) => {
  const appData = useAppData();
  const history = useHistory();

  const shouldHidePhoneNumber = false;

  React.useEffect(() => {
    const tsNow = Math.trunc(Date.now() / 1000);
    if (c.createdAt !== undefined && c.createdAt > tsNow - 60) {
      // listen for firebase changes for 1 minute
      // const unsubscribe = db.contacts.doc(c.id).onSnapshot((doc) => {
      //   setLatestContactData(doc.data());
      // });

      const timeout = setTimeout(() => {
        // unsubscribe();
      }, 60000);

      return () => {
        clearTimeout(timeout);
        // unsubscribe();
      };
    }
  }, [c]);

  const contact = {
    ...c,
  };

  return (
    <Tr key={contact.id}>
      <Td colSpan={5}>
        <Avatar
          id={contact.id}
          name={contact.name}
          fileId={contact.profilePicture?.id}
          onClick={() => {
            const whatsappWebAccountId = Object.values(
              appData.whatsappWebAccounts
            )[0]?.id;
            if (whatsappWebAccountId && contact.whatsappJid && contact.id) {
              history.push(
                `/chat/inbox/new:conv:${contact.id}:${whatsappWebAccountId}`
              );
            }
          }}
        />
      </Td>
      <Td colSpan={6}>{contact.email}</Td>
      <Td colSpan={4}>{shouldHidePhoneNumber ? "" : contact.phone}</Td>
      <Td colSpan={4}>
        <Box flex>
          {contact.labels?.map((labelId) => (
            <Label key={labelId} labelId={labelId} />
          ))}
        </Box>
      </Td>
      <Td colSpan={4}>
        <ChannelBadges channels={getContactChannels(contact)} />
      </Td>
      <Td textAlign="end" colSpan={1}>
        <Dropdown
          trigger={
            <DropdownTrigger>
              <Icon icon="dotsVertical" stroke="gray500" />
            </DropdownTrigger>
          }
        >
          <Dropdown.Item onClick={onEditClick}>
            <div className="LeftSlot">
              <Icon icon="edit" stroke="gray700" />
            </div>
            Editar contacto
          </Dropdown.Item>

          <Dropdown.Item onClick={onDeleteClick} variant="error">
            <div className="LeftSlot">
              <Icon icon="trash" stroke="error700" />
            </div>
            Eliminar contacto
          </Dropdown.Item>
        </Dropdown>
      </Td>
    </Tr>
  );
};

const ContactsList = ({
  contacts,
  onEndReach,
  onContactUpdated,
}: ContactListProps) => {
  const observerTarget = React.useRef(null);
  const [currentContact, setCurrentContact] = useState<CurrentContactModal>({
    action: "edit",
    contact: null,
  });
  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const isIntersecting = entries[0].isIntersecting;

      if (isIntersecting) {
        onEndReach?.();
      }
    });

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [onEndReach]);

  return (
    <>
      <ContactFormModal
        contact={currentContact.contact}
        contactId={currentContact.contactId as string}
        open={Boolean(
          currentContact.action === "edit" && currentContact.contact
        )}
        onSubmitted={async () => {
          await onContactUpdated?.();
        }}
        onOpenChange={(open) => {
          if (!open) setCurrentContact({ action: "edit", contact: null });
        }}
      />

      <DeleteContactModal
        contact={currentContact.contact}
        contactId={currentContact.contactId as string}
        open={Boolean(
          currentContact.action === "delete" && currentContact.contact
        )}
        onDelete={async () => {
          await onContactUpdated?.();
        }}
        onOpenChange={(open) => {
          if (!open) setCurrentContact({ action: "delete", contact: null });
        }}
      />

      <TableWrapper>
        <Table style={{ position: "relative" }}>
          <THead backgroundColor="white">
            <Tr>
              <Th colSpan={5}>Nombre</Th>
              <Th colSpan={6}>Correo electrónico</Th>
              <Th colSpan={4}>Teléfono</Th>
              <Th colSpan={4}>Etiquetas</Th>
              <Th colSpan={4}>Canales</Th>
              <Th colSpan={1} />
            </Tr>
          </THead>

          <TBody>
            {contacts.map((contact) => (
              <ContactRow
                contact={contact}
                onEditClick={() => {
                  setCurrentContact({
                    contact,
                    contactId: contact.id,
                    action: "edit",
                  });
                }}
                onDeleteClick={() => {
                  setCurrentContact({
                    contact,
                    contactId: contact.id,
                    action: "delete",
                  });
                }}
              />
            ))}
          </TBody>
          <div
            ref={observerTarget}
            style={{
              position: "absolute",
              bottom: 0,
              width: 40,
              height: "35vh",
              backgroundColor: "transparent",
              pointerEvents: "none",
            }}
          />
        </Table>
      </TableWrapper>
    </>
  );
};

const getContactChannels = (contact: Contact) => {
  const channels: MessageChannel[] = [];
  if (contact.facebookUser) channels.push("page");
  if (contact.instagramAccount) channels.push("instagram");
  if (contact.whatsappJid) channels.push("whatsapp_web");
  if (contact.email) channels.push("email");
  if (contact.whatsappWebId) channels.push("whatsapp_web");

  return channels;
};

export default ContactsList;
