import React from "react";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import {
  BorderlessInput,
  CommandBarDialogContent,
  CommandBarHeader,
  MenuItem,
} from "./common";
import Icon from "ui/Icon";
import Box from "ui/Box";
import Text from "ui/Text";
import dayjs, { Dayjs } from "dayjs";
import {
  SnoozeDateOption,
  customOptionRegex,
  generateCustomOptionsWithNumber,
} from "./snoozeConversation";
import {
  AudioMessage,
  ComposingMessage,
  FileMessage,
  ImageMessage,
  TextMessage,
} from "common/src/types";
import { useApiClient } from "common";

const Wrapper = styled.div`
  min-height: 320px;
  display: flex;
  flex-direction: column;
`;

const ScrollWindow = styled.div`
  max-height: 480px;
`;

const DatePickerWrapper = styled(Box)`
  border-right: solid 1px ${(props) => props.theme.colors.gray200};
`;

const TimeMenuItem = styled(MenuItem)`
  width: 100%;
  color: ${(props) => props.theme.colors.gray700};
`;

type Option = { label: string; value: Dayjs };

export default function ScheduleMessage(props: {
  onFinish: () => void;
  contactId: string;
  channelId: string;
  message: ComposingMessage;
}) {
  const [mode, setMode] = React.useState<"options" | "datepicker">("options");
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [customDate, setCustomDate] = React.useState<Dayjs>(
    dayjs().startOf("day")
  );
  const anchorDate = dayjs().set("hour", 9).set("minute", 0).set("second", 0);
  const apiClient = useApiClient();

  const defaultOptions = React.useMemo(
    () => [
      {
        label: "En 2 horas",
        value: dayjs().add(2, "h"),
      },
      {
        label: "Mañana",
        value: anchorDate.add(1, "d"),
      },
      {
        label: "Lunes",
        value: anchorDate.add(1, "week").set("day", 1),
      },
      {
        label: "En una semana",
        value: anchorDate.add(1, "week"),
      },
      {
        label: "En un mes",
        value: anchorDate.add(1, "month"),
      },
    ],
    [anchorDate]
  );

  const filteredOptions = React.useMemo(() => {
    const filterFn = (option: Option): boolean => {
      return option.label.toLowerCase().includes(searchQuery.toLowerCase());
    };
    const filteredDefaultOptions = defaultOptions.filter(filterFn);

    if (filteredDefaultOptions.length > 0) {
      return filteredDefaultOptions;
    }

    const customMatch = searchQuery.match(customOptionRegex);

    let customOptions: Option[] = [];

    if (customMatch) {
      const theNumber = parseInt(customMatch[1], 10);
      customOptions = generateCustomOptionsWithNumber(theNumber);
    }

    return customOptions.filter(filterFn);
  }, [searchQuery, defaultOptions]);

  const { onFinish } = props;

  const handleScheduleMessage = React.useCallback(
    async (date: Dayjs) => {
      let theMessage:
        | TextMessage
        | ImageMessage
        | FileMessage
        | AudioMessage
        | undefined = undefined;

      if (props.message.type === "text") {
        theMessage = {
          direction: "outgoing",
          channel: "whatsapp_web",
          type: "text",
          body: props.message.message,
        };
      } else if (props.message.type === "image") {
        theMessage = {
          direction: "outgoing",
          channel: "whatsapp_web",
          type: "image",
          file: props.message.zamiFile!,
        };
      } else if (props.message.type === "audio") {
        theMessage = {
          direction: "outgoing",
          channel: "whatsapp_web",
          type: "audio",
          file: props.message.zamiFile!,
        };
      } else if (props.message.type === "document") {
        theMessage = {
          direction: "outgoing",
          channel: "whatsapp_web",
          type: "file",
          file: props.message.zamiFile!,
        };
      }

      if (theMessage) {
        await apiClient.scheduledMessages.createScheduledMessage(
          props.contactId,
          props.channelId,
          date,
          theMessage
        );
      }
      onFinish();
    },
    [onFinish, apiClient, props.contactId, props.channelId, props.message]
  );

  const customHourOptions = React.useMemo(() => {
    const startDate = customDate.startOf("day");

    return Array(48)
      .fill(true)
      .map((_, i) => startDate.add(30 * i, "minutes"));
  }, [customDate]);

  return (
    <CommandBarDialogContent
      style={{ width: mode === "datepicker" ? "initial" : undefined }}
    >
      <Wrapper>
        {mode === "options" ? (
          <CommandBarHeader>
            <Icon icon="search" />
            <BorderlessInput
              autoFocus
              placeholder="¿Cuándo quieres enviar el mensaje?"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{ flex: 1 }}
            />
          </CommandBarHeader>
        ) : (
          <CommandBarHeader
            onClick={() => setMode("options")}
            style={{ cursor: "pointer" }}
          >
            <Icon icon="arrowLeft" />
            <Text size="text_md" color="gray700" marginLeft="0.5rem">
              Atrás
            </Text>
          </CommandBarHeader>
        )}

        <ScrollWindow>
          {mode === "options" ? (
            <>
              {filteredOptions.map((option, i) => (
                <SnoozeDateOption
                  label={option.label}
                  key={i}
                  date={option.value}
                  onClick={() => handleScheduleMessage(option.value)}
                />
              ))}
              <MenuItem onClick={() => setMode("datepicker")}>
                <Text size="text_sm" weight="medium">
                  Personalizar
                </Text>
              </MenuItem>
            </>
          ) : (
            <Box flex justifyContent="center" style={{ height: 400 }}>
              <DatePickerWrapper
                paddingTop="2rem"
                paddingBottom="2rem"
                paddingRight="3rem"
                paddingLeft="3rem"
                flex
                justifyContent="center"
              >
                <DatePicker
                  inline
                  selected={customDate.toDate()}
                  onChange={(date: Date) => setCustomDate(dayjs(date))}
                />
              </DatePickerWrapper>

              <Box
                style={{ width: 200, overflowY: "auto", overflowX: "hidden" }}
                padding="1rem"
              >
                {customHourOptions.map((date, i) => (
                  <TimeMenuItem onClick={() => handleScheduleMessage(date)}>
                    {date.format("h:mm a")}
                  </TimeMenuItem>
                ))}
              </Box>
            </Box>
          )}
        </ScrollWindow>
      </Wrapper>
    </CommandBarDialogContent>
  );
}
