import React from "react";
import styled from "styled-components";
import {
  AccountAssignee,
  AccountAssigneeRule,
  FacebookPage,
  InstagramAccount,
  WhatsappWebAccount,
} from "common/src/types";
import Box from "ui/Box";
import Icon from "ui/Icon";
import Radio from "ui/Radio";
import Button from "ui/Button";
import Text from "ui/Text";
import Dropdown from "ui/Dropdown";
import theme from "ui/theme";
import { ChannelAccount } from "../commandBar/assignWhatsappWebAccount";
import { useCommandBar } from "../commandBar";
import { useAppData } from "common/src/lib/appData";
import ProfilePic from "../ProfilePic";

interface ChannelAssignerProps {
  accountId: string;
  accountType: ChannelAccount["type"];
}

const AssigneeDropdownOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2;
`;

const AssigneeDropdownOption = styled(Dropdown.Item)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 8px;
  border-radius: 6px;

  &:hover {
    background-color: ${theme.colors.gray50};
  }
`;

const AssigneeMember = ({
  assignee,
  onRemoveAssignee,
}: {
  assignee: string;
  onRemoveAssignee: (assigneeId: string) => void;
}) => {
  const appData = useAppData();
  const [assigneeType, assigneeId]: ["wm" | "t" | "b", string] = assignee.split(
    ":"
  ) as ["wm" | "t" | "b", string];

  if (assigneeType === "wm") {
    const workspaceMemberDoc = appData.workspaceMembers[assigneeId];
    const workspaceMemberData = workspaceMemberDoc;

    return (
      <Box
        flex
        alignItems="center"
        justifyContent="space-between"
        paddingTop="16px"
        paddingBottom="16px"
      >
        <Box flex alignItems="center" style={{ gap: 14 }}>
          <ProfilePic
            id={workspaceMemberDoc.id}
            name={workspaceMemberData.name}
          />
          <Text weight="semibold">{workspaceMemberData.name}</Text>
        </Box>

        <Box
          onClick={() => onRemoveAssignee(assignee)}
          style={{ cursor: "pointer" }}
        >
          <Icon icon="trash" stroke="gray600" size={20} />
        </Box>
      </Box>
    );
  }

  return null;
};

const assigneeButtonStyles = { border: `1px solid ${theme.colors.gray300}` };

export const AssigneeButton = ({
  assignee,
  onClick,
}: {
  assignee: AccountAssignee | undefined;
  onClick?: () => void;
}) => {
  const appData = useAppData();

  if (!assignee)
    return (
      <Button
        kind="secondary"
        icon="userFill"
        size="sm"
        style={assigneeButtonStyles}
        onClick={onClick}
      >
        Sin asignar
      </Button>
    );

  if (typeof assignee === "object" && assignee.type === "round_robin") {
    return (
      <Button
        kind="secondary"
        size="sm"
        style={assigneeButtonStyles}
        onClick={onClick}
      >
        <Text size="text_sm" weight="semibold">
          Asignación rotativa
        </Text>
      </Button>
    );
  }

  const assigneeId =
    typeof assignee === "object"
      ? assignee.assignee.split(":")[1]
      : assignee.split(":")[1];

  assignee = typeof assignee === "object" ? assignee.assignee : assignee;

  if (typeof assignee === "string" && assignee.startsWith("wm:")) {
    const name = appData.workspaceMembers[assigneeId].name;

    return (
      <Button
        kind="secondary"
        size="sm"
        style={assigneeButtonStyles}
        onClick={onClick}
      >
        <Box flex alignItems="center">
          <ProfilePic size="xxs" id={assigneeId} name={name} />

          <Text size="text_sm" weight="semibold" marginLeft="0.5rem">
            {name}
          </Text>
        </Box>
      </Button>
    );
  }

  let data = null;

  if (typeof assignee === "string" && assignee.startsWith("t:"))
    data = appData.teams[assigneeId];
  else
    data = {
      name: "",
      emoji: "",
    };

  return (
    <Button
      kind="secondary"
      size="sm"
      style={assigneeButtonStyles}
      onClick={onClick}
    >
      <Box flex alignItems="center">
        {data.emoji}
        <Text size="text_sm" weight="semibold" marginLeft="0.5rem">
          {data.name}
        </Text>
      </Box>
    </Button>
  );
};

const generateAccountAssignee = (
  assignee: AccountAssignee | undefined,
  assignationRule: AccountAssigneeRule
): AccountAssignee => {
  if (!assignee) return "";

  if (typeof assignee === "string") {
    return {
      type: "simple",
      rule: assignationRule,
      assignee,
    };
  }

  return {
    ...assignee,
    rule: assignationRule,
  };
};

const ChannelAssigner = ({ accountId, accountType }: ChannelAssignerProps) => {
  const commandBar = useCommandBar();
  const [assignationRule, setAssignationRule] =
    React.useState<AccountAssigneeRule>();
  const [channelAccount] = React.useState<ChannelAccount>();
  const [account] = React.useState<{
    id: string;
    data: WhatsappWebAccount | InstagramAccount | FacebookPage;
  }>();

  React.useEffect(() => {
    if (accountType === "whatsappWeb") {
      // return db.whatsappWebAccounts.doc(accountId).onSnapshot((doc) => {
      //   const accountDoc = { id: doc.id, data: doc.data()! };
      //   setChannelAccount({
      //     type: accountType,
      //     whatsappWebAccount: accountDoc,
      //   });
      //   setAccount(accountDoc);
      // });
    }

    if (accountType === "instagram") {
      // return db.instagramAccounts.doc(accountId).onSnapshot((doc) => {
      //   const accountDoc = { id: doc.id, data: doc.data()! };
      //   setChannelAccount({
      //     type: accountType,
      //     instagramAccount: accountDoc,
      //   });
      //   setAccount(accountDoc);
      // });
    }

    if (accountType === "page") {
      // return db.facebookPages.doc(accountId).onSnapshot((doc) => {
      //   const accountDoc = { id: doc.id, data: doc.data()! };
      //   setChannelAccount({
      //     type: accountType,
      //     facebookPage: accountDoc,
      //   });
      //   setAccount(accountDoc);
      // });
    }
  }, [accountId, accountType]);

  React.useEffect(() => {
    if (!channelAccount) return;
    if (!assignationRule) return;

    let updatedAssignee: AccountAssignee = "";

    if (channelAccount.type === "whatsappWeb") {
      updatedAssignee = generateAccountAssignee(
        channelAccount.whatsappWebAccount.data.assignee,
        assignationRule
      );

      // db.whatsappWebAccounts.doc(channelAccount.whatsappWebAccount.id).update({
      //   assignee: updatedAssignee,
      // });
      return;
    }

    if (channelAccount.type === "instagram") {
      updatedAssignee = generateAccountAssignee(
        channelAccount.instagramAccount.data.assignee,
        assignationRule
      );

      // db.instagramAccounts.doc(channelAccount.instagramAccount.id).update({
      //   assignee: updatedAssignee,
      // });
      return;
    }

    if (channelAccount.type === "page") {
      updatedAssignee = generateAccountAssignee(
        channelAccount.facebookPage.data.assignee,
        assignationRule
      );

      console.log(updatedAssignee, "thisis tbd");

      // db.facebookPages.doc(channelAccount.facebookPage.id).update({
      //   assignee: updatedAssignee,
      // });
      return;
    }
  }, [assignationRule, channelAccount]);

  React.useEffect(() => {
    if (
      !assignationRule &&
      account &&
      typeof account.data.assignee !== "string"
    ) {
      setAssignationRule(account.data.assignee?.rule);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account?.data.assignee, assignationRule]);

  if (!channelAccount) {
    return null;
  }

  const isLegacySimpleAssignation =
    account &&
    typeof account.data.assignee === "string" &&
    account.data.assignee !== "";

  const isSimpleAssignation =
    account &&
    typeof account.data.assignee === "object" &&
    account.data.assignee.type === "simple";

  const isRotativeAssignation =
    account?.data.assignee &&
    typeof account.data.assignee === "object" &&
    account.data.assignee.type === "round_robin";

  const handleOpenAssignChannelAccountModal = (isMultiple?: boolean) => {
    if (
      accountType === "whatsappWeb" &&
      channelAccount.type === "whatsappWeb"
    ) {
      commandBar.openAssignChannelAccount({
        type: accountType,
        whatsappWebAccount: channelAccount.whatsappWebAccount,
        multiple: isMultiple,
      });
    }

    if (accountType === "instagram" && channelAccount.type === "instagram") {
      commandBar.openAssignChannelAccount({
        type: accountType,
        instagramAccount: channelAccount.instagramAccount,
        multiple: isMultiple,
      });
    }

    if (accountType === "page" && channelAccount.type === "page") {
      commandBar.openAssignChannelAccount({
        type: accountType,
        facebookPage: channelAccount.facebookPage,
        multiple: isMultiple,
      });
    }
  };

  const handleRemoveAssignee = (assigneeToDelete: string) => {
    const accountAssignee = account?.data.assignee;

    if (!accountAssignee) return;

    if (
      accountType === "whatsappWeb" &&
      channelAccount.type === "whatsappWeb"
    ) {
      if (
        typeof accountAssignee === "string" ||
        accountAssignee.type === "simple" ||
        accountAssignee.assignees.length === 1
      ) {
        // db.whatsappWebAccounts
        //   .doc(channelAccount.whatsappWebAccount.id)
        //   .update({
        //     assignee: "",
        //   });

        return;
      }

      // db.whatsappWebAccounts.doc(channelAccount.whatsappWebAccount.id).update({
      //   assignee: {
      //     ...accountAssignee,
      //     assignees: accountAssignee.assignees.filter(
      //       (assignee) => assignee !== assigneeToDelete
      //     ),
      //   },
      // });

      return;
    }

    if (accountType === "instagram" && channelAccount.type === "instagram") {
      if (
        typeof accountAssignee === "string" ||
        accountAssignee.type === "simple" ||
        accountAssignee.assignees.length === 1
      ) {
        // db.instagramAccounts.doc(channelAccount.instagramAccount.id).update({
        //   assignee: "",
        // });

        return;
      }

      // db.instagramAccounts.doc(channelAccount.instagramAccount.id).update({
      //   assignee: {
      //     ...accountAssignee,
      //     assignees: accountAssignee.assignees.filter(
      //       (assignee) => assignee !== assigneeToDelete
      //     ),
      //   },
      // });

      return;
    }

    if (accountType === "page" && channelAccount.type === "page") {
      if (
        typeof accountAssignee === "string" ||
        accountAssignee.type === "simple" ||
        accountAssignee.assignees.length === 1
      ) {
        // db.facebookPages.doc(channelAccount.facebookPage.id).update({
        //   assignee: "",
        // });

        return;
      }

      // db.facebookPages.doc(channelAccount.facebookPage.id).update({
      //   assignee: {
      //     ...accountAssignee,
      //     assignees: accountAssignee.assignees.filter(
      //       (assignee) => assignee !== assigneeToDelete
      //     ),
      //   },
      // });

      return;
    }
  };

  if (!account) return null;

  const handleUnassignChannel = async () => {
    if (accountType === "whatsappWeb") {
      // return await db.whatsappWebAccounts.doc(account.id).update({
      //   assignee: "",
      // });
    }

    if (accountType === "instagram") {
      // return await db.instagramAccounts.doc(account.id).update({
      //   assignee: "",
      // });
    }

    // await db.facebookPages.doc(account.id).update({
    //   assignee: "",
    // });
  };

  return (
    <Box flex direction="column" marginBottom="48px">
      <Box flex>
        <Box cols={1} alignItems="center">
          <Text size="text_xl" weight="semibold" marginBottom="0.25rem">
            Asignar canal
          </Text>
          <Text size="text_md" weight="regular" color="gray600">
            Asigna automáticamente las conversaciones entrantes a los miembros o
            equipos que estén asignados a este canal.
          </Text>
        </Box>

        <Dropdown
          side="bottom"
          sideOffset={-15}
          trigger={
            <div>
              <AssigneeButton assignee={account.data.assignee} />
            </div>
          }
        >
          <AssigneeDropdownOptionsWrapper>
            <AssigneeDropdownOption onClick={handleUnassignChannel}>
              <Text weight="medium">Sin asignar</Text>
              {account.data.assignee === "" && (
                <Icon icon="check" stroke="primary600" />
              )}
            </AssigneeDropdownOption>

            <AssigneeDropdownOption
              onClick={() => handleOpenAssignChannelAccountModal()}
            >
              <Text weight="medium">Asignación simple</Text>
              {(isLegacySimpleAssignation || isSimpleAssignation) && (
                <Icon icon="check" stroke="primary600" />
              )}
            </AssigneeDropdownOption>

            <AssigneeDropdownOption
              onClick={() => handleOpenAssignChannelAccountModal(true)}
            >
              <Text weight="medium">Asignación rotativa</Text>
              {isRotativeAssignation && (
                <Icon icon="check" stroke="primary600" />
              )}
            </AssigneeDropdownOption>
          </AssigneeDropdownOptionsWrapper>
        </Dropdown>
      </Box>

      {account.data.assignee && (
        <>
          <Box marginTop="24px">
            <Text size="text_lg" weight="semibold">
              Reglas de asignación
            </Text>

            {(isLegacySimpleAssignation || isSimpleAssignation) && (
              <Box
                direction="column"
                marginTop="1rem"
                style={{ display: "inline-flex", gap: 16 }}
              >
                <Box
                  flex
                  alignItems="center"
                  style={{ gap: 12 }}
                  onClick={() => setAssignationRule("always")}
                >
                  <Radio active={assignationRule === "always"} />
                  <Text weight="medium">Asignar siempre</Text>
                </Box>

                <Box
                  flex
                  alignItems="center"
                  style={{ gap: 12 }}
                  onClick={() => setAssignationRule("only-online")}
                >
                  <Radio active={assignationRule === "only-online"} />
                  <Text weight="medium">
                    Asignar solo cuando esté disponible
                  </Text>
                </Box>
              </Box>
            )}

            {isRotativeAssignation && (
              <Box
                direction="column"
                marginTop="1rem"
                style={{ display: "inline-flex", gap: 16 }}
              >
                <Box
                  flex
                  alignItems="center"
                  style={{ gap: 12 }}
                  onClick={() => setAssignationRule("only-online")}
                >
                  <Radio active={assignationRule === "only-online"} />
                  <Text weight="medium">
                    Distribuir solo entre los que estén disponibles
                  </Text>
                </Box>

                <Box
                  flex
                  alignItems="center"
                  style={{ gap: 12 }}
                  onClick={() => setAssignationRule("always")}
                >
                  <Radio active={assignationRule === "always"} />
                  <Text weight="medium">
                    Distribuir entre todos, incluyendo a los miembros ausentes
                  </Text>
                </Box>
              </Box>
            )}
          </Box>

          {typeof account.data.assignee === "object" &&
            account.data.assignee.type === "round_robin" && (
              <Box marginTop="24px">
                <Text size="text_lg" weight="semibold">
                  Miembros asignados
                </Text>

                <Box marginTop="16px" marginBottom="16px">
                  {account.data.assignee.assignees.map((member) => (
                    <AssigneeMember
                      key={member}
                      assignee={member}
                      onRemoveAssignee={handleRemoveAssignee}
                    />
                  ))}
                </Box>

                <Box
                  onClick={() => handleOpenAssignChannelAccountModal(true)}
                  flex
                  alignItems="center"
                  style={{
                    gap: 4,
                    cursor: "pointer",
                  }}
                >
                  <Icon icon="plus" stroke="primary700" />
                  <Text size="text_sm" color="primary700" weight="semibold">
                    Agregar miembro
                  </Text>
                </Box>
              </Box>
            )}
        </>
      )}
    </Box>
  );
};

export default ChannelAssigner;
