import { DataUpdateSubscriber } from "../client";
import {
  Conversation,
  User,
  InstagramAccount,
  WhatsappWebAccount,
  Workspace,
  WorkspaceMember,
  Contact,
  Label,
  FacebookPage,
  Message,
  SavedReply,
  ContactNote,
  WorkspaceRole,
  Team,
  ScheduledMessage,
} from "../types";

interface InitialLoadWebsocketMessage {
  type: "initial_load";
  data: {
    workspaceMembers: WorkspaceMember[];
    whatsappWebAccounts: WhatsappWebAccount[];
    workspace: Workspace;
    instagramAccounts: InstagramAccount[];
    messengerAccounts: FacebookPage[];
    labels: Label[];
    teams: Team[];
    savedReplies: SavedReply[];
    roles: WorkspaceRole[];
    self: User;
  };
}

interface ContactSyncBatchMessage {
  type: "contact_sync_batch";
  contacts: Contact[];
}

interface ConversationSyncBatchMessage {
  type: "conversation_sync_batch";
  conversations: Conversation[];
}

export interface ConversationMessages {
  type: "conversation_messages";
  conversationId: string;
  messages: Message[];
  after?: string;
}

export interface NewConversationsPayload {
  type: "new_conversations";
  data: Conversation[];
}

export interface NewMessagesPayload {
  type: "new_messages";
  data: Message[];
}

export interface NewNotesPayload {
  type: "new_notes";
  data: ContactNote[];
}

export interface NewContactsPayload {
  type: "new_contacts";
  data: Contact[];
}

export interface ScheduledMessagesPayload {
  type: "scheduled_messages";
  data: ScheduledMessage[];
}

export interface WorkspaceMembersPayload {
  type: "workspace_members";
  data: WorkspaceMember[];
}

export type IncomingWebsocketMessage =
  | InitialLoadWebsocketMessage
  | ContactSyncBatchMessage
  | ConversationSyncBatchMessage
  | ConversationMessages
  | NewConversationsPayload
  | WorkspaceMembersPayload
  | NewMessagesPayload
  | NewContactsPayload
  | ScheduledMessagesPayload
  | NewNotesPayload;

export type MessageHandler = (message: IncomingWebsocketMessage) => void;

export default class ZamiWebsocketClient {
  baseUrl: string;
  authToken: string;
  ws?: WebSocket;
  connId?: string;
  shouldReconnect: boolean = true;
  dataUpdatesSubscriber: () => DataUpdateSubscriber | undefined;
  messageSubscribers: () => Record<string, MessageHandler>;
  conversationSubscriber?: (msg: ConversationMessages) => void;

  constructor(
    baseUrl: string,
    authToken: string,
    messageSubscribers: () => Record<string, MessageHandler>,
    dataUpdatesSubscriber: () => DataUpdateSubscriber | undefined
  ) {
    this.baseUrl = baseUrl;
    this.authToken = authToken;
    this.messageSubscribers = messageSubscribers;
    this.dataUpdatesSubscriber = dataUpdatesSubscriber;
  }

  forwardMessage(message: IncomingWebsocketMessage) {
    for (const handler of Object.values(this.messageSubscribers())) {
      handler(message);
    }
  }

  private async handleMessage(message: IncomingWebsocketMessage) {
    if (message.type === "initial_load") {
      this.dataUpdatesSubscriber()?.onSelf(message.data.self);
      this.dataUpdatesSubscriber()?.onWorkspace(message.data.workspace);
      this.dataUpdatesSubscriber()?.onWorkspaceMembers(
        message.data.workspaceMembers
      );
      this.dataUpdatesSubscriber()?.onWhatsappWebAccounts(
        message.data.whatsappWebAccounts
      );
      this.dataUpdatesSubscriber()?.onLabels(message.data.labels);
      this.dataUpdatesSubscriber()?.onInstagramAccounts(
        message.data.instagramAccounts
      );

      this.dataUpdatesSubscriber()?.onTeams(message.data.teams);

      this.dataUpdatesSubscriber()?.onSavedReplies(message.data.savedReplies);

      this.dataUpdatesSubscriber()?.onMessengerAccounts(
        message.data.messengerAccounts
      );

      this.dataUpdatesSubscriber()?.onRoles(message.data.roles);

      this.dataUpdatesSubscriber()?.onInitialLoadCompleted();
    } else if (message.type === "workspace_members") {
      this.dataUpdatesSubscriber()?.onWorkspaceMembers(message.data);
    }

    this.forwardMessage(message);
  }

  async sendMessagesSyncPing(lastUpdatedMessageTime: Date) {
    this.ws?.send(
      JSON.stringify({
        type: "message_sync_ping",
        last_message_updated_time: lastUpdatedMessageTime.toISOString(),
      })
    );
  }

  async sendNotesSyncPing(lastUpdatedNoteTime: Date) {
    this.ws?.send(
      JSON.stringify({
        type: "note_sync_ping",
        last_note_updated_time: lastUpdatedNoteTime.toISOString(),
      })
    );
  }

  async sendConversationSyncPing(lastConversationUpdatedTime: Date) {
    this.ws!.send(
      JSON.stringify({
        type: "conversation_sync_ping",
        lastUpdateAt: lastConversationUpdatedTime.toISOString(),
      })
    );
  }

  async sendContactSyncPing(lastContactUpdatedTime: Date) {
    this.ws?.send(
      JSON.stringify({
        type: "contact_sync_ping",
        lastUpdateAt: lastContactUpdatedTime.toISOString(),
      })
    );
  }

  start(payload?: {
    onUnauthorized?: () => void;
    onStateChange?: (state: string) => void;
  }) {
    this.ws = new WebSocket(
      `${this.baseUrl}/ws/connect?auth_token=${encodeURIComponent(
        this.authToken
      )}`
    );

    let interval: NodeJS.Timeout | undefined = undefined;

    // Event handler for when the connection is opened
    this.ws.onopen = () => {
      console.log("Connected to the WebSocket server");
      payload?.onStateChange?.("connected");
      interval = setInterval(() => {
        this.ws?.send(JSON.stringify({ type: "ping" }));
      }, 5000);
    };

    // Event handler for when a message is received from the server
    this.ws.onmessage = (event) => {
      const eventMessage = event.data as string;
      try {
        const messageData = JSON.parse(
          eventMessage
        ) as IncomingWebsocketMessage;
        this.handleMessage(messageData);
      } catch (err) {
        // TODO: Track err
        console.error(err);
      }
    };

    // Event handler for when the connection is closed
    this.ws.onclose = (event) => {
      payload?.onStateChange?.("closed");
      console.log(
        `Connection died ${event.wasClean ? "cleanly" : "unexpectedly"}`
      );

      if (interval) {
        clearInterval(interval);
      }

      if (this.shouldReconnect) {
        setTimeout(this.start.bind(this, payload), 1000);
      }
    };

    // Event handler for when an error occurs
    this.ws.onerror = (error: any) => {
      payload?.onStateChange?.("error");
      if (error.message === "Received bad response code from server: 401.") {
        payload?.onUnauthorized?.();
      }
      console.error("WebSocket error:", error);
    };
  }

  stop() {
    this.ws?.close();
    this.shouldReconnect = false;
  }
}
