import React from "react";
import styled, { css, keyframes } from "styled-components";
import * as Primitive from "@radix-ui/react-dropdown-menu";

const Root = styled(Primitive.Root)``;

const Trigger = styled(Primitive.Trigger)``;

const Portal = styled(Primitive.Portal)``;

const slideDownAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideLeftAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const slideUpAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideRightAndFade = keyframes`
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const Content = styled(Primitive.Content)`
  min-width: 220px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 0.5rem;
  border: ${(props) => `1px solid ${props.theme.colors.gray200}`};
  padding: 0.5rem;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.25),
    0px 10px 20px -15px rgba(22, 23, 24, 0.1);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &[data-side="top"] {
    animation-name: ${slideDownAndFade};
  }

  &[data-side="right"] {
    animation-name: ${slideLeftAndFade};
  }

  &[data-side="bottom"] {
    animation-name: ${slideUpAndFade};
  }

  &[data-side="left"] {
    animation-name: ${slideRightAndFade};
  }

  .LeftSlot {
    padding-right: 8px;
  }
`;

const Item = styled(Primitive.Item)<{ variant?: "default" | "error" }>`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.gray700};
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  position: relative;
  cursor: pointer;
  user-select: none;
  outline: none;

  &[data-disabled] {
    pointer-events: none;
  }

  &[data-highlighted] {
    background-color: ${(props) => props.theme.colors.gray50};
  }

  ${(props) =>
    props.variant === "error" &&
    css`
      color: ${props.theme.colors.error700};
    `};
`;

type DropdownProps = {
  trigger: React.ReactNode;
  children: React.ReactNode;
  side?: "top" | "right" | "bottom" | "left" | undefined;
  align?: "end" | "start" | undefined;
  onOpenChange?: (open: boolean) => void;
  sideOffset?: number;
};

const Dropdown = ({
  children,
  trigger,
  side,
  onOpenChange,
  align,
  sideOffset,
}: DropdownProps) => {
  return (
    <Root onOpenChange={onOpenChange}>
      <Trigger asChild>{trigger}</Trigger>
      <Portal>
        <Content
          style={{ zIndex: 99 }}
          align={align ?? "end"}
          side={side ?? "bottom"}
          sideOffset={sideOffset ?? 5}
        >
          {children}
        </Content>
      </Portal>
    </Root>
  );
};

Dropdown.Item = Item;

export default Dropdown;
