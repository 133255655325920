import { useApiClient } from "common";
import { useAppData } from "common/src/lib/appData";
import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import styled from "styled-components";
import Box from "ui/Box";
import Button from "ui/Button";
import Checkbox from "ui/Checkbox";
import Field from "ui/Field";
import Input from "ui/Input";
import { Table, TBody, Td, Th, THead, Tr } from "ui/Table";
import Text from "ui/Text";

const PermissionsTableWrapper = styled.div`
  border-top: solid 1px ${(props) => props.theme.colors.gray200};
  margin-top: 1.5rem;
  margin-bottom: 2rem;
`;

const HeadingRow = styled(THead)`
  background-color: ${(props) => props.theme.colors.gray50};
`;

const Header = styled(Box)`
  border-bottom: solid 1px ${(props) => props.theme.colors.gray200};
`;

const Permission = ({
  label,
  checked,
  onChange,
}: {
  label: string;
  checked: boolean;
  onChange: (val: boolean) => void;
}) => {
  return (
    <Tr>
      <Td>
        <Box flex alignItems="center">
          <Text
            size="text_sm"
            weight="medium"
            style={{ width: "100%", maxWidth: 400 }}
          >
            {label}
          </Text>
          <Box>
            <Checkbox checked={checked} onChange={onChange} />
          </Box>
        </Box>
      </Td>
    </Tr>
  );
};

const RoleForm = (
  props: RouteComponentProps<{ roleId?: string | undefined }>
) => {
  const history = useHistory();
  const apiClient = useApiClient();
  const appData = useAppData();

  const roleId = props.match.params.roleId;
  const role = roleId ? appData.roles[roleId] : undefined;

  const [roleName, setRoleName] = React.useState(role?.name ?? "");
  const [canAssignContacts, setCanAssignContacts] = React.useState<boolean>(
    role?.permissions.canAssignContacts ?? false
  );
  const [canSeeReporting, setCanSeeReporting] = React.useState<boolean>(
    role?.permissions.canSeeReporting ?? false
  );
  const [canAssignConversations, setCanAssignConversations] = React.useState(
    role?.permissions.canAssignConversations ?? false
  );
  const [canSeeUnassignedConversations, setCanSeeUnassignedConversations] =
    React.useState(role?.permissions.canSeeUnassignedConversations ?? false);
  const [canSeeAllConversations, setCanSeeAllConversations] = React.useState(
    role?.permissions.canSeeAllConversations ?? false
  );
  const [canSeeUnassignedContacts, setCanSeeUnassignedContacts] =
    React.useState(role?.permissions.canSeeUnassignedContacts ?? false);
  const [canSeeAllContacts, setCanSeeAllContacts] = React.useState(
    role?.permissions.canSeeAllContacts ?? false
  );

  const handleSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      (async () => {
        if (appData.workspace.id) {
          if (roleId && role) {
            await apiClient.roles.updateRole({
              ...role,
              name: roleName,
              permissions: {
                canAssignConversations,
                canSeeAllContacts,
                canSeeAllConversations,
                canSeeUnassignedContacts,
                canSeeUnassignedConversations,
                canAssignContacts,
                canSeeReporting,
              },
            });
          } else {
            await apiClient.roles.createRole({
              name: roleName,
              workspaceId: appData.workspace.id,
              isAutoGenerated: false,
              permissions: {
                canAssignConversations,
                canSeeAllContacts,
                canSeeAllConversations,
                canSeeUnassignedContacts,
                canSeeUnassignedConversations,
                canAssignContacts,
                canSeeReporting,
              },
            });
          }

          history.push("/settings/roles");
        }
      })();
    },
    [
      roleName,
      canAssignConversations,
      canSeeAllContacts,
      canSeeAllConversations,
      canSeeUnassignedContacts,
      canSeeUnassignedConversations,
      canAssignContacts,
      canSeeReporting,
      history,
      roleId,
      role,
      apiClient.roles,
      appData.workspace.id,
    ]
  );

  return (
    <Box cols={1} padding="0rem 2rem" style={{ overflow: "auto" }}>
      <form onSubmit={handleSubmit}>
        <Header
          padding="1.5rem 0rem"
          marginBottom="2rem"
          flex
          alignItems="center"
        >
          <Box cols={1}>
            <Text size="display_xs" weight="semibold">
              {role?.name ?? "Nuevo rol"}
            </Text>
            <Text size="text_md" weight="regular" color="gray600">
              {role
                ? `Llena el formulario debajo para modificar el rol.`
                : `Llena el formulario debajo para crear el rol. Una ves creado
              podrás asignarselo a miembros de tu espacio de trabajo.`}
            </Text>
          </Box>
          <Box flex justifyContent="flex-end" alignItems="center">
            <Button type="submit" size="md">
              Guardar
            </Button>
          </Box>
        </Header>
        <Box style={{ maxWidth: 400 }}>
          <Field label="Nombre del rol">
            <Input
              autoFocus
              placeholder="ej. Gerente de ventas"
              required
              value={roleName}
              onChange={(e) => setRoleName(e.target.value)}
            />
          </Field>
        </Box>

        <Text size="text_lg" weight="semibold" marginTop="2rem">
          Permisos
        </Text>

        <PermissionsTableWrapper>
          <Table>
            <HeadingRow>
              <Tr>
                <Th>Conversaciones</Th>
              </Tr>
            </HeadingRow>
            <TBody>
              <Permission
                label="Puede asignar conversaciones"
                checked={canAssignConversations}
                onChange={setCanAssignConversations}
              />

              <Permission
                label="Puede ver conversaciones sin asignar"
                checked={canSeeUnassignedConversations}
                onChange={setCanSeeUnassignedConversations}
              />

              <Permission
                label="Puede ver todas las conversaciones"
                checked={canSeeAllConversations}
                onChange={setCanSeeAllConversations}
              />
            </TBody>
            <HeadingRow>
              <Tr>
                <Th>Contactos</Th>
              </Tr>
            </HeadingRow>
            <TBody>
              <Permission
                label="Puede asignar contactos"
                checked={canAssignContacts}
                onChange={setCanAssignContacts}
              />
              <Permission
                label="Puede ver contactos sin asignar"
                checked={canSeeUnassignedContacts}
                onChange={setCanSeeUnassignedContacts}
              />
              <Permission
                label="Puede ver todos los contactos"
                checked={canSeeAllContacts}
                onChange={setCanSeeAllContacts}
              />
            </TBody>
            <HeadingRow>
              <Tr>
                <Th>Reportes</Th>
              </Tr>
            </HeadingRow>
            <TBody>
              <Permission
                label="Puede ver reportes"
                checked={canSeeReporting}
                onChange={setCanSeeReporting}
              />
            </TBody>
          </Table>
        </PermissionsTableWrapper>
      </form>
    </Box>
  );
};

export default RoleForm;
