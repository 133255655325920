import React from "react";
import { useIDB } from "common/src/dexie/idb";
import OutsideClickHandler from "react-outside-click-handler";
import styled, { css } from "styled-components";
import Box from "ui/Box";
import Icon, { IconKey } from "ui/Icon";
import Text from "ui/Text";
import Badge from "ui/Badge";
import { useAppData } from "common/src/lib/appData";
import ProfilePic from "components/ProfilePic";
import IconButton from "ui/IconButton";
import { useHistory } from "react-router-dom";
import { useLiveQuery } from "dexie-react-hooks";
import { getPendingResponseIDBCollection } from "lib/chat";

const Wrapper = styled.div<{ open: boolean; shouldFloat: boolean }>`
  width: 0px;
  margin-bottom: 1rem;
  transition: all 0.25s;
  white-space: nowrap;
  overflow: auto;
  height: 100%;
  background-color: white;

  ${(props) =>
    props.shouldFloat &&
    css`
      z-index: 10;
      position: absolute;
      left: 5rem;
    `}

  ${(props) =>
    props.open &&
    css`
      width: 18rem;
      border-right: solid 1px ${(props) => props.theme.colors.gray200};
    `}

  padding-top: 2.25rem;
`;

const Subwrapper = styled.div`
  padding: 0rem 1rem;
`;

const MenuWrapper = styled.div`
  margin-bottom: 0.25rem;
`;

const MenuHeader = styled.div`
  height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  user-select: none;
`;

const MenuItem = styled.div<{ active?: boolean }>`
  height: 2.5rem;
  padding: 0.5rem 0.75rem;
  margin-bottom: 0.25rem;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;

  &:hover {
    background-color: ${(props) => props.theme.colors.gray50};
  }

  ${(props) =>
    props.active &&
    css`
      background-color: ${(props) => props.theme.colors.gray50};
    `}
`;

const Menu = ({
  children,
  name,
}: {
  name: string;
  children: React.ReactNode;
}) => {
  const [open, setOpen] = React.useState(true);
  return (
    <MenuWrapper>
      <MenuHeader>
        <Text inline size="text_sm" weight="semibold">
          {name}
        </Text>
        <IconButton
          onClick={() => setOpen((o) => !o)}
          size="sm"
          icon={open ? "chevronUp" : "chevronDown"}
        />
      </MenuHeader>

      {open ? children : null}
    </MenuWrapper>
  );
};

const Inbox = ({
  icon,
  name,
  iconNode,
  count,
  onClick,
  active,
}: {
  icon?: IconKey | undefined;
  iconNode?: React.ReactNode;
  name: string;
  onClick: () => void;
  count?: number | undefined;
  active?: boolean;
}) => {
  return (
    <MenuItem onClick={onClick} active={active}>
      <Box flex alignItems="center" cols={1} style={{ minWidth: 0 }}>
        <Box marginRight="0.75rem">
          {icon !== undefined && <Icon icon={icon} size={20} />}
          {iconNode ?? null}
        </Box>
        <Text
          size="text_sm"
          weight="semibold"
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {name}
        </Text>
      </Box>

      {count !== undefined && (
        <Badge label={count.toString()} size="xs" type="circle" />
      )}
    </MenuItem>
  );
};

const Inboxes = ({
  selectedConversationId,
  shouldFloat,
  open,
  onOpenChange,
  inbox,
}: {
  selectedConversationId: string | undefined;
  shouldFloat: boolean;
  open: boolean;
  onOpenChange?: (open: boolean) => void;
  inbox: string;
}) => {
  const history = useHistory();
  const appData = useAppData();
  const idb = useIDB();
  const teams = React.useMemo(() => {
    if (appData.permissions.canSeeAllConversations) {
      return Object.values(appData.teams);
    }

    return Object.values(appData.teams).filter((team) =>
      appData.workspaceMemberSelf?.teams?.includes(team.id)
    );
  }, [appData]);

  const otherMembers = Object.values(appData.workspaceMembers).filter(
    (member) => member.id !== appData.workspaceMemberSelf.id
  );

  const myWorkspaceMember = appData.workspaceMemberSelf;

  const inboxesCount = useLiveQuery(async () => {
    const unassignedCount = await idb.conversations
      .where("[assignee+status]")
      .equals(["", "open"])
      .count();

    const countByAssignee: Record<string, number | undefined> = {};

    for (const member of Object.values(appData.workspaceMembers)) {
      countByAssignee[member.id] = await idb.conversations
        .where("[assignee+status]")
        .equals([`wm:${member.id}`, "open"])
        .count();
    }

    for (const team of teams) {
      countByAssignee[`t:${team.id}`] = await idb.conversations
        .where("[assignee+status]")
        .equals([`t:${team.id}`, "open"])
        .count();
    }

    const assignedToMeCount = countByAssignee[myWorkspaceMember.id] ?? 0;

    const pendingResponseCount = await getPendingResponseIDBCollection(
      idb,
      appData
    ).count();

    return {
      inbox: appData.permissions.canSeeUnassignedConversations
        ? assignedToMeCount + unassignedCount
        : assignedToMeCount,
      pendingResponse: pendingResponseCount,
      unassigned: unassignedCount,
      byAssignee: countByAssignee,
    };
  }, [
    teams,
    appData.permissions.canSeeUnassignedConversations,
    appData.permissions.canSeeAllConversations,
    myWorkspaceMember,
  ]);

  const getInboxPath = React.useCallback(
    (name: string) => {
      if (selectedConversationId) {
        return `/chat/${name}/${selectedConversationId}`;
      }

      return `/chat/${name}`;
    },
    [selectedConversationId]
  );

  const goToInbox = React.useCallback(
    (inboxName: string) => {
      history.push(getInboxPath(inboxName));
      if (shouldFloat) {
        onOpenChange?.(false);
      }
    },
    [getInboxPath, onOpenChange, history, shouldFloat]
  );

  return (
    <OutsideClickHandler
      onOutsideClick={(e) => {
        e.stopPropagation();
        if (shouldFloat && open) {
          e.preventDefault();
          onOpenChange?.(false);
        }
      }}
    >
      <Wrapper open={open} shouldFloat={shouldFloat}>
        <Subwrapper>
          <Box
            flex
            justifyContent="space-between"
            alignItems="center"
            marginBottom="1rem"
          >
            <Text size="text_md" weight="semibold">
              Bandejas
            </Text>

            {shouldFloat && (
              <IconButton
                icon="x"
                size="sm"
                onClick={() => onOpenChange?.(false)}
              />
            )}
          </Box>

          <Menu name="Conversaciones">
            <Inbox
              name="Bandeja de entrada"
              active={inbox === "inbox"}
              icon="inbox"
              onClick={() => {
                goToInbox("inbox");
              }}
              count={inboxesCount?.inbox}
            />
            <Inbox
              name="Sin responder"
              icon="circle"
              count={inboxesCount?.pendingResponse}
              active={inbox === "pendingResponse"}
              onClick={() => goToInbox("pendingResponse")}
            />
            <Inbox
              name="Asignadas a mi"
              iconNode={
                <ProfilePic
                  id={myWorkspaceMember.id}
                  name={myWorkspaceMember.name}
                  showStatusIcon
                  status={myWorkspaceMember.presence}
                  size="xxs"
                />
              }
              onClick={() => goToInbox(`assigned-${myWorkspaceMember.id}`)}
              active={inbox === `assigned-${myWorkspaceMember.id}`}
              count={inboxesCount?.byAssignee[myWorkspaceMember.id]}
            />
            {(appData.permissions.canSeeUnassignedConversations ||
              appData.permissions.canSeeAllConversations) && (
              <Inbox
                name="Sin asignar"
                icon="userCircle"
                onClick={() => goToInbox(`unassigned`)}
                active={inbox === "unassigned"}
                count={inboxesCount?.unassigned}
              />
            )}
          </Menu>

          {appData.permissions.canSeeAllConversations && (
            <>
              {otherMembers.length > 0 && (
                <Menu name="Asignadas">
                  {otherMembers.map((workspaceMember) => {
                    return (
                      <Inbox
                        name={workspaceMember.name}
                        key={workspaceMember.id}
                        onClick={() =>
                          goToInbox(`assigned-${workspaceMember.id}`)
                        }
                        active={inbox === `assigned-${workspaceMember.id}`}
                        iconNode={
                          <ProfilePic
                            id={workspaceMember.id}
                            name={workspaceMember.name}
                            showStatusIcon
                            status={workspaceMember.presence}
                            size="xxs"
                          />
                        }
                        count={inboxesCount?.byAssignee[workspaceMember.id]}
                      />
                    );
                  })}
                </Menu>
              )}
            </>
          )}

          {teams.length > 0 && (
            <Menu name="Por equipos">
              {teams.map((teamData) => {
                return (
                  <Inbox
                    name={teamData.name}
                    onClick={() => goToInbox(`team-${teamData.id}`)}
                    active={inbox === `team-${teamData.id}`}
                    iconNode={
                      <span>
                        <span
                          role="img"
                          aria-label={teamData.emoji}
                          style={{ fontSize: 14 }}
                        >
                          {teamData.emoji}
                        </span>
                      </span>
                    }
                    count={inboxesCount?.byAssignee[`t:${teamData.id}`]}
                  />
                );
              })}
            </Menu>
          )}
        </Subwrapper>
      </Wrapper>
    </OutsideClickHandler>
  );
};

export default Inboxes;
