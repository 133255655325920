import React, { FormEvent } from "react";
import { css } from "styled-components";
import { ReactComponent as ZamiLogo } from "assets/logo.svg";
import styled from "styled-components";
import { OnboardingStyles } from "./common";
import Box from "ui/Box";
import Text from "ui/Text";
import Button from "ui/Button";
import Field from "ui/Field";
import Input from "ui/Input";

const Wrapper = styled.div`
  max-width: 22.5rem;
  margin: 0 auto;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

const SelectButton = styled(Box)<{ selected: boolean }>`
  background-color: white;
  border: solid 1px ${(props) => props.theme.colors.gray300};
  border-radius: 8px;
  padding: 8px 14px;
  transition-duration: 200ms;
  height: 36px;
  cursor: pointer;
  margin-right: 12px;
  margin-top: 8px;

  ${(props) =>
    props.selected &&
    css`
      background-color: ${props.theme.colors.primary100};
      border: solid 1px ${props.theme.colors.primary500};
    `}

  &:last-child {
    margin-right: 0px;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.primary100};
  }
`;

export default function BusinessProfile() {
  const [name, setName] = React.useState("");
  const [teamSize, setTeamSize] = React.useState<string>();
  const [channelCount, setChannelCount] = React.useState<string>();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
  };

  return (
    <div>
      <OnboardingStyles />
      <form onSubmit={handleSubmit}>
        <Wrapper>
          <Box flex justifyContent="center" marginBottom="1rem">
            <ZamiLogo height={48} width={48} />
          </Box>
          <Text
            size="display_sm"
            weight="semibold"
            marginBottom="0.75rem"
            flex
            justifyContent="center"
            color="gray900"
          >
            Sobre tu negocio
          </Text>
          <Text
            size="text_md"
            marginBottom="2rem"
            color="gray600"
            style={{ textAlign: "center" }}
          >
            Ayudanos a personalizar tu experiencia
          </Text>
          <Field label="Nombre de tu negocio">
            <Input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Nombre"
              required
            />
          </Field>
          <Field label="¿Cuántas personas tiene tu equipo?">
            <Box flex>
              <SelectButton
                flex
                alignItems="center"
                selected={teamSize === "1-3"}
                onClick={() => setTeamSize("1-3")}
              >
                <Text size="text_sm" weight="medium" color="gray700">
                  1-3
                </Text>
              </SelectButton>

              <SelectButton
                flex
                alignItems="center"
                selected={teamSize === "4-10"}
                onClick={() => setTeamSize("4-10")}
              >
                <Text size="text_sm" weight="medium" color="gray700">
                  4-10
                </Text>
              </SelectButton>

              <SelectButton
                flex
                alignItems="center"
                selected={teamSize === "+10"}
                onClick={() => setTeamSize("+10")}
              >
                <Text size="text_sm" weight="medium" color="gray700">
                  +10
                </Text>
              </SelectButton>
            </Box>
          </Field>
          <Field label="¿Cuántos canales tienen? (Canal es cada cuenta de WhatsApp, Instagram o Messenger)">
            <Box flex>
              <SelectButton
                flex
                alignItems="center"
                selected={channelCount === "1-3"}
                onClick={() => setChannelCount("1-3")}
              >
                <Text size="text_sm" weight="medium" color="gray700">
                  1-3
                </Text>
              </SelectButton>

              <SelectButton
                flex
                alignItems="center"
                selected={channelCount === "4-10"}
                onClick={() => setChannelCount("4-10")}
              >
                <Text size="text_sm" weight="medium" color="gray700">
                  4-10
                </Text>
              </SelectButton>

              <SelectButton
                flex
                alignItems="center"
                selected={channelCount === "+10"}
                onClick={() => setChannelCount("+10")}
              >
                <Text size="text_sm" weight="medium" color="gray700">
                  +10
                </Text>
              </SelectButton>
            </Box>
          </Field>
          <Button size="md" block style={{ marginTop: "0.5rem" }} type="submit">
            Siguiente
          </Button>
        </Wrapper>
      </form>
    </div>
  );
}
