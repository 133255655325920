import React from "react";
import { WorkspaceMember } from "common/src/types";
import Box from "ui/Box";
import Button from "ui/Button";
import Modal from "ui/Modal";
import Text from "ui/Text";
import { useApiClient } from "common";

export const CancelTeamMemberInvitationModal = ({
  invitationToCancel,
  onOpenChange,
}: {
  invitationToCancel: WorkspaceMember | undefined;
  onOpenChange: (newOpen: boolean) => void;
}) => {
  const apiClient = useApiClient();
  const [cancelling, setCancelling] = React.useState(false);

  const handleCancel = React.useCallback(async () => {
    if (invitationToCancel !== undefined) {
      setCancelling(true);
      try {
        await apiClient.members.cancelInvitation(invitationToCancel.id);
        onOpenChange(false);
      } catch (err) {}
      setCancelling(false);
    }
  }, [apiClient, invitationToCancel, onOpenChange]);

  return (
    <Modal open={Boolean(invitationToCancel)} onOpenChange={onOpenChange}>
      <Modal.FeaturedIcon icon="thrash" />
      <Modal.Title>
        ¿Estás seguro de que quieres cancelar la invitación?
      </Modal.Title>
      <Text size="text_sm" color="gray600" marginTop="4px">
        Esta acción no se puede deshacer.
      </Text>

      <Box flex marginTop="2rem">
        <Box marginRight="12px" cols={1}>
          <Button
            kind="secondary"
            block
            size="md"
            onClick={() => onOpenChange(false)}
          >
            Mantener
          </Button>
        </Box>
        <Box cols={1}>
          <Button
            kind="danger"
            block
            size="md"
            disabled={cancelling}
            isLoading={cancelling}
            onClick={handleCancel}
          >
            {cancelling ? "Cancelando" : "Cancelar"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const DeactivateTeamMemberModal = ({
  workspaceMember,
  onOpenChange,
}: {
  workspaceMember: WorkspaceMember | undefined;
  onOpenChange: (newOpen: boolean) => void;
}) => {
  const [submitting, setSubmitting] = React.useState(false);

  const handleDeactivate = React.useCallback(async () => {
    setSubmitting(true);
    // await workspaceMember?.ref.update({
    //   status: "inactive",
    // });
    setSubmitting(false);
    onOpenChange(false);
  }, [onOpenChange]);

  return (
    <Modal open={Boolean(workspaceMember)} onOpenChange={onOpenChange}>
      <Modal.FeaturedIcon icon="thrash" />
      <Modal.Title>
        ¿Estás seguro de que quieres suspender el acceso de este miembro?
      </Modal.Title>
      <Box flex marginTop="2rem">
        <Box marginRight="12px" cols={1}>
          <Button
            kind="secondary"
            block
            size="md"
            onClick={() => onOpenChange(false)}
          >
            Cancelar
          </Button>
        </Box>
        <Box cols={1}>
          <Button
            kind="danger"
            block
            size="md"
            disabled={submitting}
            isLoading={submitting}
            onClick={handleDeactivate}
          >
            {submitting ? "Suspendiendo" : "Suspender"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export const ReactivateTeamMemberModal = ({
  workspaceMember,
  onOpenChange,
}: {
  workspaceMember: WorkspaceMember | undefined;
  onOpenChange: (newOpen: boolean) => void;
}) => {
  const [submitting, setSubmitting] = React.useState(false);

  const handleReactivate = React.useCallback(async () => {
    setSubmitting(true);
    // await workspaceMember?.ref.update({
    //   status: "active",
    // });
    setSubmitting(false);
    onOpenChange(false);
  }, [onOpenChange]);

  return (
    <Modal open={Boolean(workspaceMember)} onOpenChange={onOpenChange}>
      <Modal.FeaturedIcon icon="check" iconProps={{ stroke: "primary700" }} />
      <Modal.Title>
        ¿Estás seguro de que quieres suspender el acceso de este miembro?
      </Modal.Title>
      <Box flex marginTop="2rem">
        <Box marginRight="12px" cols={1}>
          <Button
            kind="secondary"
            block
            size="md"
            onClick={() => onOpenChange(false)}
          >
            Cancelar
          </Button>
        </Box>
        <Box cols={1}>
          <Button
            kind="primary"
            block
            size="md"
            disabled={submitting}
            isLoading={submitting}
            onClick={handleReactivate}
          >
            {submitting ? "Reactivando" : "Reactivar"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export function ResendWorkspaceMemberInvitationModal(props: {
  onOpenChange: (open: boolean) => unknown;
  member: WorkspaceMember;
}) {
  const [loading, setLoading] = React.useState(false);

  const handleResend = async () => {
    // const roleId = undefined;
    setLoading(true);
    try {
      // await functions.inviteTeamMember({
      //   userId: props.member.userId,
      //   roleId: roleId !== null && roleId !== "admin" ? roleId : undefined,
      //   workspaceId: auth.workspaceId!,
      //   isSuperuser: roleId === "admin",
      //   teams: [],
      // });
      props.onOpenChange(false);
    } catch (err) {}
    setLoading(false);
  };

  return (
    <Modal open onOpenChange={props.onOpenChange}>
      <Modal.FeaturedIcon icon="send" iconProps={{ stroke: "primary700" }} />
      <Modal.Title>
        ¿Estás seguro de que quieres enviar nuevamente la invitación de{" "}
        {props.member.name}?
      </Modal.Title>

      <Box marginTop="2rem">
        <Button
          kind="primary"
          block
          size="md"
          isLoading={loading}
          disabled={loading}
          onClick={handleResend}
        >
          {loading ? "Enviando..." : "Enviar"}
        </Button>
      </Box>
    </Modal>
  );
}
