import React from "react";
import { useApiClient } from "common";
import { useLiveQuery } from "dexie-react-hooks";
import dayjs from "dayjs";
import { ZamiDexie, useIDB } from "./idb";
import { ContactNote } from "../types";

export interface IDBNote {
  id: string;
  contactId: string;
  lastUpdateAt: Date;
  data: ContactNote;
}

export function mapNoteForIDB(n: ContactNote): IDBNote {
  return {
    id: n.id,
    contactId: n.contactId,
    lastUpdateAt: dayjs(n.lastUpdateAt).toDate(),
    data: n,
  };
}

function useHistoricalNotesLoader() {
  const idb = useIDB();
  const apiClient = useApiClient();

  const syncHistoricalNotes = async () => {
    const oldestNote = await idb.notes.orderBy("lastUpdateAt").first();

    const result = await apiClient.notes.fetchNotes({
      before: oldestNote?.lastUpdateAt?.toISOString(),
      limit: 1000,
    });

    putNotes(idb, result);

    if (result.length === 1000) {
      console.log("fetching more notes");
      setTimeout(syncHistoricalNotes, 200);
    }
  };

  React.useEffect(() => {
    syncHistoricalNotes();
  }, [apiClient, idb]);
}

function putNotes(idb: ZamiDexie, notes: ContactNote[]) {
  const idbNotes: IDBNote[] = [];

  for (const note of notes) {
    if (note.deleted) {
      idb.notes.delete(note.id).catch((err: any) => false);
      continue;
    }

    idbNotes.push(mapNoteForIDB(note));
  }

  idb.notes.bulkPut(idbNotes);
}

function useSubscribeToNewNotes() {
  const idb = useIDB();
  const apiClient = useApiClient();

  const lastNote = useLiveQuery(() => idb.notes.orderBy("lastUpdateAt").last());

  React.useEffect(() => {
    if (!lastNote?.lastUpdateAt) {
      return;
    }

    apiClient.websocketClient?.sendNotesSyncPing(lastNote.lastUpdateAt);

    const unsubscribe = apiClient.subscribeMessageHandler((message) => {
      if (message.type === "new_notes") {
        putNotes(idb, message.data);
      }
    });

    return unsubscribe;
  }, [apiClient, idb, lastNote?.id]);
}

function useNotesLoader() {
  useHistoricalNotesLoader();
  useSubscribeToNewNotes();
}

export function NotesLoader(props: { children: React.ReactNode }) {
  console.log("way mi mai");
  useNotesLoader();

  return <>{props.children}</>;
}
