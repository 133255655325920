import { AxiosInstance } from "axios";
import { Label } from "../types";
import { DataUpdateSubscriber } from "../client";

export default class LabelsClient {
  constructor(
    private readonly axios: AxiosInstance,
    private readonly dataUpdatesSubscriber: () =>
      | DataUpdateSubscriber
      | undefined
  ) {}

  async createLabel(params: { color: string; label: string }) {
    const response = await this.axios.post<Label>("/labels", params);

    if (response.status !== 201) {
      throw new Error("Failed to create label");
    }

    this.dataUpdatesSubscriber()?.onLabels([response.data]);

    return response.data;
  }

  async deleteLabel(labelId: string) {
    const response = await this.axios.delete(`/labels/${labelId}`);

    if (response.status !== 200) {
      throw new Error("Failed to delete label");
    }

    this.dataUpdatesSubscriber()?.onLabelsDeleted([labelId]);

    return true;
  }
}
