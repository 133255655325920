import React from "react";
import { ScheduledMessage } from "../types";
import { useApiClient } from "../context";

export function useConversationScheduledMessages(params: {
  contactId: string;
  channelId: string;
}) {
  const apiClient = useApiClient();
  const [scheduledMessages, setScheduledMessages] = React.useState<
    ScheduledMessage[]
  >([]);

  const deleteScheduledMessage = React.useCallback(
    (id: string) => {
      setScheduledMessages((prev) => prev.filter((sm) => sm.id !== id));
    },
    [setScheduledMessages]
  );

  React.useEffect(() => {
    const unsubscribe = apiClient.subscribeMessageHandler((msg) => {
      if (msg.type === "scheduled_messages") {
        for (const newScheduledMessage of msg.data) {
          setScheduledMessages((prev) => {
            return [
              ...prev.filter((sm) => sm.id !== newScheduledMessage.id),
              newScheduledMessage,
            ];
          });
        }
      }
    });

    (async () => {
      const messages = await apiClient.scheduledMessages.listScheduledMessages(
        params.contactId,
        params.channelId
      );
      setScheduledMessages(messages);
    })();

    return unsubscribe;
  }, [params.contactId, apiClient, params.channelId]);

  return { scheduledMessages, deleteScheduledMessage };
}
