import React from "react";
import ZamiAPIClient from "./client";
import { Config, ConfigProvider } from "./lib/config";

const AuthTokenKey = "zami__authToken";

interface KeyValStore {
  get(key: string): Promise<string | null>;
  set(key: string, value: string): Promise<void>;
  del(key: string): Promise<void>;
}

export const ApiClientContext = React.createContext<ZamiAPIClient>(null as any);

export const AuthTokenContext = React.createContext<{
  authToken?: string | undefined;
  setAuthToken: React.Dispatch<React.SetStateAction<string | undefined>>;
}>(undefined as any);

export const BaseUrlContext = React.createContext<string>(
  "http://localhost:6570"
);

function AuthTokenProvider(props: {
  children: React.ReactNode;
  keyValStore: KeyValStore;
}) {
  const [authToken, setAuthToken] = React.useState<string>();
  const hadAuthToken = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (!authToken && hadAuthToken.current) {
      props.keyValStore.del(AuthTokenKey);
    } else if (authToken) {
      hadAuthToken.current = true;
      props.keyValStore.set(AuthTokenKey, authToken);
    }
  }, [authToken]);

  React.useEffect(() => {
    props.keyValStore.get(AuthTokenKey).then((val) => {
      if (val) {
        setAuthToken(val);
      } else {
        setAuthToken("");
      }
    });
  }, [props.keyValStore, setAuthToken]);

  return (
    <AuthTokenContext.Provider value={{ authToken, setAuthToken }}>
      {props.children}
    </AuthTokenContext.Provider>
  );
}

export function ZamiProvider(props: {
  children: React.ReactNode;
  keyValStore: KeyValStore;
  config: Config;
}) {
  return (
    <ConfigProvider value={props.config}>
      <AuthTokenProvider keyValStore={props.keyValStore}>
        {props.children}
      </AuthTokenProvider>
    </ConfigProvider>
  );
}

export function APIClientProvider(props: { children: React.ReactNode }) {
  const auth = React.useContext(AuthTokenContext);
  const baseUrl = React.useContext(BaseUrlContext);

  const apiClient = React.useMemo(() => {
    return new ZamiAPIClient(baseUrl, auth?.authToken);
  }, [auth?.authToken, baseUrl]);

  return (
    <ApiClientContext.Provider value={apiClient}>
      {props.children}
    </ApiClientContext.Provider>
  );
}

export const useApiClient = () => {
  return React.useContext(ApiClientContext)!;
};

export const useAuth = () => {
  const { authToken, setAuthToken } = React.useContext(AuthTokenContext);
  const apiClient = useApiClient();
  const [loginState, setLoginState] = React.useState<
    "default" | "loading" | "error"
  >("default");

  const logout = React.useCallback(() => {
    setAuthToken(undefined);
  }, [setAuthToken]);

  const passwordAuth = React.useCallback(
    async (params: { email: string; password: string }) => {
      setLoginState("loading");
      try {
        const result = await apiClient.auth.passwordAuth(params);

        if (result) {
          setAuthToken(result);
        } else {
          setLoginState("error");
        }
      } catch (err) {
        setLoginState("error");
      }
    },
    [setAuthToken, apiClient]
  );

  return {
    authToken,
    passwordAuth,
    loginState,
    logout,
    setAuthToken,
    auth: setAuthToken,
    isInitialAuthLoading: authToken === undefined,
    isLoggedIn: Boolean(authToken),
  };
};
