import { AxiosInstance } from "axios";

export default class NotificationsClient {
  constructor(private readonly axios: AxiosInstance) {}

  async registerExpoPushToken(expoPushToken: string) {
    return await this.axios.post("/push-notifications/subscribe", {
      expoPushToken,
    });
  }
}
