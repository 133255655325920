import { AxiosInstance } from "axios";
import { Conversation } from "../types";

export default class ConversationClient {
  constructor(private readonly axios: AxiosInstance) {}

  async closeConversation(id: string) {
    const response = await this.axios.post(`/conversations/${id}/close`);

    if (response.status !== 200) {
      throw new Error("Failed to close conversation");
    }
  }

  async openConversation(id: string) {
    const response = await this.axios.post(`/conversations/${id}/open`);

    if (response.status !== 200) {
      throw new Error("Failed to open conversation");
    }
  }

  async assignConversation(id: string, assignee: string) {
    const response = await this.axios.post(`/conversations/${id}/assign`, {
      assignee,
    });

    if (response.status !== 200) {
      throw new Error("Failed to assign conversation");
    }
  }

  async snoozeConversation(id: string, snoozeUntil: Date) {
    const response = await this.axios.post(`/conversations/${id}/snooze`, {
      snoozeUntil,
    });

    if (response.status !== 200) {
      throw new Error("Failed to snooze conversation");
    }
  }

  async resumeConversation(id: string) {
    const response = await this.axios.post(`/conversations/${id}/resume`);

    if (response.status !== 200) {
      throw new Error("Failed to resume conversation");
    }
  }

  async createConversation(contactId: string, channelId: string) {
    const response = await this.axios.post<Conversation>(`/conversations`, {
      contactId,
      channelId,
      channel: "whatsapp_web",
    });

    if (response.status !== 201) {
      throw new Error("Failed to create conversation");
    }

    return response.data;
  }

  async fetchConversations(params?: { before?: string; limit: number }) {
    const response = await this.axios.get<Conversation[]>("/conversations", {
      params: {
        before: params?.before,
        limit: params?.limit,
      },
    });

    if (response.status !== 200) {
      throw new Error("Failed to fetch conversations");
    }

    return response.data;
  }

  async openSupportConversation() {
    const response = await this.axios.post<Conversation>(
      "/conversations/support"
    );

    if (response.status !== 201) {
      throw new Error("Failed to open support conversation");
    }

    return response.data;
  }
}
