import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";

const ImpersonateUser = (props: RouteComponentProps<{ authToken: string }>) => {
  const history = useHistory();
  React.useEffect(() => {
    (async () => {
      history.push("/");
    })();
  }, [props.match.params.authToken, history]);

  return <div>auth token is {props.match.params.authToken}</div>;
};

export default ImpersonateUser;
