import React from "react";
import { Channel as ChannelType } from "common/src/client/channels";
import styled from "styled-components";
import Box from "ui/Box";
import Text from "ui/Text";
import { ReactComponent as WhatsappLogo } from "assets/channelLogos/whatsapp.svg";
import { ReactComponent as InstagramLogo } from "assets/channelLogos/instagram.svg";
import { ReactComponent as MessengerLogo } from "assets/channelLogos/messenger.svg";
import Button from "ui/Button";
import { useHistory } from "react-router-dom";
import Badge from "ui/Badge";
import Icon from "ui/Icon";
import { useApiClient } from "common";

const Wrapper = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
`;

const ScrollSection = styled.div`
  flex: 1;
  overflow: auto;
  padding-top: 2rem;

  .inner {
    margin: 0 auto;
    max-width: 70rem;
  }
`;

const Heading = styled.div`
  margin: 0 auto;
  max-width: 70rem;
  width: 100%;
  padding-bottom: 1.5rem;
  padding-top: 2rem;
  border-bottom: solid 1px ${(props) => props.theme.colors.gray200};
`;

const Section = styled.div`
  margin-bottom: 3rem;
`;

const ChannelBox = styled(Box)`
  padding: 1rem 1.5rem;
  border-radius: 12px;
  border: solid 1px ${(props) => props.theme.colors.gray200};
`;

const ChannelsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
`;

const ChannelFooter = styled(Box)`
  border-top: solid 1px ${(props) => props.theme.colors.gray200};
  margin: 0 -1.5rem;
  margin-top: 1rem;
  padding: 0 1.5rem;
  padding-top: 0.5rem;
  margin-bottom: -0.5rem;
`;

const Channel = ({
  name,
  description,
  logo,
  footer,
  topRightElement,
}: {
  name: string;
  topRightElement: React.ReactNode;
  description: string;
  footer?: React.ReactNode;
  logo: React.ReactNode;
}) => {
  return (
    <ChannelBox>
      <Box flex alignItems="center" justifyContent="space-between">
        <Box flex alignItems="center">
          {logo}
          <Text size="text_md" weight="semibold" marginLeft="0.75rem">
            {name}
          </Text>
        </Box>

        {topRightElement}
      </Box>

      <Box>
        <Text size="text_sm" color="gray600" marginTop="20px">
          {description}
        </Text>
      </Box>

      {footer && <ChannelFooter>{footer}</ChannelFooter>}
    </ChannelBox>
  );
};

function ChannelSettings() {
  const history = useHistory();
  const [channels, setChannels] = React.useState<ChannelType[]>();
  const apiClient = useApiClient();

  React.useEffect(() => {
    (async () => {
      const channels = await apiClient.channelsClient.listChannels();
      setChannels(channels);
    })();
  }, [apiClient]);

  if (channels === undefined) {
    return (
      <Box
        padding="2rem"
        marginTop="4rem"
        flex
        justifyContent="center"
        cols={1}
      >
        <Icon icon="oval" fill="gray900" />
      </Box>
    );
  }

  return (
    <Wrapper>
      <Heading>
        <Text size="display_xs" weight="semibold" marginBottom="0.25rem">
          Canales
        </Text>
        <Text size="text_md" color="gray600">
          Los diferentes medios que puedes conectar a Zami para manejar
          comunicación con tus clientes.
        </Text>
      </Heading>

      <ScrollSection>
        <div className="inner">
          {channels!.length > 0 && (
            <Section>
              <Text size="text_lg" weight="semibold" marginBottom="1.5rem">
                Canales conectados
              </Text>

              <ChannelsGrid>
                {channels!.map((channel) => {
                  if (channel.type === "instagram") {
                    return (
                      <Channel
                        name={channel.data.username}
                        logo={<InstagramLogo />}
                        topRightElement={
                          <Badge
                            size="lg"
                            variant="success"
                            label="Conectado"
                            type="pill"
                          />
                        }
                        footer={
                          <Box flex justifyContent="flex-end">
                            <Button
                              kind="tertiary"
                              size="md"
                              onClick={() =>
                                history.push(
                                  `/settings/channels/instagram/${channel.data.username}`
                                )
                              }
                            >
                              Configurar
                            </Button>
                          </Box>
                        }
                        description="Esta cuenta de instagram está conectada. Desde Zami podrás recibir y responder los mensajes directos "
                      />
                    );
                  }

                  if (channel.type === "messenger") {
                    return (
                      <Channel
                        name={channel.data.name}
                        logo={<MessengerLogo />}
                        topRightElement={
                          <Badge
                            size="lg"
                            variant="success"
                            label="Conectado"
                            type="pill"
                          />
                        }
                        footer={
                          <Box flex justifyContent="flex-end">
                            <Button
                              kind="tertiary"
                              size="md"
                              onClick={() =>
                                history.push(
                                  `/settings/channels/facebook-page/${channel.data.fbPageId}`
                                )
                              }
                            >
                              Configurar
                            </Button>
                          </Box>
                        }
                        description="Esta página de facebook está conectada. Desde Zami podrás recibir y responder los mensajes directos "
                      />
                    );
                  }

                  if (channel.type === "whatsapp_web") {
                    return (
                      <Channel
                        name={channel.data.name ?? channel.data.phone ?? ""}
                        logo={<WhatsappLogo />}
                        topRightElement={
                          <Badge
                            size="lg"
                            variant="success"
                            label="Conectado"
                            type="pill"
                          />
                        }
                        footer={
                          <Box flex justifyContent="flex-end">
                            <Button
                              kind="tertiary"
                              size="md"
                              onClick={() =>
                                history.push(
                                  `/settings/channels/whatsapp-web/${channel.data.id}`
                                )
                              }
                            >
                              Configurar
                            </Button>
                          </Box>
                        }
                        description="Esta cuenta de whatsapp está conectada. Desde Zami podrás recibir y responder los mensajes directos"
                      />
                    );
                  }

                  return null;
                })}
              </ChannelsGrid>
            </Section>
          )}

          <Section>
            <Text size="text_lg" weight="semibold" marginBottom="1.5rem">
              Agrega canales
            </Text>

            <ChannelsGrid>
              <Channel
                name="Whatsapp"
                logo={<WhatsappLogo />}
                topRightElement={
                  <Button
                    kind="secondary"
                    size="sm"
                    onClick={() =>
                      history.push("/settings/channels/whatsapp-web")
                    }
                  >
                    Conectar
                  </Button>
                }
                description="Conecta tu número y podrás recibir y contestar los mensajes de WhatsApp desde Zami."
              />

              <Channel
                name="Instagram"
                logo={<InstagramLogo />}
                description="Conecta tu cuenta profesional de instagram y podrás recibir en contestar los mensajes directos de instagram desde Zami."
                topRightElement={
                  <Button
                    kind="secondary"
                    size="sm"
                    onClick={() => history.push("/settings/channels/instagram")}
                  >
                    Conectar
                  </Button>
                }
              />

              <Channel
                name="Messenger"
                logo={<MessengerLogo />}
                description="Conecta tu página de facebook y podrás recibir y conestar los mensajes de messenger desde Zami."
                topRightElement={
                  <Button
                    kind="secondary"
                    size="sm"
                    onClick={() => history.push("/settings/channels/messenger")}
                  >
                    Conectar
                  </Button>
                }
              />
            </ChannelsGrid>
          </Section>
        </div>
      </ScrollSection>
    </Wrapper>
  );
}

export default ChannelSettings;
