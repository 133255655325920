import React from "react";
import styled from "styled-components";
import Box from "ui/Box";
import { ReactComponent as WhatsappLogo } from "assets/channelLogos/whatsapp.svg";
import {
  BreadcrumbChevron,
  BreadcrumbCurrentPage,
  BreadcrumbLink,
  Breadcrumbs,
} from "ui/Breadcrumbs";
import Button from "ui/Button";
import Text from "ui/Text";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { WhatsappWebAccount } from "common/src/types";
import { InlineInput } from "ui/Input";
import ChannelAssigner from "./ChannelAssigner";

const Wrapper = styled.div`
  max-width: 70rem;
  margin: 0 auto;
  flex: 1;
  overflow: auto;
`;

const Heading = styled.div`
  flex: 1;
  padding-bottom: 1.5rem;
  padding-top: 2rem;
  border-bottom: solid 1px ${(props) => props.theme.colors.gray200};
  margin-bottom: 2rem;
`;

const SettingsBox = styled.div`
  border-radius: 12px;
  border: solid 1px ${(props) => props.theme.colors.gray200};
  padding: 2rem 1.5rem;
`;

const WhatsappWebChannelConfigure = (
  props: RouteComponentProps<{ whatsappWebAccountId: string }>
) => {
  const history = useHistory();
  const [isDisconnecting, setDisconnecting] = React.useState(false);
  const [whatsappWebAccountDoc] = React.useState<WhatsappWebAccount>();
  const [name, setName] = React.useState<string>("");

  // React.useEffect(() => {
  //   return db.whatsappWebAccounts
  //     .doc(props.match.params.whatsappWebAccountId)
  //     .onSnapshot((doc) => {
  //       setWhatsappWebAccountDoc(doc);
  //       setName(doc.data()!.name ?? "");
  //     });
  // }, [props.match.params.whatsappWebAccountId]);

  if (whatsappWebAccountDoc === undefined) {
    return null;
  }

  return (
    <Wrapper>
      <Heading>
        <Breadcrumbs marginBottom="20px">
          <BreadcrumbLink to="/settings/channels">Canales</BreadcrumbLink>
          <BreadcrumbChevron />
          <BreadcrumbCurrentPage>Whatsapp</BreadcrumbCurrentPage>
        </Breadcrumbs>
        <Box flex alignItems="center">
          <WhatsappLogo />
          <Box marginLeft="20px" cols={1}>
            <Text size="display_xs" weight="semibold">
              Configuración de {whatsappWebAccountDoc.name}
            </Text>
          </Box>
        </Box>
      </Heading>

      <Box flex marginBottom="2.5rem" paddingBottom="2.5rem" borderBottom>
        <Box cols={1} alignItems="center">
          <Text size="text_xl" weight="semibold" marginBottom="0.25rem">
            Nombre
          </Text>
          <Text size="text_md" weight="regular" color="gray600">
            Para identificar el canal de WhatsApp al que pertenece
          </Text>
        </Box>

        <Box flex alignItems="center" cols={1} justifyContent="flex-end">
          <InlineInput
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            onFinish={() => {
              // whatsappWebAccountDoc.ref.update({
              //   name,
              // });
            }}
          />
        </Box>
      </Box>

      <ChannelAssigner
        accountId={whatsappWebAccountDoc.id}
        accountType="whatsappWeb"
      />

      <SettingsBox>
        <Text size="text_xl" weight="semibold" marginBottom="1rem">
          Desconectar la cuenta
        </Text>
        <Text
          size="text_md"
          color="gray600"
          marginBottom="1.5rem"
          style={{ maxWidth: "40rem" }}
        >
          Al desconectar la cuenta no podrás recibir ni responder a los mesajes
          directos de whatsapp de esta cuenta. Los contactos o mensajes
          existentes no se borrarán
        </Text>
        <Button
          size="md"
          kind="secondary"
          disabled={isDisconnecting}
          isLoading={isDisconnecting}
          onClick={async () => {
            setDisconnecting(true);
            // whatsappWebAccountDoc.ref.update({
            //   status: "disconnected",
            // });
            history.push("/settings/channels");
          }}
        >
          {isDisconnecting ? "Desconectando" : "Desconectar canal"}
        </Button>
      </SettingsBox>
    </Wrapper>
  );
};

export default WhatsappWebChannelConfigure;
