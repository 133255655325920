import React from "react";
import {
  APIClientProvider,
  BaseUrlContext,
  ZamiProvider,
} from "common/src/context";
import { IntercomProvider } from "react-use-intercom";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ThemeProvider } from "ui/theme";
import GlobalStyles from "components/GlobalStyles";
import LoginPage from "components/onboarding/login";
import { useAuth } from "common";
import AuthenticatedApp from "components/AuthenticatedApp";
import WorkspaceInvitationPage from "components/settings/team/WorkspaceInvitationPage";
import ImpersonateUser from "components/ImpersonateUser";
import ResetPassword, {
  PasswordResetSetNewPassword,
} from "components/onboarding/resetPassword";
import { OnboardingStyles } from "components/onboarding/common";
import ActivateProposal from "components/onboarding/activateProposal";
import Signup from "components/onboarding/signup";
import PostCheckoutOnboarding from "components/onboarding/postCheckoutOnboarding";
import { Config } from "common/src/lib/config";

const stripePromise = process.env.REACT_APP_STRIPE_PUBLIC_KEY
  ? loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
      locale: "es",
    })
  : null;

const AppRoutes = () => {
  const auth = useAuth();

  if (auth.isLoggedIn) {
    return <AuthenticatedApp />;
  }

  if (auth.isInitialAuthLoading) {
    return (
      <>
        <OnboardingStyles />
      </>
    );
  }

  return (
    <BaseUrlContext.Provider value={process.env.REACT_APP_CORE_BASE_URL!}>
      <APIClientProvider>
        <Switch>
          <Route
            path="/impersonate-user/:authToken"
            component={ImpersonateUser}
          />
          <Route
            path="/join/:invitationId"
            component={WorkspaceInvitationPage}
          />
          <Route
            path="/reset-password/:passwordResetToken"
            component={PasswordResetSetNewPassword}
          />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/login" render={() => <LoginPage />} />
          <Route
            path="/activate-proposal/:proposalId"
            component={ActivateProposal}
          />
          <Route exact path="/signup" component={Signup} />
          <Route
            path="/post-checkout/:checkoutSessionId/:leadId"
            component={PostCheckoutOnboarding}
          />
          <Redirect to="/login" />
        </Switch>
      </APIClientProvider>
    </BaseUrlContext.Provider>
  );
};

const keyValStore = {
  get: async (key: string) => {
    return localStorage.getItem(key);
  },
  set: async (key: string, value: string) => {
    console.log(`setting ${key} to ${value}`);
    localStorage.setItem(key, value);
  },
  del: async (key: string) => {
    localStorage.removeItem(key);
  },
};

const App = () => {
  const config = React.useMemo(() => {
    const result: Config = {
      fbAppId: process.env.REACT_APP_FB_APP_ID!,
    };

    return result;
  }, []);
  return (
    <Elements stripe={stripePromise}>
      <ZamiProvider keyValStore={keyValStore} config={config}>
        <IntercomProvider appId="big5mu6o">
          <ThemeProvider>
            <BrowserRouter>
              <GlobalStyles />
              <AppRoutes />
            </BrowserRouter>
          </ThemeProvider>
        </IntercomProvider>
      </ZamiProvider>
    </Elements>
  );
};

export default App;
