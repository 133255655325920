import React from "react";
import { useApiClient } from "common";

export function useConnectWhatsappWebAccount() {
  const apiClient = useApiClient();
  const [qrCode, setQRCode] = React.useState<string>();
  const [timedOut, setTimedOut] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const start = React.useCallback(() => {
    setQRCode(undefined);
    setTimedOut(false);

    if (apiClient.whatsappClient) {
      const unsub = apiClient.whatsappClient.connectNewWhatsappAccount({
        onQr: (qr) => {
          setQRCode(qr);
        },
        onTimeout: () => {
          setQRCode(undefined);
          setTimedOut(true);
        },
        onSuccess: () => {
          setSuccess(true);
        },
      });

      return unsub;
    }

    return () => {};
  }, [apiClient, setQRCode, setTimedOut, setSuccess]);

  const restart = React.useCallback(() => {
    if (!timedOut) {
      throw new Error("Cannot start while waiting for QR code");
    }

    start();
  }, [timedOut, start]);

  React.useEffect(() => {
    return start();
  }, [start]);

  return { qrCode, timedOut, restart, success };
}

export function useConnectWhatsappWebAccountWithPhone(phone: string) {
  const apiClient = useApiClient();
  const [timedOut, setTimedOut] = React.useState(false);
  const [code, setCode] = React.useState<string>();
  const [success, setSuccess] = React.useState(false);

  const start = React.useCallback(() => {
    setTimedOut(false);

    if (apiClient.whatsappClient) {
      const unsub = apiClient.whatsappClient.connectNewWhatsappAccountWithPhone(
        phone,
        {
          onCode: (code) => {
            setCode(code);
          },
          onTimeout: () => {
            setTimedOut(true);
          },
          onSuccess: () => {
            setSuccess(true);
          },
        }
      );

      return unsub;
    }

    return () => {};
  }, [apiClient, setTimedOut, setSuccess]);

  const restart = React.useCallback(() => {
    if (!timedOut) {
      throw new Error("Cannot start while waiting for QR code");
    }

    start();
  }, [timedOut, start]);

  React.useEffect(() => {
    return start();
  }, [start]);

  return { timedOut, restart, success, code };
}
