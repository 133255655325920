import ProfilePic from "components/ProfilePic";
import React, { FormEvent } from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import Box from "ui/Box";
import Button from "ui/Button";
import Field from "ui/Field";
import Input from "ui/Input";
import Text from "ui/Text";
import { WorkspaceInvite } from "common/src/types";
import { useApiClient, useAuth } from "common/src/context";

const InvitationWrapper = styled.div`
  max-width: 22.5rem;
  margin: 0 auto;
`;

const WorkspaceInvitationPage = ({
  match,
}: RouteComponentProps<{ invitationId: string }>) => {
  const [name, setName] = React.useState("");
  const apiClient = useApiClient();
  const [password, setPassword] = React.useState("");
  const auth = useAuth();
  const [workspaceInvite, setWorkspaceInvite] =
    React.useState<WorkspaceInvite>();
  const [submitting, setSubmitting] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        const invitation = await apiClient.members.fetchInvitation(
          match.params.invitationId
        );
        setWorkspaceInvite(invitation);
      } catch (err) {
        // TODO: Capture err
      }
    })();
  }, [match.params.invitationId, apiClient, setWorkspaceInvite]);

  const handleSubmit = React.useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      setSubmitting(true);
      try {
        const result = await apiClient.members.acceptInvitation(
          match.params.invitationId,
          name,
          password
        );

        if (result.ok) {
          auth.auth(result.auth_token);
        }
      } catch (err) {}
      setSubmitting(false);
    },
    [password, apiClient, auth, name, match.params.invitationId, setSubmitting]
  );

  if (workspaceInvite === undefined) {
    // loading
    return null;
  }

  return (
    <form onSubmit={handleSubmit}>
      <InvitationWrapper>
        <Box marginTop="6rem">
          <Box
            marginBottom="2rem"
            style={{ textAlign: "center" }}
            flex
            direction="column"
            alignItems="center"
          >
            <Box marginBottom="1rem">
              <ProfilePic size="lg" name={name} id="asdlkfjalwkejf" />
            </Box>
            <Text size="display_sm" weight="semibold">
              Activa tu cuenta
            </Text>
          </Box>

          <Field label="Correo electrónico">
            <Input value={workspaceInvite?.email} disabled />
          </Field>

          <Field label="Nombre y apellido">
            <Input
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Field>

          <Field label="Crea tu contraseña">
            <Input
              type="password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Field>

          <Button
            kind="primary"
            size="md"
            block
            type="submit"
            isLoading={submitting}
            disabled={submitting}
          >
            Activar
          </Button>
        </Box>
      </InvitationWrapper>
    </form>
  );
};

export default WorkspaceInvitationPage;
