import React, { FormEvent } from "react";
import styled from "styled-components";
import { ReactComponent as ZamiLogo } from "assets/logo.svg";
import { OnboardingStyles } from "./common";
import Box from "ui/Box";
import Text from "ui/Text";
import Field from "ui/Field";
import Input from "ui/Input";
import Button from "ui/Button";
import { RouteComponentProps } from "react-router-dom";

const Wrapper = styled.div`
  max-width: 22.5rem;
  margin: 0 auto;
  margin-top: 6rem;
`;

export default function ResetPassword() {
  const [email, setEmail] = React.useState("");
  const [isLoading, setLoading] = React.useState(false);
  const [emailSent, setEmailSent] = React.useState(false);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    // await functions.requestPasswordResetEmail({ email });
    setEmailSent(true);
  };

  if (emailSent) {
    return (
      <>
        <OnboardingStyles />
        <Wrapper style={{ textAlign: "center" }}>
          <Box flex justifyContent="center" marginBottom="1rem">
            <ZamiLogo height={48} width={48} />
          </Box>
          <Text
            size="display_sm"
            weight="semibold"
            marginBottom="1rem"
            flex
            justifyContent="center"
            color="gray900"
          >
            Correo enviado!
          </Text>

          <Text size="text_md" color="gray600" marginBottom="2rem">
            Revisa tu correo electrónico y abre el enlace que te enviamos para
            continuar.
          </Text>
        </Wrapper>
      </>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <OnboardingStyles />
      <Wrapper>
        <Box flex justifyContent="center" marginBottom="1rem">
          <ZamiLogo height={48} width={48} />
        </Box>
        <Text
          size="display_sm"
          weight="semibold"
          marginBottom="1rem"
          flex
          justifyContent="center"
          color="gray900"
        >
          Recupera tu cuenta
        </Text>

        <Text size="text_md" color="gray600" marginBottom="2rem">
          Ingresa la dirección de correo electrónico que utilizaste cuando te
          registraste. Te enviaremos instrucciones para restablecer su
          contraseña.
        </Text>

        <Field label="Correo electrónico">
          <Input
            required
            disabled={isLoading}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Field>

        <Box marginTop="">
          <Button block isLoading={isLoading} disabled={isLoading}>
            Enviar instrucciones
          </Button>
        </Box>
      </Wrapper>
    </form>
  );
}

export function PasswordResetSetNewPassword(
  props: RouteComponentProps<{ passwordResetToken: string }>
) {
  const [password, setPassword] = React.useState("");
  const [isLoading, setLoading] = React.useState(false);
  const [passwordChanged, setPasswordChanged] = React.useState(false);
  const [emailForPasswordReset, setEmailForPasswordReset] = React.useState<
    string | null
  >();
  const [errorCode, setErrorCode] = React.useState<string>();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (emailForPasswordReset) {
      setLoading(true);
      // todo
      setPasswordChanged(true);
    }
  };

  React.useEffect(() => {
    // Check if reset token is valid
    (async () => {
      try {
        // const userEmail = await firebase
        //   .auth()
        //   .verifyPasswordResetCode(props.match.params.passwordResetToken);
        // setEmailForPasswordReset(userEmail);
      } catch (err: any) {
        setErrorCode(err?.code as string);
        setEmailForPasswordReset(null);
      }
    })();
  }, [props.match.params.passwordResetToken]);

  if (emailForPasswordReset === undefined) {
    return null;
  }

  if (errorCode === "auth/expired-action-code") {
    return (
      <>
        <OnboardingStyles />
        <Wrapper style={{ textAlign: "center" }}>
          <Box flex justifyContent="center" marginBottom="1rem">
            <ZamiLogo height={48} width={48} />
          </Box>
          <Text
            size="display_sm"
            weight="semibold"
            marginBottom="1rem"
            flex
            justifyContent="center"
            color="gray900"
          >
            Este enlace expiró
          </Text>

          <Text size="text_md" color="gray600" marginBottom="2rem">
            Para restablecer tu contraseña porfavor solicita otro enlace.
          </Text>
        </Wrapper>
      </>
    );
  } else if (errorCode) {
    return (
      <>
        <OnboardingStyles />
        <Wrapper style={{ textAlign: "center" }}>
          <Box flex justifyContent="center" marginBottom="1rem">
            <ZamiLogo height={48} width={48} />
          </Box>
          <Text
            size="display_sm"
            weight="semibold"
            marginBottom="1rem"
            flex
            justifyContent="center"
            color="gray900"
          >
            Enlance inválido
          </Text>

          <Text size="text_md" color="gray600" marginBottom="2rem">
            Para restablecer tu contraseña porfavor solicita otro enlace.
          </Text>
        </Wrapper>
      </>
    );
  }

  if (passwordChanged) {
    return (
      <>
        <OnboardingStyles />
        <Wrapper style={{ textAlign: "center" }}>
          <Box flex justifyContent="center" marginBottom="1rem">
            <ZamiLogo height={48} width={48} />
          </Box>
          <Text
            size="display_sm"
            weight="semibold"
            marginBottom="1rem"
            flex
            justifyContent="center"
            color="gray900"
          >
            Contraseña cambiada!
          </Text>

          <Text size="text_md" color="gray600" marginBottom="2rem">
            Ahora puedes iniciar sesión con tu nueva contraseña.
          </Text>
        </Wrapper>
      </>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <OnboardingStyles />
      <Wrapper>
        <Box style={{ textAlign: "center" }}>
          <Box flex justifyContent="center" marginBottom="1rem">
            <ZamiLogo height={48} width={48} />
          </Box>
          <Text
            size="display_sm"
            weight="semibold"
            marginBottom="1rem"
            flex
            justifyContent="center"
            color="gray900"
          >
            Cambia tu contraseña
          </Text>

          <Text size="text_md" color="gray600" marginBottom="2rem">
            Ingresa tu nueva contraseña.
          </Text>
        </Box>
        <Field label="Nueva Contraseña">
          <Input
            required
            disabled={isLoading}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Field>

        <Box marginTop="">
          <Button block isLoading={isLoading} disabled={isLoading}>
            Cambiar contraseña
          </Button>
        </Box>
      </Wrapper>
    </form>
  );
}
