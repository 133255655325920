import React, { FormEvent } from "react";
import { useHistory } from "react-router-dom";
import { OnboardingStyles } from "./common";
import Box from "ui/Box";
import { ReactComponent as ZamiLogo } from "assets/logo.svg";
import Text from "ui/Text";
import styled from "styled-components";
import Field from "ui/Field";
import Input from "ui/Input";
import Button from "ui/Button";

const Link = styled(Text)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const Wrapper = styled.div`
  max-width: 22.5rem;
  margin: 0 auto;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

export default function PostCheckoutOnboarding() {
  const history = useHistory();
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errorCode, setErrorCode] = React.useState<
    "email_already_exists" | "password_too_small"
  >();
  const [signupState, setSignupState] = React.useState<
    "default" | "loading" | "error"
  >("default");

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    setSignupState("loading");

    const response = null as any;

    const responseData = response.data as {
      ok: boolean;
      token: string;
      error_code: string;
    };
    if (responseData.ok && responseData.token) {
      try {
        // await firebase.auth().signInWithCustomToken(responseData.token);
      } catch (err) {
        setSignupState("error");
      }
    } else {
      setSignupState("error");
      if (responseData.error_code) {
        setErrorCode(responseData.error_code as any);
      }
    }
  };

  return (
    <div>
      <OnboardingStyles />
      <Box
        paddingTop="1.5rem"
        paddingBottom="1.5rem"
        paddingRight="2rem"
        flex
        justifyContent="flex-end"
      >
        <Text size="text_md" color="gray600">
          ¿Ya tienes una cuenta?{" "}
          <Link
            inline
            size="text_md"
            color="primary700"
            weight="semibold"
            onClick={() => history.push("/login")}
          >
            Inicia sesión
          </Link>
        </Text>
      </Box>
      <form onSubmit={handleSubmit}>
        <Wrapper>
          <Box flex justifyContent="center" marginBottom="1rem">
            <ZamiLogo height={48} width={48} />
          </Box>
          <Text
            size="display_sm"
            weight="semibold"
            marginBottom="0.75rem"
            flex
            justifyContent="center"
            color="gray900"
          >
            Crea tu cuenta debajo
          </Text>

          <Text
            size="text_md"
            marginBottom="2rem"
            color="gray600"
            style={{ textAlign: "center" }}
          >
            Utilizaras estas credenciales para acceder a la plataforma
          </Text>

          <Field label="Correo electrónico">
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="correo@zamiapp.com"
              required
            />
          </Field>

          <Field label="Nombre completo">
            <Input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Luis del Giudice"
              required
            />
          </Field>

          <Field label="Contraseña">
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Field>

          {signupState === "error" && errorCode === "email_already_exists" && (
            <Text size="text_md" color="error500" marginBottom="1.5rem">
              Ya existe una cuenta con este correo electrónico.
            </Text>
          )}

          {signupState === "error" && errorCode === "password_too_small" && (
            <Text size="text_md" color="error500" marginBottom="1.5rem">
              La contraseña debe tener almenos 6 caracteres.
            </Text>
          )}

          {signupState === "error" && !errorCode && (
            <Text size="text_md" color="error500" marginBottom="1.5rem">
              Ocurrió un error al registrarte. Porfavor contactate con un
              administrador.
            </Text>
          )}

          <Button
            size="md"
            block
            style={{ marginTop: "0.5rem" }}
            type="submit"
            isLoading={signupState === "loading"}
          >
            Crear cuenta
          </Button>
        </Wrapper>
      </form>
    </div>
  );
}
