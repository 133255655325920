import React from "react";
import { ReactComponent as MicrophoneIcon } from "assets/icons/microphone.svg";
import { ReactComponent as PauseIcon } from "assets/icons/pause.svg";
import { ReactComponent as MicrophoneFillIcon } from "assets/icons/microphone-fill.svg";
import { ReactComponent as ChatIcon } from "assets/icons/message-icon.svg";
import { ReactComponent as ChevronFillDown } from "assets/icons/chevron-filled-down.svg";
import { ReactComponent as ContactsIcon } from "assets/icons/contacts-icon.svg";
import { ReactComponent as ReportsIcon } from "assets/icons/reports-icon.svg";
import { ReactComponent as TasksIcon } from "assets/icons/tasks-icon.svg";
import { ReactComponent as WhatsappBadgeIcon } from "assets/icons/whatsapp-badge-icon.svg";
import { ReactComponent as InstagramBadgeIcon } from "assets/icons/instagram-badge-icon.svg";
import { ReactComponent as MessengerBadgeIcon } from "assets/icons/messenger-badge-icon.svg";
import { ReactComponent as EmailBadgeIcon } from "assets/icons/email-badge-icon.svg";
import { ReactComponent as MessageStatusPendingIcon } from "assets/icons/message-status-pending-icon.svg";
import { ReactComponent as PlayIcon } from "assets/icons/play.svg";
import { ReactComponent as MessageStatusDeliveredIcon } from "assets/icons/message-status-delivered-icon.svg";
import { ReactComponent as MessagestatusSentIcon } from "assets/icons/message-status-sent-icon.svg";
import { ReactComponent as AIStarsIcon } from "assets/icons/ai_stars.svg";
import { ReactComponent as MessageChatIcon } from "assets/icons/message-chat-circle.svg";
import { ReactComponent as ArrowLeftIcon } from "assets/icons/arrow-left.svg";
import { ReactComponent as ArrowUpIcon } from "assets/icons/arrow-up.svg";
import { ReactComponent as FileIcon } from "assets/icons/file.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus-icon.svg";
import { ReactComponent as Plus2Icon } from "assets/icons/plus-02.svg";
import { ReactComponent as RocketIcon } from "assets/icons/rocket.svg";
import { ReactComponent as DotsVerticalIcon } from "assets/icons/dots-vertical.svg";
import { ReactComponent as TrashIcon } from "assets/icons/trash-icon.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit-icon.svg";
import { ReactComponent as Edit5Icon } from "assets/icons/edit-05.svg";
import { ReactComponent as XCloseIcon } from "assets/icons/x-close.svg";
import { ReactComponent as OvalLoaderIcon } from "assets/icons/oval-loader.svg";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { ReactComponent as ChevronUpIcon } from "assets/icons/chevron-up.svg";
import { ReactComponent as InboxIcon } from "assets/icons/inbox.svg";
import { ReactComponent as AtSignIcon } from "assets/icons/at-sign.svg";
import { ReactComponent as LightbulbIcon } from "assets/icons/lightbulb.svg";
import { ReactComponent as Send3Fill } from "assets/icons/send-03-fill.svg";
import { ReactComponent as CircleIcon } from "assets/icons/circle.svg";
import { ReactComponent as UserCircleIcon } from "assets/icons/user-circle.svg";
import { ReactComponent as ChevronDownIcon } from "assets/icons/chevron-down.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { ReactComponent as LayoutLeftIcon } from "assets/icons/layout-left.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
import { ReactComponent as ChannelsIcon } from "assets/icons/channels.svg";
import { ReactComponent as ChevronRightIcon } from "assets/icons/chevron-right.svg";
import { ReactComponent as PuzzlePieceIcon } from "assets/icons/puzzle-piece.svg";
import { ReactComponent as ArrowRightIcon } from "assets/icons/arrow-right.svg";
import { ReactComponent as LockIcon } from "assets/icons/lock.svg";
import { ReactComponent as BlockedIcon } from "assets/icons/blocked.svg";
import { ReactComponent as UsersPlusIcon } from "assets/icons/users-plus.svg";
import { ReactComponent as ThrashIcon } from "assets/icons/thrash.svg";
import { ReactComponent as WhatsappDotsIcon } from "assets/icons/whatsapp-dots.svg";
import { ReactComponent as WhatsappSettingsIcon } from "assets/icons/whatsapp-settings.svg";
import { ReactComponent as RefreshIcon } from "assets/icons/refresh.svg";
import { ReactComponent as SendIcon } from "assets/icons/send.svg";
import { ReactComponent as Send3Icon } from "assets/icons/send-03.svg";
import { ReactComponent as SettingsIcon2 } from "assets/icons/settings-2.svg";
import { ReactComponent as BarChartIcon } from "assets/icons/bar-chart.svg";
import { ReactComponent as PaperclipIcon } from "assets/icons/paperclip.svg";
import { ReactComponent as File4Icon } from "assets/icons/file-04.svg";
import { ReactComponent as FaceSmileIcon } from "assets/icons/face-smile.svg";
import { ReactComponent as CameraIcon } from "assets/icons/camera.svg";
import { ReactComponent as CornerUpLeftIcon } from "assets/icons/corner-up-left.svg";
import { ReactComponent as KeyIcon } from "assets/icons/key.svg";
import { ReactComponent as UsersFillIcon } from "assets/icons/users-fill.svg";
import { ReactComponent as PhoneFillIcon } from "assets/icons/phone-fill.svg";
import { ReactComponent as UserFillIcon } from "assets/icons/user-fill.svg";
import { ReactComponent as ComposeIcon } from "assets/icons/compose.svg";
import { ReactComponent as HambugerMenuIcon } from "assets/icons/hamburger-menu.svg";
import { ReactComponent as WhatsappGrayIcon } from "assets/icons/whatsapp-gray.svg";
import { ReactComponent as BellSnoozeIcon } from "assets/icons/bell-snooze.svg";
import { ReactComponent as BookmarkIcon } from "assets/icons/bookmark.svg";
import { ReactComponent as ImageIcon } from "assets/icons/image.svg";
import { ReactComponent as File4OutlineIcon } from "assets/icons/file-04-outline.svg";
import { ReactComponent as CardIcon } from "assets/icons/card.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/download-02.svg";
import { ReactComponent as LogOutIcon } from "assets/icons/log-out.svg";
import { ReactComponent as HelpIcon } from "assets/icons/help.svg";
import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";
import { ReactComponent as ClockSnoozeIcon } from "assets/icons/clock-snooze.svg";
import { ReactComponent as TagIcon } from "assets/icons/tag.svg";
import { ReactComponent as TickIcon } from "assets/icons/tick.svg";
import { ReactComponent as XClose } from "assets/icons/x-close.svg";
import { ReactComponent as UserStatusIcon } from "assets/icons/user-status.svg";
import { ReactComponent as ZamiBadgeIcon } from "assets/icons/zami-badge.svg";

import styled, { css, DefaultTheme, useTheme } from "styled-components";

export const iconMap = {
  chat: ChatIcon,
  contacts: ContactsIcon,
  reports: ReportsIcon,
  tasks: TasksIcon,
  whatsappBadge: WhatsappBadgeIcon,
  zamiBadge: ZamiBadgeIcon,
  instagramBadge: InstagramBadgeIcon,
  pause: PauseIcon,
  microphone: MicrophoneIcon,
  play: PlayIcon,
  aiStars: AIStarsIcon,
  microphoneFill: MicrophoneFillIcon,
  messengerBadge: MessengerBadgeIcon,
  whatsappGray: WhatsappGrayIcon,
  emailBadge: EmailBadgeIcon,
  messageStatusPending: MessageStatusPendingIcon,
  messageStatusSent: MessagestatusSentIcon,
  messageStatusDelivered: MessageStatusDeliveredIcon,
  arrowLeft: ArrowLeftIcon,
  search: SearchIcon,
  chevronUp: ChevronUpIcon,
  chevronDown: ChevronDownIcon,
  chevronFillDown: ChevronFillDown,
  inbox: InboxIcon,
  file: FileIcon,
  file4: File4Icon,
  file4Outline: File4OutlineIcon,
  plus: PlusIcon,
  plus2: Plus2Icon,
  close: XClose,
  dotsVertical: DotsVerticalIcon,
  trash: TrashIcon,
  edit: EditIcon,
  edit5: Edit5Icon,
  x: XCloseIcon,
  oval: OvalLoaderIcon,
  atSign: AtSignIcon,
  circle: CircleIcon,
  userCircle: UserCircleIcon,
  check: CheckIcon,
  layoutLeft: LayoutLeftIcon,
  settings: SettingsIcon,
  chevronRight: ChevronRightIcon,
  channels: ChannelsIcon,
  puzzlePiece: PuzzlePieceIcon,
  arrowRight: ArrowRightIcon,
  lock: LockIcon,
  blocked: BlockedIcon,
  usersPlus: UsersPlusIcon,
  thrash: ThrashIcon,
  whatsappDots: WhatsappDotsIcon,
  whatsappSettings: WhatsappSettingsIcon,
  refresh: RefreshIcon,
  send: SendIcon,
  send3: Send3Icon,
  send3Fill: Send3Fill,
  settings2: SettingsIcon2,
  barChart: BarChartIcon,
  paperclip: PaperclipIcon,
  faceSmile: FaceSmileIcon,
  camera: CameraIcon,
  cornerUpLeft: CornerUpLeftIcon,
  key: KeyIcon,
  usersFill: UsersFillIcon,
  phoneFill: PhoneFillIcon,
  userFill: UserFillIcon,
  userStatus: UserStatusIcon,
  arrowUp: ArrowUpIcon,
  compose: ComposeIcon,
  hambugerMenu: HambugerMenuIcon,
  bellSnooze: BellSnoozeIcon,
  bookmark: BookmarkIcon,
  messageChatIcon: MessageChatIcon,
  tick: TickIcon,
  rocket: RocketIcon,
  image: ImageIcon,
  card: CardIcon,
  download: DownloadIcon,
  logOut: LogOutIcon,
  help: HelpIcon,
  clock: ClockIcon,
  clockSnooze: ClockSnoozeIcon,
  tag: TagIcon,
  lightbulb: LightbulbIcon,
};

export type IconKey = keyof typeof iconMap;

const IconWrapper = styled.div<{ stroke?: string; fill?: string }>`
  display: flex;

  ${(props) =>
    props.stroke &&
    css`
      path {
        stroke: ${props.stroke};
      }
    `}

  ${(props) =>
    props.fill &&
    css`
      path {
        fill: ${props.fill};
      }
    `}
`;

export interface IconProps {
  icon: IconKey;
  stroke?: keyof DefaultTheme["colors"];
  size?: number;
  className?: string;
  fill?: keyof DefaultTheme["colors"];
  style?: any;
}

const Icon = ({ icon, size, stroke, fill, className, style }: IconProps) => {
  const theme = useTheme();

  return (
    <IconWrapper
      className={className}
      stroke={stroke ? theme.colors[stroke] : undefined}
      fill={fill ? theme.colors[fill] : undefined}
      style={style}
    >
      {iconMap[icon] &&
        React.createElement(iconMap[icon], {
          ...(size
            ? {
                height: size,
                width: size,
              }
            : {}),
          ...(fill ? { fill: theme.colors[fill] } : {}),
        })}
    </IconWrapper>
  );
};

export default Icon;
