import Axios, { AxiosInstance } from "axios";
import { v4 } from "uuid";
import AuthClient from "./auth";
import ZamiWhatsappClient from "./whatsapp";
import ConversationClient from "./conversations";
import ZamiWebsocketClient, { IncomingWebsocketMessage } from "./socket";
import {
  User,
  InstagramAccount,
  WhatsappWebAccount,
  Workspace,
  WorkspaceMember,
  Team,
  WorkspaceRole,
  FacebookPage,
  Contact,
  Conversation,
  SavedReply,
  Label,
} from "../types";
import MessagesClient from "./messages";
import InstagramApiClient from "./instagram";
import FileClient from "./file";
import MessengerApiClient from "./messenger";
import MembersClient from "./members";
import WorkspaceClient from "./workspace";
import ChannelsClient from "./channels";
import NotesClient from "./notes";
import SavedRepliesClient from "./savedReplies";
import RolesClient from "./roles";
import ContactsClient from "./contacts";
import LabelsClient from "./labels";
import ScheduledMessagesClient from "./scheduledMessages";
import TeamsClient from "./teams";
import OnboardingClient from "./onboarding";
import NotificationsClient from "./notifications";

export interface DataUpdateSubscriber {
  onSelf: (self: User) => void;
  onWorkspace: (workspace: Workspace) => void;
  onWorkspaceMembers: (workspaceMembers: WorkspaceMember[]) => void;
  onWorkspaceMembersDeleted: (ids: string[]) => void;
  onWhatsappWebAccounts: (whatsappWebAccounts: WhatsappWebAccount[]) => void;
  onRoles: (roles: WorkspaceRole[]) => void;
  onLabels: (labels: Label[]) => void;
  onLabelsDeleted: (ids: string[]) => void;
  onTeams: (teams: Team[]) => void;
  onTeamsDeleted: (ids: string[]) => void;
  onInstagramAccounts: (instagramAccounts: InstagramAccount[]) => void;
  onMessengerAccounts: (messengerAccounts: FacebookPage[]) => void;
  onSavedReplies: (savedReplies: SavedReply[]) => void;
  onSavedRepliesDeleted: (ids: string[]) => void;
  onInitialLoadCompleted: () => void;
  getLastUpdatedContact: () => Promise<Contact | undefined>;
  getLastUpdatedConversation: () => Promise<Conversation | undefined>;
}

export default class ZamiAPIClient {
  private axios: AxiosInstance;
  private baseUrl: string;
  private authToken?: string;
  conversations: ConversationClient;
  wsMsgHandlers: Record<string, (msg: IncomingWebsocketMessage) => void>;
  whatsappClient?: ZamiWhatsappClient;
  workspaceId?: string;
  channelsClient: ChannelsClient;
  contacts: ContactsClient;
  labels: LabelsClient;
  instagram: InstagramApiClient;
  members: MembersClient;
  scheduledMessages: ScheduledMessagesClient;
  files: FileClient;
  websocketClient?: ZamiWebsocketClient;
  messagesClient?: MessagesClient;
  notificationsClient?: NotificationsClient;
  workspaces: WorkspaceClient;
  onboarding: OnboardingClient;
  teams: TeamsClient;
  savedReplies: SavedRepliesClient;
  notes: NotesClient;
  roles: RolesClient;
  messenger: MessengerApiClient;
  auth: AuthClient;
  dataUpdatesSubscriber?: DataUpdateSubscriber;

  constructor(baseUrl: string, authToken?: string) {
    this.baseUrl = baseUrl;
    this.authToken = authToken;
    this.wsMsgHandlers = { hola: () => 1 };

    this.axios = Axios.create({
      baseURL: baseUrl,
      headers: {
        Authorization: authToken ? authToken : undefined,
      },
    });

    this.onboarding = new OnboardingClient(this.axios);

    this.notes = new NotesClient(this.axios);

    this.notificationsClient = new NotificationsClient(this.axios);

    this.channelsClient = new ChannelsClient(this.axios);

    this.files = new FileClient(this.axios);

    this.conversations = new ConversationClient(this.axios);

    this.scheduledMessages = new ScheduledMessagesClient(this.axios);

    this.teams = new TeamsClient(this.axios, () => this.dataUpdatesSubscriber);

    this.members = new MembersClient(
      this.axios,
      () => this.dataUpdatesSubscriber
    );

    this.contacts = new ContactsClient(this.axios);

    this.roles = new RolesClient(this.axios, () => this.dataUpdatesSubscriber);

    this.labels = new LabelsClient(
      this.axios,
      () => this.dataUpdatesSubscriber
    );

    this.savedReplies = new SavedRepliesClient(
      this.axios,
      () => this.dataUpdatesSubscriber
    );

    this.workspaces = new WorkspaceClient(
      this.axios,
      () => this.dataUpdatesSubscriber
    );

    this.instagram = new InstagramApiClient(
      this.axios,
      () => this.dataUpdatesSubscriber
    );

    this.messenger = new MessengerApiClient(
      this.axios,
      () => this.dataUpdatesSubscriber
    );

    if (this.authToken) {
      this.whatsappClient = new ZamiWhatsappClient(
        this.baseUrl,
        this.authToken,
        () => this.dataUpdatesSubscriber
      );
    }

    this.auth = new AuthClient(this.axios);
  }

  setWorkspaceId(workspaceId: string) {
    this.workspaceId = workspaceId;
    this.axios.defaults.headers["X-Workspace-Id"] = workspaceId;
    // this.axios = Axios.create({
    //   baseURL: this.baseUrl,
    //   headers: {
    //     Authorization: this.authToken ? this.authToken : undefined,
    //     "X-Workspace-Id": workspaceId,
    //   },
    // });
  }

  subscribeMessageHandler(
    handler: (message: IncomingWebsocketMessage) => void
  ) {
    const handlerId = v4();

    this.wsMsgHandlers[handlerId] = handler;

    return () => {
      delete this.wsMsgHandlers[handlerId];
    };
  }

  startWebsocketConnection(
    subscriber: DataUpdateSubscriber,
    extra?: {
      onConnectionStateChange?: (state: string) => void;
      onFail?: () => void;
    }
  ) {
    this.dataUpdatesSubscriber = subscriber;
    if (!this.authToken) {
      throw new Error(
        "Attempted to create a websocket connection without an auth token"
      );
    }

    if (this.websocketClient) {
      throw new Error("Websocket connection already exists");
    }

    this.websocketClient = new ZamiWebsocketClient(
      this.baseUrl.replace("http", "ws"),
      this.authToken,
      () => this.wsMsgHandlers,
      () => this.dataUpdatesSubscriber
    );

    this.messagesClient = new MessagesClient(this.axios, this.websocketClient);

    this.websocketClient.start({
      onStateChange: extra?.onConnectionStateChange,
      onUnauthorized: () => {
        this.websocketClient?.stop();
        this.messagesClient = undefined;
        this.websocketClient = undefined;
        extra?.onFail?.();
      },
    });

    return () => {
      this.websocketClient?.stop();
      this.messagesClient = undefined;
      this.websocketClient = undefined;
    };
  }
}
