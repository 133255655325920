import React from "react";
import * as Avatar from "@radix-ui/react-avatar";
import styled from "styled-components";
import { ReactComponent as MessengerLogo } from "assets/channelLogos/messenger.svg";
import igPlaceholderImage from "assets/igplaceholder.jpeg";
import { ReactComponent as Check2Icon } from "assets/icons/check2.svg";
import { OnboardingStyles } from "./common";
import Box from "ui/Box";
import Text from "ui/Text";
import Button from "ui/Button";
import { useConnectMessenger } from "common/src/lib/messenger";
import ProgressWindow from "./progressWindow";
import Icon from "ui/Icon";
import { useHistory } from "react-router-dom";

const Root = styled(Avatar.Root)`
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
`;

const WhiteBox = styled(Box)`
  background-color: ${(props) => props.theme.colors.gray50};
  border: solid 1px ${(props) => props.theme.colors.gray200};
  margin-right: 1.5rem;
  padding: 2rem 1.5rem;
  border-radius: 12px;

  &:last-child {
    margin-right: 0;
  }
`;

const IGWrapper = styled.div`
  padding: 1rem 0rem;
  border-bottom: solid 1px ${(props) => props.theme.colors.gray200};
`;

const IgPic = styled(Avatar.Image)`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
`;

const Pic = styled.img`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
`;

const IgFallback = styled(Avatar.Fallback)`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
`;

function OnboardingConnectMessenger(props: { onFinish: () => void }) {
  const history = useHistory();
  const {
    loginLoading,
    startFacebookLogin,
    facebookPages,
    connectMessengerAccount,
  } = useConnectMessenger();

  return (
    <ProgressWindow currentStep={2}>
      <OnboardingStyles />
      <Box marginTop="4rem" padding="0rem 3rem">
        <Box
          flex
          alignItems="center"
          marginBottom="0.5rem"
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/onboarding/connect-first-channel")}
        >
          <Icon icon="arrowLeft" />
          <Text size="text_md" marginLeft="0.5rem">
            Atras
          </Text>
        </Box>
        <Box
          flex
          paddingBottom="1.5rem"
          borderBottom
          marginBottom="2rem"
          alignItems="center"
        >
          <Box marginRight="1rem">
            <MessengerLogo />
          </Box>
          <Box cols={1}>
            <Text
              size="display_xs"
              weight="semibold"
              style={{ marginBottom: 4 }}
            >
              Messenger
            </Text>

            <Text
              size="text_sm"
              weight="regular"
              color="gray600"
              style={{ maxWidth: 560 }}
            >
              Conecta tu página de facebook y podrás recibir y contestar los
              mensajes directos de messenger desde Zami.
            </Text>
          </Box>
          <Box flex alignItems="center">
            <Button
              isLoading={loginLoading}
              icon="puzzlePiece"
              size="md"
              onClick={startFacebookLogin}
            >
              Conectar Messenger
            </Button>
          </Box>
        </Box>

        <>
          <WhiteBox>
            <Text size="text_xl" weight="semibold" marginBottom="1.5rem">
              Para conectar Facebook Messenger tienes que cumplir con los
              siguientes requisitos:
            </Text>

            <Box flex alignItems="center" marginBottom="20px">
              <Check2Icon />
              <Text color="gray600" marginLeft="0.75rem">
                Tener al menos una página de Facebook.
              </Text>
            </Box>

            <Box flex alignItems="center">
              <Check2Icon />
              <Text color="gray600" marginLeft="0.75rem">
                Ser el administrador de la página de Facebook.
              </Text>
            </Box>
          </WhiteBox>

          {facebookPages !== undefined && (
            <Box marginTop="2rem">
              <Text size="text_xl" weight="semibold" marginBottom="0.25rem">
                Selecciona la página de Facebook que quieras conectar a Zami.
              </Text>
              <Text size="text_md" color="gray600" marginBottom="1.5rem">
                Encontramos {facebookPages.length} páginas de Facebook
                administradas por ti.
              </Text>
              {facebookPages.map((fbPage) => {
                return (
                  <IGWrapper>
                    <Box flex alignItems="center">
                      <Root>
                        <IgPic src={fbPage.data.picture.data.url} />
                        <IgFallback>
                          <Pic src={igPlaceholderImage} />
                        </IgFallback>
                      </Root>

                      <Text size="text_md" weight="semibold" cols={1}>
                        {fbPage.data.name}
                      </Text>
                      <Button
                        size="sm"
                        disabled={fbPage.status !== "not_connected"}
                        isLoading={fbPage.status === "loading"}
                        onClick={() => {
                          connectMessengerAccount(fbPage.data);
                        }}
                      >
                        {fbPage.status === "connected"
                          ? "Connected"
                          : "Conectar"}
                      </Button>
                    </Box>
                  </IGWrapper>
                );
              })}
            </Box>
          )}
        </>
      </Box>
    </ProgressWindow>
  );
}

export default OnboardingConnectMessenger;
