import React from "react";
import styled, { css } from "styled-components";

export interface BoxProps extends React.HTMLAttributes<HTMLDivElement> {
  padding?: string;
  paddingTop?: string;
  paddingLeft?: string;
  paddingRight?: string;
  paddingBottom?: string;
  marginBottom?: string;
  margin?: string;
  marginTop?: string;
  marginLeft?: string;
  marginRight?: string;
  borderBottom?: boolean;
  borderTop?: boolean;
  inline?: boolean;
  flex?: boolean;
  mobileOnly?: boolean;
  desktopOnly?: boolean;
  direction?: "row" | "column";
  wrap?: boolean;
  cols?: number;
  alignItems?: "center" | "flex-start" | "flex-end" | "stretch";
  justifyContent?: "center" | "flex-start" | "flex-end" | "space-between";
}

const BoxWrapper = styled.div<BoxProps>`
  ${(props) =>
    props.inline &&
    css`
      display: inline;
    `}
  ${(props) =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom};
    `}

  ${(props) =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop};
    `}

  ${(props) =>
    props.marginLeft &&
    css`
      margin-left: ${props.marginLeft};
    `}
  
  ${(props) =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}

  ${(props) =>
    props.marginRight &&
    css`
      margin-right: ${props.marginRight};
    `}
    ${(props) =>
      props.cols &&
      css`
        flex: ${props.cols};
      `}
  
  ${(props) =>
    props.flex &&
    css`
      display: flex;
    `}
  
    ${(props) =>
      props.direction &&
      css`
        flex-direction: ${props.direction};
      `}

    ${(props) =>
      props.alignItems &&
      css`
        align-items: ${props.alignItems};
      `}

    ${(props) =>
      props.justifyContent &&
      css`
        justify-content: ${props.justifyContent};
      `}

    ${(props) =>
      props.paddingLeft &&
      css`
        padding-left: ${props.paddingLeft};
      `}

    ${(props) =>
      props.paddingRight &&
      css`
        padding-right: ${props.paddingRight};
      `}

    ${(props) =>
      props.paddingBottom &&
      css`
        padding-bottom: ${props.paddingBottom};
      `}
    ${(props) =>
      props.wrap &&
      css`
        flex-wrap: wrap;
      `}

    ${(props) =>
      props.borderBottom &&
      css`
        border-bottom: solid 1px ${props.theme.colors.gray200};
      `}

      ${(props) =>
        props.borderTop &&
        css`
          border-top: solid 1px ${props.theme.colors.gray200};
        `}
    
      ${(props) =>
        props.paddingTop &&
        css`
          padding-top: ${props.paddingTop};
        `}

        ${(props) =>
          props.mobileOnly &&
          css`
            display: none;
            ${props.theme.mediaQueries.mobile} {
              display: block;

              ${props.flex &&
              css`
                display: flex;
              `}
            }
          `}

          ${(props) =>
            props.desktopOnly &&
            css`
              ${props.theme.mediaQueries.mobile} {
                display: none;
              }
            `}
  }
`;

const Box = ({ ...props }: BoxProps) => {
  return <BoxWrapper {...props}>{props.children}</BoxWrapper>;
};

export default Box;
