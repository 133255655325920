import React from "react";
import { useIntercom } from "react-use-intercom";
import styled, { css } from "styled-components";
import Box from "ui/Box";
import Button from "ui/Button";
import Icon from "ui/Icon";
import Text from "ui/Text";

const ProgressBar = styled(Box)`
  background-color: ${(props) => props.theme.colors.primary900};
  padding: 88px 48px;
  padding-bottom: 24px;
  flex: none;
`;

const Line = styled.div`
  height: 46px;
  margin: 6px 0px;
  width: 2px;
  background-color: ${(props) => props.theme.colors.primary200};
  border-radius: 1px;
`;

const StepCircle = styled.div<{ highlighted: boolean }>`
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  border: solid 2px ${(props) => props.theme.colors.primary200};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.primary200};

  ${(props) =>
    props.highlighted &&
    css`
      background-color: ${props.theme.colors.primary100};
      border: solid 2px ${props.theme.colors.primary100};
      color: ${props.theme.colors.primary600};
    `}
`;

function OnboardingStep(props: {
  stepNumber: number;
  currentStep: number;
  title: string;
  desc: string;
  isLast?: boolean;
  done?: boolean;
}) {
  return (
    <Box flex>
      <Box flex direction="column" alignItems="center">
        <StepCircle highlighted={props.currentStep >= props.stepNumber}>
          {props.done ? (
            <Icon icon="tick" />
          ) : (
            <Text size="text_md" weight="bold">
              {props.stepNumber}
            </Text>
          )}
        </StepCircle>

        {!props.isLast && <Line />}
      </Box>

      <Box marginLeft="1rem">
        <Box
          style={{ height: 32 }}
          paddingTop="2px"
          paddingBottom="2px"
          flex
          alignItems="center"
        >
          <Text size="text_md" weight="semibold" color="white">
            {props.title}
          </Text>
        </Box>
        <Text size="text_md" weight="regular" color="primary200">
          {props.desc}
        </Text>
      </Box>
    </Box>
  );
}

export default function ProgressWindow(props: {
  children: React.ReactNode;
  currentStep: number;
}) {
  const { currentStep } = props;
  const intercom = useIntercom();
  return (
    <Box flex alignItems="stretch" style={{ height: "100vh" }}>
      <Box cols={1}>{props.children}</Box>

      <ProgressBar style={{ height: "100%", minWidth: 0 }}>
        <Box
          flex
          direction="column"
          justifyContent="flex-end"
          style={{ height: "100%" }}
        >
          <Box cols={1}>
            <OnboardingStep
              stepNumber={1}
              currentStep={currentStep}
              title="Activar cuenta"
              desc="Activación de la cuenta de Zami"
              done={currentStep > 1}
            />
            <OnboardingStep
              stepNumber={2}
              currentStep={currentStep}
              title="Conectar canales"
              desc="WhatsApp, Instagram y Messenger"
              done={currentStep > 2}
            />
            <OnboardingStep
              stepNumber={3}
              currentStep={currentStep}
              title="Invitar equipo"
              desc="A través de correo electrónico"
              done={currentStep > 3}
            />
            <OnboardingStep
              stepNumber={4}
              currentStep={currentStep}
              title="Descargar app móvil"
              desc="Disponible para iOS y Android"
              isLast
              done={currentStep > 4}
            />
          </Box>

          <Box>
            <Text size="text_md" weight="semibold" color="white">
              ¿Necesitas ayuda?
            </Text>

            <Text
              size="text_sm"
              weight="regular"
              color="primary200"
              marginTop="0.75rem"
              marginBottom="1rem"
            >
              No dudes en contactarnos si necesitas ayuda durante el proceso.
            </Text>

            <Button
              kind="primary"
              size="sm"
              onClick={() => {
                intercom.show();
              }}
            >
              Contactar
            </Button>
          </Box>
        </Box>
      </ProgressBar>
    </Box>
  );
}
