import { DataUpdateSubscriber } from "../client";
import { WhatsappWebAccount } from "../types";

type ConnectWhatsappMessageData =
  | {
      type: "code";
      code: string;
    }
  | {
      type: "success";
      whatsappWebAccount: WhatsappWebAccount;
    };

export default class ZamiWhatsappClient {
  constructor(
    private baseUrl: string,
    private authToken: string,
    private dataUpdatesSubscriber: () => DataUpdateSubscriber | undefined
  ) {}

  connectNewWhatsappAccount(params: {
    onQr: (qrCode: string) => void;
    onTimeout: () => void;
    onSuccess: () => void;
  }) {
    const abortController = new AbortController();
    (async (ctrl: AbortController) => {
      const response = await fetch(`${this.baseUrl}/whatsapp/connect`, {
        signal: ctrl.signal,
        headers: {
          Authorization: `${this.authToken}`,
        },
      });

      const reader = response.body?.getReader();

      while (reader) {
        const result = await reader.read();

        if (result.done) {
          break;
        }

        const msg = new TextDecoder().decode(result.value);
        const messageData = JSON.parse(msg) as ConnectWhatsappMessageData;

        console.log(messageData);

        if (messageData.type === "code") {
          params.onQr(messageData.code);
        } else if (messageData.type === "success") {
          this.dataUpdatesSubscriber()?.onWhatsappWebAccounts([
            messageData.whatsappWebAccount,
          ]);
          params.onSuccess();
          break;
        }
      }
      params.onTimeout();
      abortController.abort();
    })(abortController).catch((err) => {});

    return () => {
      abortController.abort();
    };
  }

  connectNewWhatsappAccountWithPhone(
    phone: string,
    params: {
      onCode: (code: string) => void;
      onTimeout: () => void;
      onSuccess: () => void;
    }
  ) {
    const abortController = new AbortController();
    (async (ctrl: AbortController) => {
      const response = await fetch(
        `${this.baseUrl}/whatsapp/connect/phone?phone=${phone}`,
        {
          signal: ctrl.signal,
          headers: {
            Authorization: `${this.authToken}`,
          },
          ["reactNative" as unknown as any]: { textStreaming: true },
        }
      );
      const reader = response.body?.getReader();

      while (reader) {
        const result = await reader.read();

        if (result.done) {
          break;
        }

        const msg = new TextDecoder().decode(result.value);
        const messageData = JSON.parse(msg) as ConnectWhatsappMessageData;

        console.log(messageData);

        if (messageData.type === "code") {
          params.onCode(messageData.code);
        } else if (messageData.type === "success") {
          this.dataUpdatesSubscriber()?.onWhatsappWebAccounts([
            messageData.whatsappWebAccount,
          ]);
          params.onSuccess();
          break;
        }
      }
      params.onTimeout();
      abortController.abort();
    })(abortController).catch((err) => {});

    return () => {
      abortController.abort();
    };
  }
}
