import React from "react";

export interface Config {
  fbAppId?: string;
}

export const ConfigContext = React.createContext<Config>({});

export const ConfigProvider = ConfigContext.Provider;

export const useConfig = () => React.useContext(ConfigContext);
