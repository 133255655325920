import React from "react";
import dayjs from "dayjs";
import { useLiveQuery } from "dexie-react-hooks";
import { Contact } from "../types";
import { ZamiDexie, useIDB } from "./idb";
import { useApiClient } from "../context";

export interface IDBContact {
  id: string;
  lastUpdateAt: Date;
  data: Contact;
}

export async function putContacts(idb: ZamiDexie, contacts: Contact[]) {
  const idbContacts: IDBContact[] = [];
  for (const contact of contacts) {
    if (contact.deleted) {
      await idb.contacts.delete(contact.id).catch((err) => false);
      continue;
    }

    idbContacts.push({
      id: contact.id,
      lastUpdateAt: dayjs(contact.lastUpdateAt).toDate(),
      data: contact,
    });
  }

  console.log("bulk putting...", idbContacts.length);
  await idb.contacts.bulkPut(idbContacts);
}

export function useContacts() {
  const idb = useIDB();
  const [searchQuery, setSearchQuery] = React.useState("");
  const [selectedLabels, setSelectedLabels] = React.useState<string[]>();

  const idbContacts = useLiveQuery(async () => {
    return (await idb.contacts.orderBy("lastUpdateAt").toArray()) ?? [];
  });

  const contacts = React.useMemo(() => {
    let result =
      idbContacts?.filter((c) => !c.data.zamiId).map((c) => c.data) ?? [];

    if (searchQuery) {
      result = result.filter((c) =>
        c.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (selectedLabels?.length ?? 0 > 0) {
      result = result.filter((c) =>
        c.labels?.some((l) => selectedLabels!.includes(l))
      );
    }

    return result;
  }, [idbContacts, searchQuery, selectedLabels]);

  return {
    contacts,
    searchQuery,
    setSearchQuery,
    selectedLabels,
    setSelectedLabels,
  };
}

export function useSubscribeToNewContacts() {
  const idb = useIDB();
  const apiClient = useApiClient();

  const lastContact = useLiveQuery(() =>
    idb.contacts.orderBy("lastUpdateAt").last()
  );

  React.useEffect(() => {
    const unsubscribe = apiClient.subscribeMessageHandler((wsMessage) => {
      if (wsMessage.type === "new_contacts") {
        putContacts(idb, wsMessage.data);
      }
    });

    if (!lastContact) {
      return unsubscribe;
    }

    try {
      apiClient.websocketClient?.sendContactSyncPing(lastContact.lastUpdateAt);
    } catch (err) {
      throw err;
    }

    return () => {
      unsubscribe();
    };
  }, [apiClient, idb, lastContact]);
}

export function useHistoricalContactsLoader() {
  const idb = useIDB();
  const apiClient = useApiClient();

  const syncHistoricalContacts = async () => {
    const oldestContact = await idb.contacts.orderBy("lastUpdateAt").first();

    const result = await apiClient.contacts.fetchContacts({
      before: oldestContact?.lastUpdateAt?.toISOString(),
      limit: 1000,
    });

    putContacts(idb, result);

    if (result.length === 1000) {
      setTimeout(syncHistoricalContacts, 200);
    }
  };

  React.useEffect(() => {
    syncHistoricalContacts();
  }, [apiClient, idb]);
}

export function useConversationsLoader() {
  useHistoricalContactsLoader();
  useSubscribeToNewContacts();
}

export function useContactActions() {
  const idb = useIDB();
  const apiClient = useApiClient();

  const createContact = React.useCallback(
    async (payload: {
      name: string;
      email: string;
      phone: string;
      phoneCountryCode: string;
    }) => {
      const result = await apiClient.contacts.createContact(payload);

      if (result.ok) {
        putContacts(idb, [result.contact]);
      }

      return result;
    },
    [apiClient]
  );

  const updateContact = React.useCallback(
    async (contactId: string, payload: { name?: string; email?: string }) => {
      const result = await apiClient.contacts.updateContact(contactId, payload);

      if (result) {
        putContacts(idb, [result]);
      }

      return result;
    },
    [apiClient]
  );

  const labelContact = React.useCallback(
    async (contactId: string, labelId: string) => {
      const contact = await idb.contacts.get(contactId);

      if (!contact) {
        throw new Error("Contact not found");
      }

      const originalContact = { ...contact };

      const updatedContact = {
        ...contact,
        data: {
          ...contact.data,
          labels: [...(contact.data.labels ?? []), labelId],
        },
      };

      await idb.contacts.put(updatedContact);

      try {
        await apiClient.contacts.addLabelToContact(contactId, labelId);
      } catch (err) {
        await idb.contacts.put(originalContact);
        throw err;
      }

      return updatedContact.data;
    },
    [idb]
  );

  const removeLabelFromContact = React.useCallback(
    async (contactId: string, labelId: string) => {
      const contact = await idb.contacts.get(contactId);

      if (!contact) {
        throw new Error("Contact not found");
      }

      const originalContact = { ...contact };

      const updatedContact = {
        ...contact,
        data: {
          ...contact.data,
          labels: (contact.data.labels ?? []).filter((l) => l !== labelId),
        },
      };

      await idb.contacts.put(updatedContact);

      try {
        await apiClient.contacts.removeLabelFromContact(contactId, labelId);
      } catch (err) {
        await idb.contacts.put(originalContact);
        throw err;
      }

      return updatedContact.data;
    },
    [idb]
  );

  return { createContact, updateContact, labelContact, removeLabelFromContact };
}

export function ContactsLoader(props: { children: React.ReactNode }) {
  useConversationsLoader();

  return React.createElement("div", {}, props.children);
}
