import React, { useState } from "react";
import styled from "styled-components";
import { DialogProps } from "@radix-ui/react-dialog";

import { Contact } from "common/src/types";

import Button from "ui/Button";
import Icon from "ui/Icon";
import Modal from "ui/Modal";
import { useApiClient } from "common";

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  min-width: 48px;
  min-height: 48px;
  width: 48px;
  height: 48px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  background-color: ${(props) => props.theme.colors.white};
`;

type DeleteContactModalProps = {
  contact?: Contact | null;
  contactId: string;
  onDelete?: () => void;
  trigger?: React.ReactNode;
} & DialogProps;

const DeleteContactModal = ({
  contact,
  contactId,
  ...props
}: DeleteContactModalProps) => {
  const apiClient = useApiClient();
  const [isDeleting, setDeleting] = useState(false);

  const deleteContact = async () => {
    setDeleting(true);
    try {
      await apiClient.contacts.deleteContact(contactId);
      props.onDelete?.();
      props.onOpenChange && props.onOpenChange(false);
    } catch (err) {
      // TODO: Track err
    }
    setDeleting(false);
  };

  return (
    <Modal {...props}>
      <IconWrapper>
        <Icon icon="trash" size={24} stroke="error700" />
      </IconWrapper>
      <Modal.Title>
        ¿Estás seguro de que quieres eliminar a{" "}
        {contact?.name ? contact.name + " de tus contactos?" : "este contacto?"}
      </Modal.Title>
      <Modal.Description>Esta acción no se puede deshacer.</Modal.Description>
      <Modal.Actions>
        <div
          style={{
            display: "flex",
            gap: "0.75rem",
            justifyContent: "flex-end",
          }}
        >
          <Modal.Cancel asChild>
            <Button block kind="secondary" size="md">
              Cancelar
            </Button>
          </Modal.Cancel>
          <Button
            isLoading={isDeleting}
            block
            kind="danger"
            size="md"
            onClick={deleteContact}
          >
            Eliminar
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

export default DeleteContactModal;
