import React from "react";
import Header from "ui/Header";
import styled from "styled-components";
import Badge from "ui/Badge";
import Button from "ui/Button";
import { Table, THead, Tr, Th, TBody, Td } from "ui/Table";
import Box from "ui/Box";
import ProfilePic from "components/ProfilePic";
import { WorkspaceMember } from "common/src/types";
import Text from "ui/Text";
import TeamMemberModal from "./TeamMemberModal";
import Dropdown from "ui/Dropdown";
import IconButton from "ui/IconButton";
import Icon from "ui/Icon";
import { useAppData } from "common/src/lib/appData";
import {
  CancelTeamMemberInvitationModal,
  DeactivateTeamMemberModal,
  ReactivateTeamMemberModal,
  ResendWorkspaceMemberInvitationModal,
} from "./modals";

const Wrapper = styled.div`
  flex: 1;
  padding: 0rem 2rem;
  overflow: auto;
`;

const ManageTeamMembers = () => {
  const appData = useAppData();
  const [inviteMemberModalOpen, setInviteMemberModalOpen] =
    React.useState(false);
  const [workspaceMemberToUpdate, setWorkspaceMemberToUpdate] =
    React.useState<WorkspaceMember>();
  const [resendInvitationForMember, setResendInvitationForMember] =
    React.useState<WorkspaceMember>();
  const [memberInvitationToCancel, setMemberInvitationToCancel] =
    React.useState<WorkspaceMember>();

  const [memberToDeactivate, setMemberToDeactivate] =
    React.useState<WorkspaceMember>();

  const [memberToReactivate, setMemberToReactivate] =
    React.useState<WorkspaceMember>();

  const teamMembers = React.useMemo(() => {
    return Object.values(appData.workspaceMembers);
  }, [appData.workspaceMembers]);

  return (
    <>
      <Wrapper>
        <Header
          title="Miembros de tu espacio de trabajo"
          subtitle="Administra los miembros de tu espacio de trabajo y los permisos de sus cuentas aquí."
          badge={
            <Badge
              label={`${teamMembers?.length ?? ""} ${
                teamMembers?.length ?? 0 > 1 ? "miembros" : "miembro"
              }`}
              size="sm"
              variant="success"
            />
          }
          actions={
            <Button
              size="sm"
              icon="plus"
              onClick={() => setInviteMemberModalOpen(true)}
            >
              Invitar miembro
            </Button>
          }
        />

        <Table>
          <THead>
            <Tr>
              <Th colSpan={2}>Nombre</Th>
              <Th>Rol</Th>
              <Th>Equipos</Th>
              <Th></Th>
            </Tr>
          </THead>
          <TBody>
            {teamMembers?.map((member) => (
              <Tr key={member.id}>
                <Td colSpan={2}>
                  <Box flex alignItems="center">
                    <ProfilePic id={member.id} name={member.name} />
                    <Text
                      marginLeft="0.75rem"
                      size="text_sm"
                      weight="medium"
                      color="gray900"
                      marginRight="0.5rem"
                    >
                      {member.name}
                    </Text>
                    {member.status === "pendingInvitation" && (
                      <Badge label="Pendiente" size="sm" />
                    )}

                    {member.status === "inactive" && (
                      <Badge label="Desactivado" size="sm" />
                    )}
                  </Box>
                </Td>
                <Td>
                  {member.isSuperuser
                    ? "Administrador"
                    : appData.roles[member.roleId ?? ""]?.name}
                </Td>
                <Td>
                  {member.teams
                    ?.map((team) => appData.teams[team]?.name)
                    .join(", ")}
                </Td>

                <Td>
                  <Box flex justifyContent="flex-end">
                    {member.id !== appData.workspaceMemberSelf.id && (
                      <Dropdown
                        trigger={
                          <div style={{ cursor: "pointer" }}>
                            <IconButton size="sm" icon="dotsVertical" />
                          </div>
                        }
                      >
                        <Dropdown.Item
                          onClick={() => setWorkspaceMemberToUpdate(member)}
                        >
                          <Box marginRight="0.5rem">
                            <Icon icon="edit" />
                          </Box>
                          Modificar miembro
                        </Dropdown.Item>

                        {member.status === "pendingInvitation" && (
                          <>
                            <Dropdown.Item
                              onClick={() =>
                                setResendInvitationForMember(member)
                              }
                            >
                              <Box marginRight="0.5rem">
                                <Icon icon="send" size={16} stroke="gray600" />
                              </Box>
                              Re-enviar invitación
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                setMemberInvitationToCancel(member)
                              }
                            >
                              <Box marginRight="0.5rem">
                                <Icon icon="trash" />
                              </Box>
                              Cancelar invitación
                            </Dropdown.Item>
                          </>
                        )}

                        {member.status === "active" &&
                          member.id !== appData.workspaceMemberSelf.id && (
                            <Dropdown.Item
                              onClick={() => setMemberToDeactivate(member)}
                            >
                              <Box marginRight="0.5rem">
                                <Icon icon="trash" />
                              </Box>
                              Suspender miembro
                            </Dropdown.Item>
                          )}

                        {member.status === "inactive" &&
                          member.id !== appData.workspaceMemberSelf.id && (
                            <Dropdown.Item
                              onClick={() => setMemberToReactivate(member)}
                            >
                              <Box marginRight="0.5rem">
                                <Icon icon="trash" />
                              </Box>
                              Reactivar miembro
                            </Dropdown.Item>
                          )}
                      </Dropdown>
                    )}
                  </Box>
                </Td>
              </Tr>
            ))}
          </TBody>
        </Table>
      </Wrapper>
      <TeamMemberModal
        open={inviteMemberModalOpen || Boolean(workspaceMemberToUpdate)}
        member={workspaceMemberToUpdate}
        onOpenChange={(newOpen) => {
          setInviteMemberModalOpen(newOpen);

          if (newOpen === false) {
            setWorkspaceMemberToUpdate(undefined);
          }
        }}
        onTeamMemberInvited={async () => {
          setInviteMemberModalOpen(false);
          setWorkspaceMemberToUpdate(undefined);
        }}
      />

      {resendInvitationForMember && (
        <ResendWorkspaceMemberInvitationModal
          member={resendInvitationForMember}
          onOpenChange={() => setResendInvitationForMember(undefined)}
        />
      )}
      {memberInvitationToCancel && (
        <CancelTeamMemberInvitationModal
          onOpenChange={() => setMemberInvitationToCancel(undefined)}
          invitationToCancel={memberInvitationToCancel}
        />
      )}
      {memberToDeactivate && (
        <DeactivateTeamMemberModal
          workspaceMember={memberToDeactivate}
          onOpenChange={() => setMemberToDeactivate(undefined)}
        />
      )}
      {memberToReactivate && (
        <ReactivateTeamMemberModal
          workspaceMember={memberToReactivate}
          onOpenChange={() => setMemberToReactivate(undefined)}
        />
      )}
    </>
  );
};

export default ManageTeamMembers;
