import React from "react";
import { useAppData } from "common/src/lib/appData";
import { Link, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import Icon from "ui/Icon";
import Text from "ui/Text";
import ChannelSettings from "./ChannelSettings";
import ConnectWhatsappWebAccount from "./ConnectWhatsappWebAccount";
import InstagramChannelConfigure from "./InstagramChannelConfigure";
import InstagramSettings from "./InstagramSettings";
import MessengerChannelConfigure from "./MessengerChannelConfigure";
import MessengerSettings from "./MessengerSettings";
import RoleForm from "./roles/form";
import RolesSettings from "./roles/roles";
import ManageTeamMembers from "./team/ManageTeamMembers";
import TeamsList from "./teams/list";
import WhatsappChannelConfigure from "./WhatsappChannelConfigure";
import WhatsappSettings from "./WhatsappSettings";
import WhatsappWebChannelConfigure from "./WhatsappWebChannelConfigure";
import Box from "ui/Box";
import InstagramDebug from "./InstagramDebug";

const NavigationWrapper = styled.div`
  padding: 2.25rem 1rem;
  width: 17.5rem;
  border-right: 1px solid ${(props) => props.theme.colors.gray200};

  a {
    all: unset;
  }
`;

const NavLink = styled.div`
  padding: 8px 12px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  margin-bottom: 0.25rem;
  transition-duration: 200ms;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: ${(props) => props.theme.colors.gray50};
  }
`;

const Settings = () => {
  const appData = useAppData();
  const isSuperuser = appData.workspaceMemberSelf.isSuperuser;
  const [redirectingToStripePortal, setRedirectingToStripePortal] =
    React.useState(false);

  const goToStripeCustomerPortal = async () => {
    setRedirectingToStripePortal(true);
    // const response = await restClient.post<{ url: string }>(
    //   "/accounts/stripe-customer-portal-session"
    // );
    const response = null as any;
    setRedirectingToStripePortal(false);
    window.location.href = response.data.url;
  };

  return (
    <>
      <NavigationWrapper>
        <Text size="text_md" weight="medium" marginBottom="1rem">
          Configuración
        </Text>

        {isSuperuser && (
          <>
            <Link to="/settings/team">
              <NavLink>
                <Icon icon="contacts" />
                <Text size="text_md" weight="semibold" marginLeft="0.5rem">
                  Miembros
                </Text>
              </NavLink>
            </Link>

            <Link to="/settings/roles">
              <NavLink>
                <Icon icon="key" />
                <Text size="text_md" weight="semibold" marginLeft="0.5rem">
                  Roles
                </Text>
              </NavLink>
            </Link>

            <Link to="/settings/teams">
              <NavLink>
                <Icon icon="contacts" />
                <Text size="text_md" weight="semibold" marginLeft="0.5rem">
                  Equipos
                </Text>
              </NavLink>
            </Link>

            {(false as any) && (
              <Box
                onClick={
                  redirectingToStripePortal
                    ? undefined
                    : goToStripeCustomerPortal
                }
              >
                <NavLink>
                  <Icon icon="card" />
                  <Text size="text_md" weight="semibold" marginLeft="0.5rem">
                    Suscripción
                  </Text>
                </NavLink>
              </Box>
            )}

            <Link to="/settings/channels">
              <NavLink>
                <Icon icon="channels" stroke="gray500" />
                <Text size="text_md" weight="semibold" marginLeft="0.5rem">
                  Canales
                </Text>
              </NavLink>
            </Link>
          </>
        )}
      </NavigationWrapper>
      <Switch>
        <Route path="/settings/team" component={ManageTeamMembers} />

        {isSuperuser && (
          <Switch>
            <Route
              exact
              path="/settings/channels/instagram/:username"
              component={InstagramChannelConfigure}
            />
            <Route
              exact
              path="/settings/channels/facebook-page/:fbPageId"
              component={MessengerChannelConfigure}
            />
            <Route
              exact
              path="/settings/channels/whatsapp/:whatsappBusinessAccountId"
              component={WhatsappChannelConfigure}
            />
            <Route
              exact
              path="/settings/channels/whatsapp-web/:whatsappWebAccountId"
              component={WhatsappWebChannelConfigure}
            />
            <Route
              exact
              path="/settings/channels/whatsapp"
              component={WhatsappSettings}
            />
            <Route
              exact
              path="/settings/channels/whatsapp-web"
              component={ConnectWhatsappWebAccount}
            />
            <Route
              exact
              path="/settings/channels/messenger"
              component={MessengerSettings}
            />
            <Route
              exact
              path="/settings/channels/instagram"
              component={InstagramSettings}
            />
            <Route
              exact
              path="/settings/channels/instagram/debug/:fbAccessToken"
              component={InstagramDebug}
            />
            <Route path="/settings/channels" component={ChannelSettings} />
            <Route exact path="/settings/teams" component={TeamsList} />
            <Route exact path="/settings/roles/form" component={RoleForm} />
            <Route exact path="/settings/roles" component={RolesSettings} />
            <Route
              exact
              path="/settings/roles/:roleId/form"
              component={RoleForm}
            />
          </Switch>
        )}
      </Switch>
    </>
  );
};

export default Settings;
