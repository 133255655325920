import React from "react";
import { useApiClient } from "common";

interface AudioPlayerProps {
  fileId: string;
}

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const AudioPlayer = ({ fileId }: AudioPlayerProps) => {
  const [audioUrl, setAudioUrl] = React.useState<string>();
  const apiClient = useApiClient();

  React.useEffect(() => {
    (async () => {
      const fileUrl = await apiClient.files.getFileUrl(fileId);
      setAudioUrl(fileUrl);
    })();
  }, [apiClient, fileId]);

  if (isSafari) {
    return (
      <audio controls>
        <source src={audioUrl} type="audio/mpeg" />
      </audio>
    );
  }

  return <audio controls src={audioUrl} />;
};

export default AudioPlayer;
