import styled from "styled-components";

import { MessageChannel } from "common/src/types";

import Icon, { iconMap } from "ui/Icon";

const Wrapper = styled.div`
  display: flex;

  & .icon {
    &:not(:first-child) {
      margin-left: -8px;
    }
  }
`;

type ChannelBadgesProps = {
  channels: MessageChannel[];
};

const channelIconMap: Record<MessageChannel, keyof typeof iconMap> = {
  instagram: "instagramBadge",
  whatsapp_web: "whatsappBadge",
  page: "messengerBadge",
  email: "emailBadge",
  zami: "zamiBadge",
};

const ChannelBadges = ({ channels }: ChannelBadgesProps) => {
  return (
    <Wrapper>
      {channels.map((c) => (
        <Icon className="icon" key={c} icon={channelIconMap[c]} size={24} />
      ))}
    </Wrapper>
  );
};

export default ChannelBadges;
