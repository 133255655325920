import { useApiClient } from "common/src/context";
import React from "react";

interface FBImageProps {
  fileId: string;
}

const downloadUrls: Record<string, string> = {};

const Image = ({
  fileId,
  ...restProps
}: FBImageProps & React.ImgHTMLAttributes<HTMLImageElement>) => {
  const [imageUrl, setImageUrl] = React.useState<string>();
  const apiClient = useApiClient();

  React.useEffect(() => {
    (async () => {
      if (!downloadUrls[fileId]) {
        downloadUrls[fileId] = await apiClient.files.getFileUrl(fileId);
      }

      if (imageUrl !== downloadUrls[fileId]) {
        setImageUrl(downloadUrls[fileId]);
      }
    })();
  }, [fileId, imageUrl, apiClient]);

  return <img alt="" src={imageUrl} {...restProps} />;
};

export const Video = ({
  fileId,
  contentType,
  width,
  height,
  controls,
  onClick,
  style,
  ...restProps
}: {
  fileId: string;
  contentType: string;
  width?: number;
  height?: number;
} & FBImageProps &
  React.VideoHTMLAttributes<HTMLVideoElement>) => {
  const [videoUrl, setVideoUrl] = React.useState<string>();
  const apiClient = useApiClient();

  React.useEffect(() => {
    (async () => {
      if (!downloadUrls[fileId]) {
        const downloadUrl = await apiClient.files.getFileUrl(fileId);
        downloadUrls[fileId] = downloadUrl;
      }

      if (videoUrl !== downloadUrls[fileId]) {
        setVideoUrl(downloadUrls[fileId]);
      }
    })();
  }, [fileId, videoUrl, apiClient]);

  if (!videoUrl) {
    return null;
  }

  return (
    <video
      width={width?.toString() ?? "112"}
      height={height?.toString() ?? "199"}
      controls={controls ?? true}
      style={style}
      onClick={onClick}
      {...restProps}
    >
      <source src={videoUrl} type={contentType} />
    </video>
  );
};

export default Image;
