import { AxiosInstance } from "axios";
import { WorkspaceRole } from "../types";
import { DataUpdateSubscriber } from "../client";

export default class RolesClient {
  constructor(
    private readonly axios: AxiosInstance,
    private readonly dataUpdatesSubscriber: () =>
      | DataUpdateSubscriber
      | undefined
  ) {}

  async createRole(role: Omit<WorkspaceRole, "id">) {
    const response = await this.axios.post<WorkspaceRole>("/roles", role);

    if (response.status !== 201) {
      throw new Error("Failed to create role");
    }

    this.dataUpdatesSubscriber()?.onRoles([response.data]);

    return response.data;
  }

  async updateRole(role: WorkspaceRole) {
    const response = await this.axios.put<WorkspaceRole>(
      `/roles/${role.id}`,
      role
    );

    if (response.status !== 200) {
      throw new Error("Failed to update role");
    }

    this.dataUpdatesSubscriber()?.onRoles([response.data]);

    return response.data;
  }
}
