import { Contact, WorkspaceMember } from "common/src/types";
import dayjs from "dayjs";
import Image, { Video } from "components/Image";
import ProfilePic from "components/ProfilePic";
import React from "react";
import styled, { css } from "styled-components";
import Box from "ui/Box";
import Text from "ui/Text";
import { capitalize } from "./utils";
import Icon from "ui/Icon";
import { useApiClient } from "common";

const PreviewWrapper = styled.div<{ visible: boolean }>`
  background-color: rgba(52, 64, 84, 0.9);
  backdrop-filter: blur(4px);
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2rem 1.5rem;
  pointer-events: none;
  transition-duration: 300ms;
  opacity: 0;

  ${(props) =>
    props.visible &&
    css`
      pointer-events: initial;
      opacity: 1;
    `}
`;

interface BasePreview {
  contact?: {
    id: string;
    data: Contact;
  };
  workspaceMember?: {
    id: string;
    data: WorkspaceMember;
  };
  messageTimestamp?: number;
}

interface ImagePreview extends BasePreview {
  type: "image";
  fileId: string;
}

interface VideoPreview extends BasePreview {
  type: "video";
  fileId: string;
  videoMimetype: string;
}

type Preview = ImagePreview | VideoPreview;

const ImagePreviewContext = React.createContext<{
  preview?: Preview | undefined;
  setPreview: (preview: Preview) => void;
}>({
  setPreview: undefined as any,
});

const ActionIconWrapper = styled(Box)`
  cursor: pointer;
`;

export const ImagePreviewProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [preview, setPreview] = React.useState<Preview>();
  const apiClient = useApiClient();

  const ctxVal = React.useMemo(() => {
    return {
      preview,
      setPreview,
    };
  }, [preview, setPreview]);

  const download = async () => {
    if (preview?.type === "image") {
      const url = await apiClient.files.getFileUrl(preview.fileId);
      const a = document.createElement("a");
      a.href = url;
      a.download = "thefilenane.jpg";
      a.target = "_blank";
      a.click();
    } else if (preview?.type === "video") {
      const url = await apiClient.files.getFileUrl(preview.fileId);
      const a = document.createElement("a");
      a.href = url;
      a.download = "thefilenane.jpg";
      a.target = "_blank";
      a.click();
    }
  };

  React.useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        e.preventDefault();
        setPreview(undefined);
      }
    };

    document.addEventListener("keydown", listener);
    return () => document.removeEventListener("keydown", listener);
  }, []);

  return (
    <ImagePreviewContext.Provider value={ctxVal}>
      {children}
      <PreviewWrapper visible={Boolean(preview)}>
        <Box flex justifyContent="space-between" alignItems="flex-start">
          <Box>
            {preview?.contact && (
              <Box flex alignItems="center">
                <Box marginRight="0.5rem">
                  <ProfilePic
                    id={preview.contact.id}
                    name={preview.contact.data.name}
                    fileId={preview.contact.data.profilePicture?.id}
                  />
                </Box>
                <Box>
                  <Text size="text_lg" weight="semibold" color="white">
                    {preview.contact.data.name}
                  </Text>
                  {preview.messageTimestamp && (
                    <Text size="text_sm" color="gray300">
                      {capitalize(
                        dayjs
                          .unix(preview.messageTimestamp)
                          .format("dddd D [a las] h:mm a")
                      )}
                    </Text>
                  )}
                </Box>
              </Box>
            )}

            {preview?.workspaceMember && (
              <Box flex alignItems="center">
                <Box marginRight="0.5rem">
                  <ProfilePic
                    id={preview.workspaceMember.id}
                    name={preview.workspaceMember.data.name}
                  />
                </Box>
                <Box>
                  <Text size="text_lg" weight="semibold" color="white">
                    {preview.workspaceMember.data.name}
                  </Text>
                  {preview.messageTimestamp && (
                    <Text size="text_sm" color="gray300">
                      {capitalize(
                        dayjs
                          .unix(preview.messageTimestamp)
                          .format("dddd D [a las] h:mm a")
                      )}
                    </Text>
                  )}
                </Box>
              </Box>
            )}
          </Box>
          <Box flex>
            <ActionIconWrapper
              flex
              alignItems="center"
              style={{ marginRight: 20, height: 20 }}
              onClick={download}
            >
              <Icon icon="download" stroke="gray300" size={18} />
            </ActionIconWrapper>

            <ActionIconWrapper
              flex
              alignItems="center"
              onClick={() => setPreview(undefined)}
              style={{ height: 20 }}
            >
              <Icon icon="x" stroke="gray300" size={20} />
            </ActionIconWrapper>
          </Box>
        </Box>

        {preview && preview.type === "image" && (
          <Box
            padding="2rem"
            flex
            justifyContent="center"
            alignItems="center"
            cols={1}
            style={{ minHeight: 0 }}
          >
            <Image
              fileId={preview.fileId}
              style={{
                objectFit: "contain",
                maxHeight: "100%",
                maxWidth: "100%",
              }}
            />
          </Box>
        )}

        {preview && preview.type === "video" && (
          <Box
            padding="2rem"
            flex
            justifyContent="center"
            alignItems="center"
            cols={1}
            style={{ minHeight: 0 }}
          >
            <Video
              contentType={preview.videoMimetype}
              fileId={preview.fileId}
              width={3000}
              height={3000}
              autoPlay
              style={{
                objectFit: "contain",
                maxHeight: "80%",
                maxWidth: "80%",
              }}
            />
          </Box>
        )}
      </PreviewWrapper>
    </ImagePreviewContext.Provider>
  );
};

export const useImagePreview = () => {
  return React.useContext(ImagePreviewContext);
};
