import { AxiosInstance } from "axios";

export default class OnboardingClient {
  constructor(private readonly axios: AxiosInstance) {}

  async signup(payload: { email: string; password: string; name: string }) {
    try {
      const response = await this.axios.post<{
        token: string;
        ok: boolean;
        error_code: string | null;
      }>("/onboarding/signup", payload);

      if (response.status !== 201) {
        throw new Error("Failed to signup");
      }

      return {
        ok: true,
        error_code: null,
        token: response.data.token,
      };
    } catch (err: any) {
      if (err.response?.status === 400) {
        return {
          ok: false,
          error_code: err.response.data.error_code,
          token: null,
        };
      }
    }

    return {
      ok: false,
      error_code: "unknown",
      token: null,
    };
  }
}
