import React, { FormEvent } from "react";
import { useHistory, useParams } from "react-router-dom";
import Box from "ui/Box";
import Button from "ui/Button";
import Field from "ui/Field";
import Input from "ui/Input";
import {
  FBInstagramAccount,
  InstagramThirdPartyMessagesErrorModal,
} from "./InstagramSettings";
import axios from "axios";

function InstagramDebug() {
  const params = useParams<{ fbAccessToken: string }>();
  const [loading, setLoading] = React.useState(false);
  const [fbPageId, setFbPageId] = React.useState("");
  const [igUsername, setIGUsername] = React.useState("");
  const [
    instagramMessagesBlockedModalOpen,
    setInstagramMessagesBlockedModalOpen,
  ] = React.useState(false);
  const history = useHistory();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const fbPageAccessTokenRes = await axios.get<{
      access_token: string;
      id: string;
    }>(`https://graph.facebook.com/v16.0/${fbPageId}`, {
      params: {
        access_token: params.fbAccessToken,
        fields: "access_token",
      },
    });

    await axios.get<{
      data: FBInstagramAccount[];
    }>(`https://graph.facebook.com/v16.0/${fbPageId}/instagram_accounts`, {
      params: {
        access_token: fbPageAccessTokenRes.data.access_token,
        fields: "id,username,profile_pic",
      },
    });

    // const result = await functions.connectInstagramAccount({
    //   fbUserAccessToken: params.fbAccessToken,
    //   fbInstagramAccountId: res.data.data.find(
    //     (kk) => kk.username === igUsername
    //   )!.id,
    //   fbPageId: fbPageId,
    //   workspaceId: auth.workspaceId!,
    // });

    const result = null as any;

    if (result.ok) {
      history.push("/settings/channels");
    } else if (result.error === "third_party_messages_blocked") {
      setInstagramMessagesBlockedModalOpen(true);
    } else {
      setLoading(true);
    }
  };

  return (
    <Box padding="2rem">
      <InstagramThirdPartyMessagesErrorModal
        open={instagramMessagesBlockedModalOpen}
        onOpenChange={setInstagramMessagesBlockedModalOpen}
      />
      <form onSubmit={handleSubmit}>
        <Field label="Facebook Page ID">
          <Input
            required
            type="text"
            value={fbPageId}
            onChange={(e) => setFbPageId(e.target.value)}
          />
        </Field>

        <Field label="Instagram username">
          <Input
            required
            type="text"
            value={igUsername}
            onChange={(e) => setIGUsername(e.target.value)}
          />
        </Field>

        <Button size="md" disabled={loading}>
          {loading ? "Sending..." : "Send"}
        </Button>
      </form>
    </Box>
  );
}
export default InstagramDebug;
