import React from "react";
import { useOnboarding } from "common/src/lib/onboarding";
import { use100vh } from "react-div-100vh";
import { Redirect, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import Box from "ui/Box";
import Chat from "components/chat";
import Contacts from "components/contacts";
import CommandBar from "./commandBar";
import AppData, { useAppData } from "common/src/lib/appData";
import Settings from "./settings";
import Reporting from "./reporting";
import ImpersonateUser from "./ImpersonateUser";
import { ImagePreviewProvider } from "lib/imagePreview";
import { OnboardingConnectFirstChannel } from "./onboarding/channels";
import OnboardingConnectWhatsapp from "./onboarding/whatsapp";
import OnboardingConnectInstagram from "./onboarding/instagram";
import OnboardingConnectMessenger from "./onboarding/messenger";
import OnboardingInviteTeamMembers from "./onboarding/inviteTeamMembers";
import ActivateTrial from "./onboarding/billing";
import Text from "ui/Text";
import Button from "ui/Button";
import dayjs from "dayjs";
import Modal from "ui/Modal";
import NavigationBar from "./navigationBar";
import StripeCheckoutSuccess from "./StripeCheckoutSuccess";
import DownloadMobileApp from "./onboarding/downloadMobileApp";
import BusinessProfile from "./onboarding/businessProfile";
import { isOnMobile } from "lib/responsive";
import { MessagesLoader } from "common/src/dexie/messages";
import { NotesLoader } from "common/src/dexie/notes";
import { ConversationsLoader } from "common/src/dexie/conversations";
import { ContactsLoader } from "common/src/dexie/contacts";
import { BaseUrlContext } from "common";
import { useConnectWhatsappWebAccountWithPhone } from "common/src/lib/whatsapp";

const AuthenticatedAppWrapper = styled.div`
  width: 100vw;
  height: 94vh;
  display: flex;
  flex-direction: column;
`;

const BillingAlertWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.primary900};
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function BillingAlert() {
  const [redirectingToCheckout, setRedirectingToCheckout] =
    React.useState(false);

  const goToCheckout = async () => {
    setRedirectingToCheckout(true);
    setRedirectingToCheckout(false);
  };

  const hasBillingProfile = true;
  const workspaceCreatedAt = 0;

  const trialDays = React.useMemo(() => {
    if (workspaceCreatedAt === undefined) {
      return 0;
    }

    let totalTrialDays = 60;

    return dayjs
      .unix(workspaceCreatedAt ?? 0)
      .add(totalTrialDays, "d")
      .diff(dayjs(), "d");
  }, [workspaceCreatedAt]);

  if (!hasBillingProfile && trialDays < 1) {
    return (
      <Modal open noClose>
        <Modal.Title>Período de prueba terminó</Modal.Title>
        <Modal.Description>
          Para continuar utilizando Zami activa tu cuenta haciendo click en el
          botón de abajo.
        </Modal.Description>

        <Modal.Actions>
          <Button
            size="md"
            block
            onClick={goToCheckout}
            isLoading={redirectingToCheckout}
            disabled={redirectingToCheckout}
          >
            Activar suscripción
          </Button>
        </Modal.Actions>
      </Modal>
    );
  } else if (("asdf" as string) === "canceled") {
    return (
      <Modal open noClose>
        <Modal.Title>Activa tu cuenta de Zami</Modal.Title>
        <Modal.Description>
          Para continuar utilizando Zami activa tu cuenta haciendo click en el
          botón de abajo.
        </Modal.Description>

        <Modal.Actions>
          <Button
            size="md"
            block
            onClick={goToCheckout}
            isLoading={redirectingToCheckout}
            disabled={redirectingToCheckout}
          >
            Activar suscripción
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  if (!hasBillingProfile) {
    return (
      <BillingAlertWrapper>
        <Text size="text_sm" weight="medium" color="white">
          Tu período de prueba gratis termina en {trialDays} día
          {trialDays > 1 && "s"}
        </Text>

        <Button
          isLoading={redirectingToCheckout}
          disabled={redirectingToCheckout}
          style={{ marginLeft: "1.5rem" }}
          size="md"
          kind="secondary"
          onClick={goToCheckout}
        >
          Agregar método de pago
        </Button>
      </BillingAlertWrapper>
    );
  }

  return null;
}

function Routes() {
  const height = use100vh();
  const { isCustomerOnboarding, setCustomerOnboarding } = useOnboarding();
  const appData = useAppData();

  return (
    <>
      {false ? (
        <Switch>
          <Route
            path="/stripe-checkout-success"
            component={StripeCheckoutSuccess}
          />
          <Route path="/onboarding/activate-trial" component={ActivateTrial} />
          <Switch>
            <Route
              path="/onboarding/connect-first-channel"
              component={OnboardingConnectFirstChannel}
            />
            <Route
              path="/onboarding/connect-whatsapp"
              component={OnboardingConnectWhatsapp}
            />
            <Route
              path="/onboarding/connect-instagram"
              render={() => (
                <OnboardingConnectInstagram
                  onFinish={() => setCustomerOnboarding(false)}
                />
              )}
            />
            <Route
              path="/onboarding/connect-messenger"
              render={() => (
                <OnboardingConnectMessenger
                  onFinish={() => setCustomerOnboarding(false)}
                />
              )}
            />

            <Route
              path="/onboarding/invite-team-members"
              component={OnboardingInviteTeamMembers}
            />

            <Route
              path="/onboarding/download-mobile-app"
              component={DownloadMobileApp}
            />
            <Redirect to="/onboarding/connect-first-channel" />
          </Switch>
          {appData.workspace?.businessProfile === null && (
            <Switch>
              <Route
                path="/onboarding/business-profile"
                component={BusinessProfile}
              />
              <Redirect to="/onboarding/business-profile" />
            </Switch>
          )}
          {/* <Redirect to="/onboarding/activate-trial" /> */}
        </Switch>
      ) : (
        <>
          <AuthenticatedAppWrapper style={{ height: height ?? undefined }}>
            <BillingAlert />
            <Box
              flex
              direction="row"
              cols={1}
              style={{ minHeight: 0, overflow: "hidden" }}
            >
              {!isOnMobile() && <NavigationBar />}
              <Switch>
                <Route
                  path="/impersonate-user/:authToken"
                  component={ImpersonateUser}
                />
                <Route
                  path="/chat/:inbox/:selectedConversationId"
                  component={Chat}
                />
                <Route path="/chat/:inbox" component={Chat} />
                <Route path="/contacts" component={Contacts} />
                <Route path="/settings" component={Settings} />
                <Route path="/reports" component={Reporting} />
                <Route
                  path="/stripe-checkout-success"
                  component={StripeCheckoutSuccess}
                />
                <Redirect to="/chat/inbox" />
              </Switch>
            </Box>
          </AuthenticatedAppWrapper>
        </>
      )}
    </>
  );
}

const AuthenticatedApp = () => {
  return (
    <BaseUrlContext.Provider value={process.env.REACT_APP_CORE_BASE_URL!}>
      <AppData>
        <ConversationsLoader>
          <MessagesLoader>
            <NotesLoader>
              <ContactsLoader>
                <ImagePreviewProvider>
                  <CommandBar>
                    <Routes />
                  </CommandBar>
                </ImagePreviewProvider>
              </ContactsLoader>
            </NotesLoader>
          </MessagesLoader>
        </ConversationsLoader>
      </AppData>
    </BaseUrlContext.Provider>
  );
};

export default AuthenticatedApp;
