import React from "react";
import { useAppData } from "common/src/lib/appData";

export function useOnboarding() {
  const appData = useAppData();
  const [isCustomerOnboarding, setCustomerOnboarding] = React.useState(false);

  const shouldCustomerBeOnboarding = React.useMemo(() => {
    if (appData.workspace?.onboarding?.finishedOnboarding === false) {
      return true;
    }

    return false;
  }, [appData.workspace]);

  React.useEffect(() => {
    if (shouldCustomerBeOnboarding) {
      setCustomerOnboarding(true);
    } else {
      setCustomerOnboarding(false);
    }
  }, [shouldCustomerBeOnboarding]);

  return { isCustomerOnboarding, setCustomerOnboarding };
}
