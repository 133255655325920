import { AxiosInstance } from "axios";

export default class AuthClient {
  axios: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axios = axios;
  }

  async passwordAuth(params: { email: string; password: string }) {
    try {
      const response = await this.axios.post<{ auth_token: string }>(
        "/auth/password",
        params
      );

      if (response.status !== 200) {
        return undefined;
      }

      return response.data.auth_token;
    } catch (err) {
      return undefined;
    }
  }

  async deleteAccount() {
    const response = await this.axios.post("/delete-account");

    if (response.status !== 200) {
      throw new Error(" failed to delete account");
    }

    return;
  }
}
