import React from "react";
import dayjs from "dayjs";
import Dexie, { IndexableType } from "dexie";
import { ZamiDexie } from "./idb";
import { useLiveQuery } from "dexie-react-hooks";
import { Conversation, MessageChannel } from "../types";
import { useAppData } from "../lib/appData";
import { useIDB } from "./idb";
import { useApiClient } from "../context";
import { ObjectId } from "bson";

export interface IDBConversation {
  id: string;
  lastUpdateAt: Date;
  assignee: string;
  status: string;
  contactId: string;
  channelId: string;
  lastMessageAt: Date;
  lastMessageDirection: string;
  data: Conversation;
}

export async function putConversations(
  idb: ZamiDexie,
  conversations: Conversation[]
) {
  const idbConversations: IDBConversation[] = [];
  for (const c of conversations) {
    if (c.deleted) {
      idb.conversations.delete(c.id).catch((err) => false);
      continue;
    }

    let status = "open";

    if (c.closed) {
      status = "closed";
    } else if (c.snoozedUntil) {
      status = "snoozed";
    }

    let lastMessageAt: Date | undefined = undefined;

    if (c.lastMessage) {
      lastMessageAt = dayjs(c.lastMessage.time).toDate();
    } else {
      const convId = new ObjectId(c.id);
      lastMessageAt = convId.getTimestamp();
    }

    const idbConversation: IDBConversation = {
      id: c.id,
      lastUpdateAt: dayjs(c.lastUpdateAt).toDate(),
      assignee: c.assignee,
      channelId: c.channelId,
      contactId: c.contact.id,
      status: status,
      lastMessageAt,
      lastMessageDirection: c.lastMessage?.message?.direction!,
      data: c,
    };

    idbConversations.push(idbConversation);
  }

  await idb.conversations.bulkPut(idbConversations);
}

export function useHistoricalConversationsLoader() {
  const idb = useIDB();
  const apiClient = useApiClient();

  const syncHistoricalConversations = async () => {
    const oldestConversation = await idb.conversations
      .orderBy("lastUpdateAt")
      .first();

    const result = await apiClient.conversations.fetchConversations({
      before: oldestConversation?.lastUpdateAt?.toISOString(),
      limit: 1000,
    });

    putConversations(idb, result);

    if (result.length === 1000) {
      setTimeout(syncHistoricalConversations, 200);
    }
  };

  React.useEffect(() => {
    syncHistoricalConversations();
  }, [apiClient, idb]);
}

export function useSubscribeToNewConversations() {
  const idb = useIDB();
  const apiClient = useApiClient();

  const lastConversation = useLiveQuery(() =>
    idb.conversations.orderBy("lastUpdateAt").last()
  );

  React.useEffect(() => {
    const unsubscribe = apiClient.subscribeMessageHandler((wsMessage) => {
      if (wsMessage.type === "new_conversations") {
        putConversations(idb, wsMessage.data);
      }
    });

    if (!lastConversation) {
      return unsubscribe;
    }

    try {
      apiClient.websocketClient?.sendConversationSyncPing(
        lastConversation.lastUpdateAt
      );
    } catch (err) {
      throw err;
    }

    return () => {
      unsubscribe();
    };
  }, [apiClient, idb, lastConversation]);
}

export function useConversationList(
  inboxName: string,
  opts: {
    order: string;
    status: string;
  }
) {
  const appData = useAppData();
  const idb = useIDB();

  const conversations = useLiveQuery(async () => {
    let conversationCollection:
      | undefined
      | Dexie.Collection<IDBConversation, IndexableType> = undefined;

    if (inboxName === "inbox") {
      const possibleAssignees = [`wm:${appData.workspaceMemberSelf?.id}`];

      if (appData.permissions.canSeeUnassignedConversations) {
        possibleAssignees.push("");
      }

      conversationCollection = idb.conversations
        .where("[assignee+status]")
        .anyOf(possibleAssignees.map((assignee) => [assignee, opts.status]));
    } else if (inboxName === "unassigned") {
      conversationCollection = idb.conversations
        .where("[assignee+status]")
        .equals(["", opts.status]);
    } else if (inboxName.startsWith("assigned-")) {
      const assigneeId = inboxName.split("assigned-")[1];
      conversationCollection = idb.conversations
        .where("[assignee+status]")
        .equals([`wm:${assigneeId}`, opts.status]);
    } else if (inboxName.startsWith("team-")) {
      const assigneeId = inboxName.split("team-")[1];
      conversationCollection = idb.conversations
        .where("[assignee+status]")
        .equals([`t:${assigneeId}`, opts.status]);
    } else if (inboxName.startsWith("sequence-")) {
      const sequenceId = inboxName.split("sequence-")[1];
      conversationCollection = idb.conversations
        .where("[assignee+status]")
        .equals([`b:${sequenceId}`, opts.status]);
    } // else if (inboxName === "pendingResponse") {
    //   conversationCollection = getPendingResponseIDBCollection(idb, appData);
    // }

    if (conversationCollection) {
      if (opts.order === "newest_first") {
        return await conversationCollection.reverse().sortBy("lastMessageAt");
      } else {
        return await conversationCollection.sortBy("lastMessageAt");
      }
    }

    return [];
  }, [
    appData.permissions,
    appData.workspaceMemberSelf,
    inboxName,
    opts.order,
    opts.status,
  ]);

  console.log(
    conversations?.map((c) => ({
      name: c.data.contact.name,
      lastMessageAt: c.lastMessageAt,
      lma: c.data.lastMessageAt,
    }))
  );

  return {
    conversations: conversations ?? [],
  };
}

export function useConversationsLoader() {
  useHistoricalConversationsLoader();
  useSubscribeToNewConversations();
}

export function useIDBConversationActions() {
  const apiClient = useApiClient();
  const idb = useIDB();

  const closeConversation = React.useCallback(
    async (conversation: IDBConversation) => {
      try {
        putConversations(idb, [
          {
            ...conversation.data,
            closed: true,
          },
        ]);
        await apiClient.conversations.closeConversation(conversation.id);
      } catch (err) {
        putConversations(idb, [
          {
            ...conversation.data,
            closed: false,
          },
        ]);
      }
    },
    [apiClient]
  );

  const openConversation = React.useCallback(
    async (conversation: IDBConversation) => {
      try {
        putConversations(idb, [
          {
            ...conversation.data,
            closed: false,
          },
        ]);
        await apiClient.conversations.openConversation(conversation.id);
      } catch (err) {
        putConversations(idb, [
          {
            ...conversation.data,
            closed: true,
          },
        ]);
      }
    },
    [apiClient]
  );

  const assignConversation = React.useCallback(
    async (conversation: IDBConversation, assignee: string) => {
      const previousAssignee = conversation.data.assignee;
      try {
        putConversations(idb, [
          {
            ...conversation.data,
            assignee: assignee,
          },
        ]);
        await apiClient.conversations.assignConversation(
          conversation.id,
          assignee
        );
      } catch (err) {
        putConversations(idb, [
          {
            ...conversation.data,
            assignee: previousAssignee,
          },
        ]);
      }
    },
    [apiClient]
  );

  const snoozeConversation = React.useCallback(
    async (conversation: IDBConversation, snoozeUntil: Date) => {
      const previousSnoozeUntil = conversation.data.snoozedUntil;
      try {
        putConversations(idb, [
          {
            ...conversation.data,
            snoozedUntil: snoozeUntil.toString(),
          },
        ]);
        await apiClient.conversations.snoozeConversation(
          conversation.id,
          snoozeUntil
        );
      } catch (err) {
        putConversations(idb, [
          {
            ...conversation.data,
            snoozedUntil: previousSnoozeUntil,
          },
        ]);
      }
    },
    [apiClient]
  );

  const resumeConversation = React.useCallback(
    async (conversation: IDBConversation) => {
      const previousSnoozeUntil = conversation.data.snoozedUntil;
      try {
        putConversations(idb, [
          {
            ...conversation.data,
            snoozedUntil: undefined,
          },
        ]);
        await apiClient.conversations.resumeConversation(conversation.id);
      } catch (err) {
        putConversations(idb, [
          {
            ...conversation.data,
            snoozedUntil: previousSnoozeUntil,
          },
        ]);
      }
    },
    [apiClient]
  );

  const getOrCreateConversation = React.useCallback(
    async (contactId: string, channelId: string) => {
      const conversation = await idb.conversations
        .where("[contactId+channelId]")
        .equals([contactId, channelId])
        .first();

      if (conversation) {
        return conversation;
      }

      await apiClient.conversations.createConversation(contactId, channelId);

      return null;
    },
    [idb]
  );

  return {
    closeConversation,
    openConversation,
    assignConversation,
    snoozeConversation,
    resumeConversation,
    getOrCreateConversation,
  };
}

export function ConversationsLoader(props: { children: React.ReactNode }) {
  useConversationsLoader();

  return React.createElement("div", {}, props.children);
}
