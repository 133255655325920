import React from "react";
import Box from "ui/Box";
import styled from "styled-components";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { ReactComponent as ZamiLogo } from "assets/logo.svg";
import Text from "ui/Text";
import { OnboardingStyles } from "./common";
import Button from "ui/Button";
import { FormEvent } from "react";
import Link from "ui/link";

const TrialDetailsBox = styled(Box)`
  border-radius: 12px;
  background-color: white;
  border: solid 1px ${(props) => props.theme.colors.gray200};
  padding: 1.5rem 1rem;
`;

const SummaryBox = styled(Box)`
  border-top: solid 1px ${(props) => props.theme.colors.gray200};
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
`;

export default function ActivateTrial() {
  const stripe = useStripe();
  const elements = useElements();
  // eslint-disable-next-line
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);

  const goToCheckout = React.useCallback(async () => {
    setLoading(true);
    // const response = await restClient.post<{ url: string }>(
    //   "/accounts/stripe-checkout-session",
    //   {
    //     trialDays: 7,
    //   }
    // );
    // window.location.href = response.data.url;
  }, []);

  const handleSubmit = async (e: FormEvent) => {
    setErrorMessage(undefined);
    setLoading(true);
    e.preventDefault();

    if (stripe && elements) {
      const result = await stripe.createPaymentMethod({
        elements,
      });

      if (result.error) {
        setLoading(false);
        return setErrorMessage(result.error.message);
      }

      try {
        const response = null as any;

        if (
          response.data.ok === false &&
          response.data.error_code === "card_error"
        ) {
          setLoading(false);
          return setErrorMessage(
            "La tarjeta fue rechazada, porfavor intenta con otra"
          );
        }

        if (response.data.billingProfile) {
          (window as any).fbq?.("track", "StartTrial");
          // auth.setWorkspace!((workspace: any) => ({
          //   ...workspace!,
          //   billingProfile: response.data.billingProfile,
          // }));
        }
      } catch (err) {
        setLoading(false);
        setErrorMessage(
          "Hubo un error al procesar el pago, porfavor intenta de nuevo"
        );
      }
    } else {
      throw new Error("No stripe or elements instance ready, why?");
    }
  };

  // if (auth.workspace?.billingProfile) {
  //   return <Redirect to="/onboarding/connect-first-channel" />;
  // }

  return (
    <Box
      style={{
        maxWidth: "26rem",
        margin: "0 auto",
        padding: "0rem 1rem",
        paddingBottom: "4rem",
      }}
    >
      <OnboardingStyles />
      <Box
        flex
        alignItems="center"
        direction="column"
        marginTop="4rem"
        paddingLeft="1rem"
        marginBottom="2rem"
        paddingRight="1rem"
        style={{
          textAlign: "center",
        }}
      >
        <ZamiLogo height={48} width={48} />
        <Text
          size="display_sm"
          weight="semibold"
          marginTop="1.5rem"
          marginBottom="1rem"
        >
          Prueba gratis, 7 días
        </Text>

        <Text
          size="text_lg"
          color="gray600"
          style={{ maxWidth: 500, textAlign: "center" }}
        >
          La prueba de Zami es gratis, podrás cancelar en cualquier momento
          desde nuestra página o escribiendonos
        </Text>
      </Box>

      <TrialDetailsBox>
        <Text size="text_xs" weight="bold" color="primary600">
          PLAN BASE
        </Text>

        <Text
          size="text_lg"
          weight="semibold"
          color="gray900"
          marginBottom="1rem"
        >
          Prueba gratis 7 días
        </Text>

        <Box marginBottom="1rem">
          <Text size="text_xs" weight="bold">
            VALOR
          </Text>
          <Text size="text_md" weight="regular" color="gray600">
            US$49/mes
          </Text>
        </Box>

        <Box marginBottom="1rem">
          <Text size="text_xs" weight="bold">
            PRUEBA
          </Text>
          <Text size="text_md" weight="regular" color="gray600">
            7 días gratis
          </Text>
        </Box>

        <SummaryBox>
          <Text size="text_md" weight="semibold" color="gray900">
            Pagarás hoy:
          </Text>

          <Text size="text_md" weight="semibold" color="gray900">
            $0.00
          </Text>
        </SummaryBox>
      </TrialDetailsBox>

      <form onSubmit={handleSubmit}>
        <Box marginTop="1.5rem" marginBottom="1.5rem">
          <Button
            block
            size="lg"
            type="submit"
            disabled={loading}
            isLoading={loading}
            onClick={goToCheckout}
          >
            Iniciar prueba gratis
          </Button>
        </Box>

        <Box>
          <Text size="text_md" weight="regular" color="gray600">
            <Link href="https://wa.me/message/G2P3BHVWB23WB1">
              Haz click aquí para escribirnos por WhatsApp
            </Link>{" "}
            si tienes alguna duda o necesitas ayuda.
          </Text>
        </Box>
      </form>
    </Box>
  );
}
