import { AxiosInstance } from "axios";
import { Contact } from "../types";

export default class ContactsClient {
  constructor(private readonly axios: AxiosInstance) {}

  async createContact(contactPayload: {
    name: string;
    email: string;
    phone: string;
  }) {
    const response = await this.axios.post("/contacts", contactPayload);

    if (response.status !== 201) {
      throw new Error("Failed to create contact");
    }

    return {
      ok: true,
      contact: response.data,
    };
  }

  async fetchContacts(params: { before?: string; limit: number }) {
    const response = await this.axios.get("/contacts", {
      params: {
        before: params.before,
        limit: params?.limit,
      },
    });

    if (response.status !== 200) {
      throw new Error("Failed to fetch contacts");
    }

    return response.data;
  }

  async deleteContact(contactId: string) {
    const response = await this.axios.delete(`/contacts/${contactId}`);

    if (response.status !== 200) {
      throw new Error("Failed to delete contact");
    }

    return true;
  }

  async updateContact(
    contactId: string,
    contactPayload: {
      name?: string;
      email?: string;
    }
  ) {
    const response = await this.axios.put<Contact>(
      `/contacts/${contactId}`,
      contactPayload
    );

    if (response.status !== 200) {
      throw new Error("Failed to update contact");
    }

    return response.data;
  }

  async addLabelToContact(contactId: string, labelId: string) {
    const response = await this.axios.post(
      `/contacts/${contactId}/add-label/${labelId}`
    );

    if (response.status !== 200) {
      throw new Error("Failed to add label to contact");
    }

    return true;
  }

  async removeLabelFromContact(contactId: string, labelId: string) {
    const response = await this.axios.post(
      `/contacts/${contactId}/remove-label/${labelId}`
    );

    if (response.status !== 200) {
      throw new Error("Failed to remove label from contact");
    }

    return true;
  }
}
