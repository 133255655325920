import { InstagramAccount, FacebookPage, WhatsappWebAccount } from "../types";
import { AxiosInstance } from "axios";

export type Channel =
  | {
      type: "instagram";
      data: InstagramAccount;
    }
  | {
      type: "messenger";
      data: Exclude<FacebookPage, "fbPageAccessToken">;
    }
  | {
      type: "whatsapp_web";
      data: WhatsappWebAccount;
    };

export default class ChannelsClient {
  constructor(private readonly axios: AxiosInstance) {}

  async listChannels() {
    const response = await this.axios.get<{ channels: Channel[] }>("/channels");

    if (response.status !== 200) {
      throw new Error("Failed to fetch channels");
    }

    return response.data.channels;
  }
}
