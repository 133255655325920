import React from "react";
import { useHistory } from "react-router-dom";
import { IDBConversation } from "common/src/dexie/conversations";
import { useIDBConversationActions } from "common/src/dexie/conversations";

const NewConversation = ({
  contactId,
  channelId,
  conversations,
}: {
  contactId: string;
  channelId: string;
  conversations: IDBConversation[];
}) => {
  const history = useHistory();

  const convActions = useIDBConversationActions();

  React.useEffect(() => {
    (async () => {
      const convo = await convActions.getOrCreateConversation(
        contactId,
        channelId
      );

      if (convo) {
        history.push(`/chat/inbox/${convo.id}?wait=true`);
      }
    })();
  }, [convActions, history, contactId, channelId]);

  return null;
};

export default NewConversation;
