import React from "react";
import { WorkspaceMemberPresence, MessageChannel } from "common/src/types";
import styled, { css } from "styled-components";
import Icon, { iconMap } from "ui/Icon";
import Text, { TextSize } from "ui/Text";
import theme from "ui/theme";
import Image from "./Image";

interface ProfilePicProps {
  id: string;
  className?: string;
  size?: "md" | "xxs" | "xs" | "lgs" | "lg";
  name: string;
  fileId?: string | undefined;
  showChannelBadgeFor?: MessageChannel;
  showStatusIcon?: boolean;
  status?: WorkspaceMemberPresence;
}

const COLORS = [
  theme.colors.grayBlue400,
  theme.colors.pink700,
  theme.colors.orange600,
  theme.colors.yellow500,
  theme.colors.cyan500,
  theme.colors.green500,
];

function getColorIndex(uid: string) {
  let hash = 0;
  Array(uid.length)
    .fill(1)
    .forEach((_, i) => {
      hash += uid.charCodeAt(i);
    });

  return hash % 6;
}

function getInitials(name: string | undefined) {
  let initials = "";
  const [firstName, lastName] = (name ?? "").split(" ");
  if (firstName !== undefined && firstName[0] !== undefined)
    initials = `${firstName[0]}`;
  if (lastName !== undefined && lastName[0] !== undefined)
    initials = `${initials}${lastName[0]}`;
  return (initials ?? "").toUpperCase();
}

const Wrapper = styled.div``;

const PicWrapper = styled.div<{ id: string; size: ProfilePicProps["size"] }>`
  background-color: ${(props) => props.theme.colors.primary200};
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.size === "lg" &&
    css`
      width: 104px;
      height: 104px;
    `}

  ${(props) =>
    props.size === "lgs" &&
    css`
      width: 72px;
      height: 72px;
    `}

  ${(props) =>
    props.size === "md" &&
    css`
      width: 40px;
      height: 40px;
    `}

  ${(props) =>
    props.size === "xs" &&
    css`
      width: 24px;
      height: 24px;
    `}

  ${(props) =>
    props.size === "xxs" &&
    css`
      width: 20px;
      height: 20px;
    `}

  ${(props) =>
    props.id &&
    css`
      background-color: ${COLORS[getColorIndex(props.id)]};
    `}

  img {
    border-radius: 50%;
  }
`;

const ProfilePicture = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ChannelBadge = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  overflow: hidden;
  box-sizing: content-box;
  transform: translate(15%, 15%);
  position: absolute;
  bottom: 0px;
  right: 0px;
`;

const StatusIconWrapper = styled.div<{ size: ProfilePicProps["size"] }>`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(20%, 20%);
  border: 2px solid #fff;
  border-radius: 50%;

  ${(props) =>
    props.size === "xxs"
      ? "width: 11px; height: 11px;"
      : "width: 15px; height: 15px;"}
`;

const StatusIcon = styled.div<{ isActive?: boolean }>`
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: ${(props) =>
    props.isActive ? theme.colors.success500 : "#fff"};
  ${(props) =>
    props.isActive ? "" : `border: 1.5px ${theme.colors.gray300} solid;`}
`;

const channelIconMap: Record<MessageChannel, keyof typeof iconMap> = {
  whatsapp_web: "whatsappBadge",
  instagram: "instagramBadge",
  page: "messengerBadge",
  email: "emailBadge",
  zami: "zamiBadge",
};

const fontSizeMap: Record<NonNullable<ProfilePicProps["size"]>, TextSize> = {
  xxs: "text_xxs",
  xs: "text_xs",
  md: "text_md",
  lg: "display_lg",
  lgs: "text_lg",
};

const ProfilePic = ({
  id,
  fileId,
  name,
  size,
  className,
  showChannelBadgeFor,
  showStatusIcon,
  status,
}: ProfilePicProps) => {
  const gid = React.useMemo(() => {
    if (Array.isArray(id)) {
      return id.join("");
    }

    return id;
  }, [id]);

  return (
    <Wrapper>
      <PicWrapper className={className} id={gid} size={size}>
        {fileId ? (
          <ProfilePicture fileId={fileId} />
        ) : (
          <Text size={fontSizeMap[size!]} weight="medium" color="white">
            {getInitials(name)}
          </Text>
        )}
        {showStatusIcon && (
          <StatusIconWrapper size={size}>
            <StatusIcon isActive={status === "online"} />
          </StatusIconWrapper>
        )}
        {showChannelBadgeFor && (
          <ChannelBadge>
            <Icon icon={channelIconMap[showChannelBadgeFor]} size={18} />
          </ChannelBadge>
        )}
      </PicWrapper>
    </Wrapper>
  );
};

ProfilePic.defaultProps = {
  size: "md",
};

export default ProfilePic;
