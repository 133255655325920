type UrlOrString = {
  type: "string" | "url";
  value: string;
};

export function extractUrlsAndStrings(input: string) {
  const urlPattern = /((https?:\/\/)?[^\s]+\.[^\s]+)/g;
  const urls = input.match(urlPattern) || [];
  let result: UrlOrString[] = [];
  let lastIndex = 0;

  urls.forEach((url) => {
    const index = input.indexOf(url, lastIndex);
    if (index > lastIndex) {
      result.push({
        type: "string",
        value: input.substring(lastIndex, index),
      });
    }
    result.push({ type: "url", value: url });
    lastIndex = index + url.length;
  });

  if (lastIndex < input.length) {
    result.push({
      type: "string",
      value: input.substring(lastIndex),
    });
  }

  return result;
}
