import { AxiosInstance } from "axios";
import { DataUpdateSubscriber } from "../client";
import { Workspace } from "../types";

export default class WorkspaceClient {
  constructor(
    private axios: AxiosInstance,
    private dataUpdatesSubscriber: () => DataUpdateSubscriber | undefined
  ) {}

  async finishOnboarding() {
    const result = await this.axios.post<Workspace>("/onboarding/finish");

    if (result.status === 200) {
      this.dataUpdatesSubscriber()?.onWorkspace(result.data);
      return true;
    }

    throw new Error("Failed to finish onboarding");
  }
}
