import { AxiosInstance } from "axios";
import { WorkspaceInvite, WorkspaceMember } from "../types";
import { DataUpdateSubscriber } from "../client";

export default class MembersClient {
  constructor(
    private axios: AxiosInstance,
    private dataUpdatesSubscriber: () => DataUpdateSubscriber | undefined
  ) {}

  async inviteTeamMembers(
    invitations: {
      email: string;
      role_id?: string | undefined;
      superuser: boolean;
      teams?: string[];
    }[]
  ) {
    const response = await this.axios.post<{ status: "ok" }>(
      "/invite-team-members",
      invitations
    );

    if (response.status !== 200) {
      throw new Error("Failed to invite team members");
    }
  }

  async fetchInvitation(invitationKey: string) {
    const response = await this.axios.get<WorkspaceInvite>(
      `/workspace-invitations/${invitationKey}`
    );

    if (response.status !== 200) {
      throw new Error("Failed to fetch invitation");
    }

    return response.data;
  }

  async acceptInvitation(
    invitationKey: string,
    name: string,
    password: string
  ) {
    const response = await this.axios.post<{ auth_token: string; ok: true }>(
      `/workspace-invitations/${invitationKey}/accept`,
      { name, password }
    );

    if (response.status !== 200) {
      throw new Error("Failed to accept invitation");
    }

    return response.data;
  }

  async cancelInvitation(workspaceMemberId: string) {
    const response = await this.axios.delete(
      `/workspace-members/${workspaceMemberId}/invitation`
    );

    if (response.status !== 200) {
      throw new Error("Failed to cancel invitation");
    }

    this.dataUpdatesSubscriber()?.onWorkspaceMembersDeleted([
      workspaceMemberId,
    ]);
  }

  async updateMember(
    workspaceMemberId: string,
    updates: {
      teams: string[];
      isSuperuser: boolean;
      roleId?: string | undefined;
    }
  ) {
    const response = await this.axios.put<WorkspaceMember>(
      `/workspace-members/${workspaceMemberId}`,
      updates
    );

    if (response.status !== 200) {
      throw new Error("Failed to update member");
    }

    this.dataUpdatesSubscriber()?.onWorkspaceMembers([response.data]);
  }
}
