import React from "react";
import Box from "ui/Box";
import * as Avatar from "@radix-ui/react-avatar";
import { ReactComponent as Check2Icon } from "assets/icons/check2.svg";
import styled from "styled-components";
import igPlaceholderImage from "assets/igplaceholder.jpeg";
import { ReactComponent as InstagramLogo } from "assets/channelLogos/instagram.svg";
import { OnboardingStyles } from "./common";
import { useConnectInstagram } from "common/src/lib/instagram";
import Text from "ui/Text";
import Icon from "ui/Icon";
import Button from "ui/Button";
import { InstagramThirdPartyMessagesErrorModal } from "components/settings/InstagramSettings";
import ProgressWindow from "./progressWindow";
import { useHistory } from "react-router-dom";

const DocumentationLinkBox = styled(Box)`
  cursor: pointer;
`;

const WhiteBox = styled(Box)`
  background-color: ${(props) => props.theme.colors.gray50};
  border: solid 1px ${(props) => props.theme.colors.gray200};
  margin-right: 1.5rem;
  padding: 2rem 1.5rem;
  border-radius: 12px;

  &:last-child {
    margin-right: 0;
  }
`;

const IgPic = styled(Avatar.Image)`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
`;

const Pic = styled.img`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
`;

const IgFallback = styled(Avatar.Fallback)`
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
`;

const Root = styled(Avatar.Root)`
  width: 3rem;
  height: 3rem;
  margin-right: 1rem;
`;

const IGWrapper = styled.div`
  padding: 1rem 0rem;
  border-bottom: solid 1px ${(props) => props.theme.colors.gray200};
`;

export default function OnboardingConnectInstagram(props: {
  onFinish: () => void;
}) {
  const history = useHistory();
  const {
    loginLoading,
    startFbOauth,
    handleConnectIgAccount,
    instagramAccounts,
    connectedInstagramAccounts,
    instagramConnectionStatuses,
    instagramMessagesBlockedModalOpen,
    setInstagramMessagesBlockedModalOpen,
  } = useConnectInstagram();

  const hasConnectedAccount = React.useMemo(
    () =>
      Boolean(
        Object.keys(connectedInstagramAccounts).find(
          (key) => connectedInstagramAccounts[key]
        )
      ),
    [connectedInstagramAccounts]
  );

  return (
    <ProgressWindow currentStep={2}>
      <InstagramThirdPartyMessagesErrorModal
        open={instagramMessagesBlockedModalOpen}
        onOpenChange={setInstagramMessagesBlockedModalOpen}
      />
      <OnboardingStyles />
      <Box marginTop="4rem" padding="0rem 3rem">
        <Box
          flex
          alignItems="center"
          marginBottom="0.5rem"
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/onboarding/connect-first-channel")}
        >
          <Icon icon="arrowLeft" />
          <Text size="text_md" marginLeft="0.5rem">
            Atras
          </Text>
        </Box>
        <Box
          flex
          paddingBottom="1.5rem"
          borderBottom
          marginBottom="2rem"
          alignItems="center"
        >
          <Box marginRight="1rem">
            <InstagramLogo />
          </Box>
          <Box cols={1}>
            <Text
              size="display_xs"
              weight="semibold"
              style={{ marginBottom: 4 }}
            >
              Instagram
            </Text>

            <Text
              size="text_sm"
              weight="regular"
              color="gray600"
              style={{ maxWidth: 560 }}
            >
              Conecta tu cuenta profesional de instagram y podrás recibir y
              contestar los mensajes directos de Instagram desde Zami.
            </Text>
          </Box>
          {!hasConnectedAccount && (
            <Box flex alignItems="center">
              <Button
                isLoading={loginLoading}
                icon="puzzlePiece"
                size="md"
                onClick={startFbOauth}
              >
                Conectar Instagram
              </Button>
            </Box>
          )}
        </Box>

        <>
          <WhiteBox>
            <Text size="text_xl" weight="semibold" marginBottom="1.5rem">
              Para conectar una cuenta de instagram tienes que cumplir con los
              siguientes requisitos:
            </Text>

            <Box flex alignItems="center" marginBottom="20px">
              <Check2Icon />
              <Text color="gray600" marginLeft="0.75rem">
                Tener una cuenta de instagram profesional asociada a una página
                de Facebook.
              </Text>
            </Box>

            <Box flex alignItems="center">
              <Check2Icon />
              <Text color="gray600" marginLeft="0.75rem">
                Permitir mensajes de terceros desde configuraciones de
                privacidad en instagram.
              </Text>
            </Box>

            <DocumentationLinkBox marginTop="2rem" flex alignItems="center">
              <Text size="text_md" weight="semibold" marginRight="0.5rem">
                Ver pasos
              </Text>
              <Icon icon="arrowRight" />
            </DocumentationLinkBox>
          </WhiteBox>

          {instagramAccounts !== undefined && (
            <Box marginTop="2rem">
              <Text size="text_xl" weight="semibold" marginBottom="0.25rem">
                Selecciona la cuenta de Instagram que quieras conectar a Zami.
              </Text>
              <Text size="text_md" color="gray600" marginBottom="1.5rem">
                Encontramos {instagramAccounts.length} cuentas de Instagram
                vinculadas con páginas de Facebook administradas por ti.
              </Text>
              {instagramAccounts.map((igAccount) => {
                return (
                  <IGWrapper>
                    <Box flex alignItems="center">
                      <Root>
                        <IgPic src={igAccount.profile_pic} />
                        <IgFallback>
                          <Pic src={igPlaceholderImage} />
                        </IgFallback>
                      </Root>

                      <Text size="text_md" weight="semibold" cols={1}>
                        {igAccount.username}
                      </Text>
                      <Button
                        size="sm"
                        disabled={
                          instagramConnectionStatuses[igAccount.id] ===
                            "loading" ||
                          connectedInstagramAccounts[igAccount.igId]
                        }
                        isLoading={
                          instagramConnectionStatuses[igAccount.id] ===
                          "loading"
                        }
                        onClick={async () => {
                          await handleConnectIgAccount(
                            igAccount.pageId,
                            igAccount.id
                          );
                        }}
                      >
                        {connectedInstagramAccounts[igAccount.igId]
                          ? "Conectado"
                          : "Conectar"}
                      </Button>
                    </Box>
                  </IGWrapper>
                );
              })}
            </Box>
          )}
        </>
      </Box>
    </ProgressWindow>
  );
}
