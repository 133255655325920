import React from "react";
import dayjs from "dayjs";
import { useLiveQuery } from "dexie-react-hooks";
import { Message } from "../types";
import { useIDB } from "./idb";
import { useApiClient } from "common";

export interface IDBMessage {
  id: string;
  contactId: string;
  timestamp: Date;
  lastUpdateAt: Date;
  data: Message;
}

function mapMessageForIDB(m: Message) {
  const result: IDBMessage = {
    id: m.id,
    contactId: m.contactId,
    timestamp: dayjs(m.time).toDate(),
    lastUpdateAt: dayjs(m.lastUpdateAt).toDate(),
    data: m,
  };

  return result;
}

function useHistoricalMessagesLoader() {
  const idb = useIDB();
  const apiClient = useApiClient();

  const syncHistoricalMessages = async () => {
    const oldestMessage = await idb.messages.orderBy("lastUpdateAt").first();

    const result = await apiClient.messagesClient!.fetchMessages({
      before: oldestMessage?.lastUpdateAt?.toISOString(),
      limit: 1000,
    });

    idb.messages.bulkPut(result.map(mapMessageForIDB));

    if (result.length === 1000) {
      console.log("fetching more messages");
      setTimeout(syncHistoricalMessages, 200);
    }
  };

  React.useEffect(() => {
    syncHistoricalMessages();
  }, [apiClient, idb]);
}

function useSubscribeToNewMessages() {
  const idb = useIDB();
  const apiClient = useApiClient();

  const lastMessage = useLiveQuery(() =>
    idb.messages.orderBy("lastUpdateAt").last()
  );

  React.useEffect(() => {
    const unsubscribe = apiClient.subscribeMessageHandler((message) => {
      if (message.type === "new_messages") {
        idb.messages.bulkPut(message.data.map(mapMessageForIDB));
      }
    });

    if (!lastMessage) {
      return unsubscribe;
    }

    try {
      apiClient.websocketClient?.sendMessagesSyncPing(lastMessage.lastUpdateAt);
    } catch (err) {
      throw err;
    }

    return unsubscribe;
  }, [idb, lastMessage?.id]);
}

function useMessagesLoader() {
  useHistoricalMessagesLoader();
  useSubscribeToNewMessages();
}

export function MessagesLoader(props: { children: React.ReactNode }) {
  useMessagesLoader();

  return React.createElement("div", {}, props.children);
}
