import axios, { AxiosInstance } from "axios";
import { FacebookPage } from "../types";
import { DataUpdateSubscriber } from "../client";

export interface FBPage {
  access_token: string;
  id: string;
  name: string;
  picture: {
    data: {
      url: string;
    };
  };
}

export default class MessengerApiClient {
  constructor(
    private zamiAuthAxios: AxiosInstance,
    private dataUpdatesSubscriber: () => DataUpdateSubscriber | undefined
  ) {}

  async getFacebookPagesFromAccessToken(accessToken: string) {
    const resp = await this.zamiAuthAxios.get<FBPage[]>("/facebook-pages", {
      params: {
        access_token: accessToken,
        fields: "access_token,name,id,picture",
      },
    });
    return resp.data;
  }

  async connectMessengerAccount(params: {
    fbUserAccessToken: string;
    fbPageId: string;
  }) {
    try {
      const resp = await this.zamiAuthAxios.post<FacebookPage>(
        "/messenger/connect",
        {
          fb_user_access_token: params.fbUserAccessToken,
          fb_page_id: params.fbPageId,
        }
      );

      if (resp.status === 201) {
        this.dataUpdatesSubscriber()?.onMessengerAccounts([resp.data]);
        return {
          ok: true,
          messengerAccount: resp.data,
        };
      } else {
        return {
          ok: false,
          messengerAccount: undefined,
        };
      }
    } catch (err) {
      return {
        ok: false,
        messengerAccount: undefined,
      };
    }
  }
}
