import React, { FormEvent } from "react";
import { useAuth } from "common/src/context";
import styled from "styled-components";
import { OnboardingStyles } from "./common";
import { ReactComponent as ZamiLogo } from "assets/logo.svg";
import Box from "ui/Box";
import Text from "ui/Text";
import { useHistory } from "react-router-dom";
import Field from "ui/Field";
import Input from "ui/Input";
import Button from "ui/Button";
import posthog from "posthog-js";
import { useApiClient } from "common";

const Link = styled(Text)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const Wrapper = styled.div`
  max-width: 22.5rem;
  margin: 0 auto;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

export default function Signup() {
  const apiClient = useApiClient();
  const { setAuthToken } = useAuth();
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errorCode, setErrorCode] = React.useState<
    "email_already_exists" | "password_too_small"
  >();
  const [signupState, setSignupState] = React.useState<
    "default" | "loading" | "error"
  >("default");

  const history = useHistory();

  React.useEffect(() => {
    posthog.capture("Signup Loaded");
  }, []);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setErrorCode(undefined);
    setSignupState("loading");
    const result = await apiClient.onboarding.signup({
      email,
      name,
      password,
    });
    if (result.ok) {
      (window as any).fbq?.("track", "Lead");
      try {
        setAuthToken(result.token!);
      } catch (err) {
        setSignupState("error");
      }
    } else {
      if (result.error_code === "email_already_registerd") {
        setErrorCode("email_already_exists");
        setSignupState("error");
      } else if (result.error_code === "password_too_small") {
        setErrorCode("password_too_small");
        setSignupState("error");
      } else {
        setSignupState("error");
      }
    }
  };

  return (
    <div>
      <OnboardingStyles />
      <Box
        paddingTop="1.5rem"
        paddingBottom="1.5rem"
        paddingRight="2rem"
        flex
        justifyContent="flex-end"
      >
        <Text size="text_md" color="gray600">
          ¿Ya tienes una cuenta?{" "}
          <Link
            inline
            size="text_md"
            color="primary700"
            weight="semibold"
            onClick={() => history.push("/login")}
          >
            Inicia sesión
          </Link>
        </Text>
      </Box>
      <form onSubmit={handleSubmit}>
        <Wrapper>
          <Box flex justifyContent="center" marginBottom="1rem">
            <ZamiLogo height={48} width={48} />
          </Box>
          <Text
            size="display_sm"
            weight="semibold"
            marginBottom="0.75rem"
            flex
            justifyContent="center"
            color="gray900"
          >
            Crea tu cuenta debajo
          </Text>

          <Text
            size="text_md"
            marginBottom="2rem"
            color="gray600"
            style={{ textAlign: "center" }}
          >
            Utilizaras estas credenciales para acceder a nuestra plataforma
          </Text>

          <Field label="Correo electrónico">
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="correo@zamiapp.com"
              required
            />
          </Field>

          <Field label="Nombre completo">
            <Input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Luis del Giudice"
              required
            />
          </Field>

          <Field label="Contraseña">
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Field>

          {signupState === "error" && errorCode === "email_already_exists" && (
            <Text size="text_md" color="error500" marginBottom="1.5rem">
              Ya existe una cuenta con este correo electrónico.
            </Text>
          )}

          {signupState === "error" && errorCode === "password_too_small" && (
            <Text size="text_md" color="error500" marginBottom="1.5rem">
              La contraseña debe tener almenos 6 caracteres.
            </Text>
          )}

          {signupState === "error" && !errorCode && (
            <Text size="text_md" color="error500" marginBottom="1.5rem">
              Ocurrió un error al registrarte. Porfavor contactate con un
              administrador.
            </Text>
          )}

          <Button
            size="md"
            block
            style={{ marginTop: "0.5rem" }}
            type="submit"
            isLoading={signupState === "loading"}
          >
            Iniciar prueba gratis
          </Button>
        </Wrapper>
      </form>
    </div>
  );
}
