import React from "react";
import dayjs from "dayjs";
import { Message, MessageChannel } from "common/src/types";
import styled from "styled-components";
import Button from "ui/Button";
import Text from "ui/Text";
import { ComposerMessage } from "common/src/client/messages";
import Composer, { ComposerWrapper } from "./composer";
import { useCommandBar } from "components/commandBar";
import { useAppData } from "common/src/lib/appData";
import { IDBConversation } from "common/src/dexie/conversations";
import { useApiClient } from "common";
import { useIDBConversationActions } from "common/src/dexie/conversations";

const WhatsappTemplateComposerWrapper = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WhatsappTemplateComposer = ({ contactId }: { contactId: string }) => {
  const commandBar = useCommandBar();
  return (
    <ComposerWrapper>
      <WhatsappTemplateComposerWrapper>
        <Text size="text_md" weight="semibold">
          Esta conversación no está activa
        </Text>

        <Text
          size="text_sm"
          color="gray600"
          style={{ margin: "0.5rem 0rem", marginBottom: "1rem" }}
        >
          Whatsapp requiere enviar plantillas de mensajes cuando el contacto no
          ha escrito en las últimas 24 horas.
        </Text>

        <Button
          size="md"
          title="Usar plantilla de mensaje"
          kind="primary"
          icon="file"
          onClick={() =>
            commandBar.openCommandBarForWhatsappTemplate(contactId)
          }
        >
          Usar plantilla de mensaje
        </Button>
      </WhatsappTemplateComposerWrapper>
    </ComposerWrapper>
  );
};

const ChatComposer = React.forwardRef(
  (
    {
      conversation,
      quotedMessage,
      onQuotedMessageClear,
    }: {
      quotedMessage?: Message;
      conversation: IDBConversation;
      onQuotedMessageClear?: () => void;
    },
    ref
  ) => {
    const initialChannels = React.useRef<Record<string, MessageChannel>>({});
    const appData = useAppData();
    const apiClient = useApiClient();
    const convActions = useIDBConversationActions();
    const conversationData = conversation.data;
    const [channel, setChannel] = React.useState<MessageChannel>(
      conversationData.channel
    );

    const needsToSendTemplatedWhatsappMessage = React.useMemo(() => {
      return (
        (channel as any) === "whatsapp" &&
        Boolean(
          !conversationData.lastIncomingWhatsappMessage ||
            dayjs(conversationData.lastIncomingWhatsappMessage.time).isBefore(
              dayjs().subtract(1, "day")
            )
        )
      );
    }, [conversationData.lastIncomingWhatsappMessage, channel]);

    React.useEffect(() => {
      if (!initialChannels.current[conversation.id]) {
        initialChannels.current[conversation.id] = conversationData.channel;
      }

      setChannel(initialChannels.current[conversation.id]);
    }, [conversation.id, conversationData]);

    const sendMessage = async (message: ComposerMessage) => {
      if (channel === undefined) {
        throw new Error("Channel is undefined");
      }

      apiClient.messagesClient?.sendOutgoingMessage({
        channel,
        workspaceAuthorId: appData.workspaceMemberSelf.id,
        contactId: conversationData.contact.id,
        conversationId: conversation.id,
        message,
      });

      if (conversationData.snoozedUntil) {
        convActions.resumeConversation(conversation);
      }

      if (conversationData.assignee === "") {
        convActions.assignConversation(
          conversation,
          `wm:${appData.workspaceMemberSelf.id}`
        );
      }

      // await updateConversation(idb, conversation.id, updates);
    };

    if (needsToSendTemplatedWhatsappMessage) {
      return (
        <WhatsappTemplateComposer contactId={conversation.data.contact.id} />
      );
    }

    return (
      <Composer
        channel={channel}
        contact={conversationData.contact}
        contactId={conversationData.contact.id}
        channelId={conversationData.channelId}
        onSendMessage={sendMessage}
        onQuotedMessageClear={onQuotedMessageClear}
        ref={ref}
        quotedMessage={
          quotedMessage
            ? {
                id: quotedMessage.id,
                data: quotedMessage,
              }
            : undefined
        }
      />
    );
  }
);

export default ChatComposer;
