import React from "react";
import Dexie, { Table } from "dexie";
import { IDBContact } from "./contacts";
import { IDBConversation } from "./conversations";
import { IDBMessage } from "./messages";
import { IDBNote } from "./notes";

export class ZamiDexie extends Dexie {
  contacts!: Table<IDBContact>;
  conversations!: Table<IDBConversation>;
  messages!: Table<IDBMessage>;
  notes!: Table<IDBNote>;
  constructor(workspaceId: string) {
    super(`zami-chat-${workspaceId}`);

    this.version(1).stores({
      contacts: "id, lastUpdateAt",
      conversations:
        "id, [contactId+channelId], [assignee+status], [lastMessageDirection+status], [assignee+lastMessageDirection+status], lastUpdateAt",
      messages: "id, contactId, lastUpdateAt",
      notes: "id, contactId, lastUpdateAt",
    });
  }
}

export const IDBContext = React.createContext<ZamiDexie>(undefined as any);

export function useIDB() {
  return React.useContext(IDBContext);
}
