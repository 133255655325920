import React from "react";
import qs from "querystring";
import posthog from "posthog-js";
import {
  Redirect,
  RouteComponentProps,
  useHistory,
  useLocation,
} from "react-router-dom";
import Conversation from "./Conversation";
import ConversationList from "./ConversationList";
import Inboxes from "./Inboxes";
import { useAppData } from "common/src/lib/appData";
import { useChat, wrapWithChatContext } from "./context";
import NewConversation from "./NewConversation";
import ContactProfile from "./ContactProfile";
import { useMediaQuery } from "react-responsive";
import InboxEmptyState from "./InboxEmptyState";
import { useConversationList } from "common/src/dexie/conversations";

const Chat = ({
  match,
}: {} & RouteComponentProps<{
  inbox: string;
  selectedConversationId?: string;
}>) => {
  const shouldInboxesFloat = useMediaQuery({ query: "(max-width: 1366px)" });
  const [inboxesOpen, setInboxesOpen] = React.useState(!shouldInboxesFloat);
  const history = useHistory();
  const location = useLocation();
  const inboxName = match.params.inbox;
  const appData = useAppData();
  const chat = useChat();

  const shouldWaitForConvToAppear = React.useMemo(() => {
    if (location.search) {
      const params = qs.parse(location.search.replace("?", ""));
      return Boolean(params.wait);
    }

    return false;
  }, [location.search]);

  const receivedFirstSnapshot = React.useRef<boolean>(false);

  const myWorkspaceMember = React.useMemo(() => {
    return appData.workspaceMemberSelf;
  }, [appData.workspaceMemberSelf]);

  const isNewConversation =
    match.params.selectedConversationId?.includes("new:conv");

  const { conversations } = useConversationList(inboxName, {
    status: chat.conversationsStatusFilter,
    order: chat.conversationsOrderFilter,
  });

  const conversation = conversations.find((conv) =>
    match?.params.selectedConversationId
      ? conv.id === match.params.selectedConversationId
      : undefined
  );

  const conversationLastMessage = conversation?.data.lastMessage?.channel;

  React.useEffect(() => {
    if (conversation?.id) {
      posthog.capture("Conversation Viewed", {
        conversationId: conversation.id,
        channel: conversationLastMessage,
      });
    }
  }, [conversation?.id, conversationLastMessage]);

  React.useEffect(() => {
    if (
      !conversation &&
      conversations.length > 0 &&
      !isNewConversation &&
      !shouldWaitForConvToAppear
    ) {
      history.push(`/chat/${inboxName}/${conversations[0].id}`);
    }
  }, [
    conversation,
    history,
    conversations,
    inboxName,
    isNewConversation,
    shouldWaitForConvToAppear,
  ]);

  const allowedInboxes = ["inbox", "pendingResponse"];

  if (
    appData.permissions.canSeeAllConversations ||
    appData.permissions.canSeeUnassignedConversations
  ) {
    allowedInboxes.push("unassigned");
  }

  if (
    !allowedInboxes.includes(inboxName) &&
    inboxName.includes("assignee-") &&
    !appData.permissions.canSeeAllConversations &&
    inboxName !== `assignee-${myWorkspaceMember?.id}`
  ) {
    return <Redirect to="/chat" />;
  }

  const newConvParams = isNewConversation
    ? match.params.selectedConversationId!.replace("new:conv:", "").split(":")
    : undefined;

  return (
    <>
      <Inboxes
        inbox={inboxName}
        open={inboxesOpen}
        selectedConversationId={match.params.selectedConversationId}
        shouldFloat={shouldInboxesFloat}
        onOpenChange={(open) => setInboxesOpen(open)}
      />
      <ConversationList
        conversations={conversations}
        onInboxesToggle={() => setInboxesOpen((o) => !o)}
        shouldInboxesFloat={shouldInboxesFloat}
        selectedConversationId={match.params.selectedConversationId}
        inbox={inboxName}
      />
      {newConvParams && (
        <NewConversation
          conversations={conversations}
          contactId={newConvParams[0]}
          channelId={newConvParams[1]}
        />
      )}

      {!isNewConversation && conversation && (
        <Conversation conversation={conversation} />
      )}

      {!isNewConversation && !conversation && receivedFirstSnapshot.current && (
        <InboxEmptyState />
      )}

      {conversation && (
        <ContactProfile contactId={conversation.data.contact.id} />
      )}
    </>
  );
};

export default wrapWithChatContext(Chat);
