import { AxiosInstance } from "axios";
import { ContactNote } from "../types";

export default class NotesClient {
  constructor(private readonly axios: AxiosInstance) {}

  async createNote(contactId: string, body: string) {
    const response = await this.axios.post<ContactNote>("/notes", {
      contactId,
      body,
    });

    if (response.status !== 201) {
      throw new Error("Failed to create note");
    }

    return response.data;
  }

  async fetchNotes(params: { before?: string; limit: number }) {
    const response = await this.axios.get<ContactNote[]>("/notes", {
      params,
    });

    if (response.status !== 200) {
      throw new Error("Failed to fetch notes");
    }

    return response.data;
  }

  async deleteNote(noteId: string) {
    const response = await this.axios.delete(`/notes/${noteId}`);

    if (response.status !== 200) {
      throw new Error("Failed to delete note");
    }
  }
}
