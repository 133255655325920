import { AxiosInstance } from "axios";
import { SavedReply } from "../types";
import { DataUpdateSubscriber } from "../client";

export default class SavedRepliesClient {
  constructor(
    private readonly axios: AxiosInstance,
    private readonly dataUpdatesSubscriber: () =>
      | DataUpdateSubscriber
      | undefined
  ) {}

  async createSavedReply(savedReplyPayload: {
    body: string;
    name: string;
    type: string;
    fileId?: string | undefined;
  }) {
    const response = await this.axios.post<SavedReply>(
      "/saved-replies",
      savedReplyPayload
    );

    if (response.status !== 201) {
      throw new Error("Failed to create saved reply");
    }

    this.dataUpdatesSubscriber()?.onSavedReplies([response.data]);
  }

  async deleteSavedReply(id: string) {
    const response = await this.axios.delete(`/saved-replies/${id}`);

    if (response.status !== 200) {
      throw new Error("Failed to delete saved reply");
    }

    this.dataUpdatesSubscriber()?.onSavedRepliesDeleted([id]);
  }
}
