import { AxiosInstance } from "axios";
import ZamiWebsocketClient, { ConversationMessages } from "./socket";
import { ObjectId } from "bson";
import { Message, MessageChannel, ZamiFile } from "../types";

interface ComposerBaseMessage {
  quotedMessage?: {
    id: string;
    data: Message;
  };
}

export interface ComposerFile {
  name: string;
  size: number;
  type: string;
}

export interface ComposerTextMessage extends ComposerBaseMessage {
  type: "text";
  body: string;
}

export interface ComposerFileMessage extends ComposerBaseMessage {
  type: "file";
  file: ComposerFile;
  zamiFile: ZamiFile;
}

export interface ComposerImageMessage extends ComposerBaseMessage {
  type: "image";
  file: ComposerFile;
  zamiFile: ZamiFile;
  caption?: string;
}

export interface ComposerAudioMessage extends ComposerBaseMessage {
  type: "audio";
  blob: Blob;
  mimeType: string;
  duration: number;
  zamiFile: ZamiFile;
}

export type ComposerMessage =
  | ComposerTextMessage
  | ComposerAudioMessage
  | ComposerFileMessage
  | ComposerImageMessage;

interface SendOutgoingMessageParams {
  channel: MessageChannel;
  contactId: string;
  message: ComposerMessage;
  workspaceAuthorId: string;
  conversationId: string;
}

export default class MessagesClient {
  constructor(
    private readonly axios: AxiosInstance,
    private readonly websocketClient: ZamiWebsocketClient
  ) {}

  async sendOutgoingMessage(params: SendOutgoingMessageParams) {
    const partialData = {
      id: new ObjectId().toHexString(),
      time: new Date().toISOString(),
      contactId: params.contactId,
      lastUpdateAt: new Date().toISOString(),
      workspaceAuthorId: params.workspaceAuthorId,
      isSystem: false,
    };

    let messageData: Message | undefined = undefined;

    if (params.message.type === "text") {
      messageData = {
        ...partialData,
        message: {
          channel: params.channel,
          type: params.message.type,
          body: params.message.body,
          direction: "outgoing",
          quotedMessage: params.message.quotedMessage?.data,
        },
      };
    } else if (params.message.type === "image") {
      messageData = {
        ...partialData,
        message: {
          channel: params.channel,
          type: params.message.type,
          direction: "outgoing",
          file: params.message.zamiFile,
          body: params.message.caption,
          quotedMessage: params.message.quotedMessage?.data,
        },
      };
    } else if (params.message.type === "file") {
      messageData = {
        ...partialData,
        message: {
          channel: params.channel,
          type: params.message.type,
          direction: "outgoing",
          file: params.message.zamiFile,
          quotedMessage: params.message.quotedMessage?.data,
        },
      };
    } else if (params.message.type === "audio") {
      messageData = {
        ...partialData,
        message: {
          channel: params.channel,
          type: params.message.type,
          direction: "outgoing",
          file: params.message.zamiFile,
          quotedMessage: params.message.quotedMessage?.data,
        },
      };
    }

    if (messageData) {
      const response = await this.axios.post("/messages/send", {
        conversationId: params.conversationId,
        message: messageData,
      });
    }
  }

  async fetchMessages(params?: { before?: string; limit: number }) {
    const response = await this.axios.get<Message[]>("/messages", {
      params: {
        before: params?.before,
        limit: params?.limit,
      },
    });

    if (response.status !== 200) {
      throw new Error("Failed to fetch messages");
    }

    return response.data;
  }

  subscribeToConversation(
    conversationId: string,
    subscriber: (msg: ConversationMessages) => void
  ) {
    this.websocketClient.conversationSubscriber = subscriber;

    this.websocketClient.ws?.send(
      JSON.stringify({
        type: "subscribe_to_conversation",
        conversationId,
      })
    );

    return () => {
      this.websocketClient.conversationSubscriber = undefined;
      this.websocketClient.ws?.send(
        JSON.stringify({
          type: "unsubscribe_from_conversations",
        })
      );
    };
  }
}
