import React from "react";
import styled from "styled-components";
import Box from "ui/Box";
import { ReactComponent as MessengerLogo } from "assets/channelLogos/messenger.svg";
import {
  BreadcrumbChevron,
  BreadcrumbCurrentPage,
  BreadcrumbLink,
  Breadcrumbs,
} from "ui/Breadcrumbs";
import Button from "ui/Button";
import Text from "ui/Text";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { FacebookPage } from "common/src/types";
import ChannelAssigner from "./ChannelAssigner";

const Wrapper = styled.div`
  max-width: 70rem;
  margin: 0 auto;
  flex: 1;
  overflow: auto;
`;

const Heading = styled.div`
  flex: 1;
  padding-bottom: 1.5rem;
  padding-top: 2rem;
  border-bottom: solid 1px ${(props) => props.theme.colors.gray200};
  margin-bottom: 2rem;
`;

const SettingsBox = styled.div`
  border-radius: 12px;
  border: solid 1px ${(props) => props.theme.colors.gray200};
  padding: 2rem 1.5rem;
`;

const MessengerChannelConfigure = (
  props: RouteComponentProps<{ fbPageId: string }>
) => {
  const history = useHistory();
  const [isDisconnecting, setDisconnecting] = React.useState(false);
  const [facebookPage] = React.useState<
    Exclude<FacebookPage, "fbPageAccessToken"> & { id: string }
  >();

  if (facebookPage === undefined) {
    return null;
  }

  return (
    <Wrapper>
      <Heading>
        <Breadcrumbs marginBottom="20px">
          <BreadcrumbLink to="/settings/channels">Canales</BreadcrumbLink>
          <BreadcrumbChevron />
          <BreadcrumbCurrentPage>Messenger</BreadcrumbCurrentPage>
          <BreadcrumbChevron />
          <BreadcrumbCurrentPage>{facebookPage.name}</BreadcrumbCurrentPage>
        </Breadcrumbs>
        <Box flex alignItems="center">
          <MessengerLogo />
          <Box marginLeft="20px" cols={1}>
            <Text size="display_xs" weight="semibold">
              Configuración de {facebookPage.name}
            </Text>
          </Box>
        </Box>
      </Heading>

      <ChannelAssigner accountId={facebookPage.id} accountType="page" />

      <SettingsBox>
        <Text size="text_xl" weight="semibold" marginBottom="1rem">
          Desconectar la cuenta
        </Text>
        <Text
          size="text_md"
          color="gray600"
          marginBottom="1.5rem"
          style={{ maxWidth: "40rem" }}
        >
          Al desconectar la cuenta no podrás recibir ni responder a los mesajes
          directos de messenger. Los contactos o mensajes existentes no se
          borrarán
        </Text>
        <Button
          size="md"
          kind="secondary"
          disabled={isDisconnecting}
          onClick={async () => {
            setDisconnecting(true);
            // const result = await functions.disconnectFacebookPage({
            //   facebookPageId: facebookPage.id,
            // });
            const result = null as any;

            if (result.data.ok) {
              history.push("/settings/channels");
            }
          }}
        >
          {isDisconnecting ? "Desconectando" : "Desconectar canal"}
        </Button>
      </SettingsBox>
    </Wrapper>
  );
};

export default MessengerChannelConfigure;
