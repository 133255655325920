import {
  FacebookPage,
  InstagramAccount,
  Label,
  User,
  Team,
  WhatsappWebAccount,
  WorkspaceMember,
  WorkspaceRole,
  Workspace,
  SavedReply,
} from "common/src/types";
import React from "react";
import {
  ApiClientContext,
  AuthTokenContext,
  BaseUrlContext,
  useAuth,
} from "common/src/context";
import ZamiAPIClient from "../client";
import { IDBContext, ZamiDexie } from "../dexie/idb";

export interface AppDataCtxInterface {
  user: User;
  workspace: Workspace;
  workspaceMembers: Record<string, WorkspaceMember>;
  workspaceMemberSelf: WorkspaceMember;
  permissions: WorkspaceRole["permissions"];
  connectionState: string | undefined;
  roles: Record<string, WorkspaceRole>;
  whatsappWebAccounts: Record<string, WhatsappWebAccount>;
  instagramAccounts: Record<string, InstagramAccount>;
  savedReplies: Record<string, SavedReply>;
  facebookPages: Record<string, FacebookPage>;
  teams: Record<string, Team>;
  labels: Record<string, Label>;
}

const AppDataCtx = React.createContext<AppDataCtxInterface>({
  user: {} as any,
  workspace: {} as any,
  workspaceMembers: {},
  workspaceMemberSelf: null as any,
  permissions: {} as any,
  roles: {},
  instagramAccounts: {},
  connectionState: undefined,
  savedReplies: {},
  whatsappWebAccounts: {},
  facebookPages: {},
  teams: {},
  labels: {},
});

const AppData = ({ children }: { children: React.ReactNode }) => {
  const [workspaceMembers, setWorkspaceMembers] = React.useState<
    Record<string, WorkspaceMember>
  >({});
  const [connectionState, setConnectionState] = React.useState<string>();
  const auth = useAuth();
  const [labels, setLabels] = React.useState<Record<string, Label>>({});
  const [workspace, setWorkspace] = React.useState<Workspace>();
  const authToken = React.useContext(AuthTokenContext);
  const baseUrl = React.useContext(BaseUrlContext);
  const [idb, setIdb] = React.useState<ZamiDexie>();
  const idbRef = React.useRef<ZamiDexie>();
  const [user, setUser] = React.useState<User>();
  const [loaded, setLoaded] = React.useState(false);
  const [workspaceRoles, setWorkspaceRoles] =
    React.useState<Record<string, WorkspaceRole>>();
  const [workspaceTeams, setWorkspaceTeams] =
    React.useState<Record<string, Team>>();
  const [instagramAccounts, setInstagramAccounts] =
    React.useState<Record<string, InstagramAccount>>();
  const [whatsappWebAccounts, setWhatsappWebAccounts] =
    React.useState<Record<string, WhatsappWebAccount>>();
  const [facebookPages, setFacebookPages] =
    React.useState<Record<string, FacebookPage>>();
  const [savedReplies, setSavedReplies] =
    React.useState<Record<string, SavedReply>>();

  const apiClient = React.useMemo(() => {
    return new ZamiAPIClient(baseUrl, authToken.authToken);
  }, [authToken.authToken, baseUrl]);

  React.useEffect(() => {
    if (workspace) {
      apiClient.setWorkspaceId(workspace.id);
    }
  }, [apiClient, workspace?.id]);

  React.useEffect(() => {
    const killWebsocketConnection = apiClient.startWebsocketConnection(
      {
        onSelf: (self) => {
          setUser(self);
        },
        onMessengerAccounts: (messengerAccounts) => {
          setFacebookPages((existing) =>
            messengerAccounts.reduce((acc, curr) => {
              return {
                ...acc,
                [curr.id]: curr,
              };
            }, existing as Record<string, FacebookPage>)
          );
        },
        onLabels: (labels) => {
          setLabels((existing) =>
            labels.reduce((acc, curr) => {
              return {
                ...acc,
                [curr.id]: curr,
              };
            }, existing as Record<string, Label>)
          );
        },
        onLabelsDeleted: (ids) => {
          setLabels((curr) => {
            const copy = { ...curr };
            ids.forEach((id) => {
              delete copy[id];
            });
            return copy;
          });
        },
        onInstagramAccounts: (instagramAccounts) => {
          setInstagramAccounts((existing) =>
            instagramAccounts.reduce((acc, curr) => {
              return {
                ...acc,
                [curr.id]: curr,
              };
            }, existing as Record<string, InstagramAccount>)
          );
        },
        onWorkspace: (workspace) => {
          if (!idbRef.current) {
            const db = new ZamiDexie(workspace.id);
            setIdb(db);
            (window as any).idb = db;
            idbRef.current = db;
          }
          setWorkspace(workspace);
        },
        onInitialLoadCompleted: () => {
          setLoaded(true);
        },
        onWhatsappWebAccounts: (whatsappWebAccounts) => {
          setWhatsappWebAccounts((existing) =>
            whatsappWebAccounts.reduce((acc, curr) => {
              return {
                ...acc,
                [curr.id]: curr,
              };
            }, existing)
          );
        },
        onWorkspaceMembers: (workspaceMembers) => {
          setWorkspaceMembers((existing) =>
            workspaceMembers.reduce((acc, curr) => {
              return {
                ...acc,
                [curr.id]: curr,
              };
            }, existing)
          );
        },
        onWorkspaceMembersDeleted: (ids) => {
          setWorkspaceMembers((curr) => {
            const copy = { ...curr };
            ids.forEach((id) => {
              delete copy[id];
            });
            return copy;
          });
        },
        onTeams: (teams) => {
          setWorkspaceTeams((existing) =>
            teams.reduce((acc, curr) => {
              return {
                ...acc,
                [curr.id]: curr,
              };
            }, existing)
          );
        },
        onTeamsDeleted: (ids) => {
          setWorkspaceTeams((curr) => {
            const copy = { ...curr };
            ids.forEach((id) => {
              delete copy[id];
            });
            return copy;
          });
        },
        getLastUpdatedContact: async () => {
          if (!idbRef.current) {
            throw new Error("IDB not initialized");
          }

          const res = await idbRef
            .current!.contacts.orderBy("lastUpdateAt")
            .last();

          return res?.data;
        },
        onSavedReplies: (savedReplies) => {
          setSavedReplies((curr) =>
            savedReplies.reduce((acc, curr) => {
              return {
                ...acc,
                [curr.id]: curr,
              };
            }, curr)
          );
        },
        onSavedRepliesDeleted: (ids) => {
          setSavedReplies((curr) => {
            const copy = { ...curr };
            ids.forEach((id) => {
              delete copy[id];
            });
            return copy;
          });
        },
        getLastUpdatedConversation: async () => {
          const res = await idbRef
            .current!.conversations.orderBy("lastUpdateAt")
            .last();
          return res?.data;
        },
        onRoles: (roles) => {
          setWorkspaceRoles((existing) =>
            roles.reduce((acc, curr) => {
              return {
                ...acc,
                [curr.id]: curr,
              };
            }, existing as Record<string, WorkspaceRole>)
          );
        },
      },
      {
        onConnectionStateChange: (state) => {
          setConnectionState(state);
        },
        onFail: () => {
          auth.logout?.();
        },
      }
    );

    return () => {
      setLoaded(false);
      killWebsocketConnection();
    };
  }, [
    apiClient,
    setLoaded,
    setUser,
    setIdb,
    setWorkspace,
    setWorkspaceMembers,
  ]);

  const workspaceMemberSelf = React.useMemo(() => {
    const self = Object.values(workspaceMembers || {}).find(
      (member) => member.userId === user?.id
    )!;

    return self;
  }, [workspaceMembers, user]);

  const permissions = React.useMemo(() => {
    const isSuperuser = workspaceMemberSelf?.isSuperuser;
    const role = workspaceRoles?.[workspaceMemberSelf?.roleId ?? ""];
    const permissions: WorkspaceRole["permissions"] = {
      canAssignConversations: Boolean(
        isSuperuser || role?.permissions.canAssignConversations
      ),
      canSeeAllContacts: Boolean(
        isSuperuser || role?.permissions.canSeeAllContacts
      ),
      canSeeUnassignedContacts: Boolean(
        isSuperuser || role?.permissions.canSeeUnassignedContacts
      ),
      canSeeUnassignedConversations: Boolean(
        isSuperuser ||
          role?.permissions.canSeeUnassignedConversations ||
          role?.permissions.canSeeAllConversations
      ),
      canSeeAllConversations: Boolean(
        isSuperuser || role?.permissions.canSeeAllConversations
      ),
      canSeeReporting: Boolean(
        isSuperuser || role?.permissions.canSeeReporting
      ),
      canAssignContacts: Boolean(
        isSuperuser || role?.permissions.canAssignContacts
      ),
      canSeePhoneNumbers: Boolean(
        isSuperuser || role?.permissions.canSeePhoneNumbers
      ),
    };

    return permissions;
  }, [workspaceMemberSelf, workspaceRoles]);

  const ctxVal = React.useMemo(() => {
    return {
      user: user!,
      workspaceMembers: workspaceMembers ?? {},
      workspaceMemberSelf,
      permissions,
      connectionState,
      labels: labels ?? {},
      instagramAccounts: instagramAccounts ?? {},
      whatsappWebAccounts: whatsappWebAccounts ?? {},
      savedReplies: savedReplies ?? {},
      roles: workspaceRoles ?? {},
      teams: workspaceTeams ?? {},
      facebookPages: facebookPages ?? {},
      workspace: workspace!,
    };
  }, [
    user,
    workspaceMembers,
    workspaceMemberSelf,
    permissions,
    workspace,
    labels,
    workspaceRoles,
    instagramAccounts,
    connectionState,
    workspaceTeams,
    whatsappWebAccounts,
    facebookPages,
    savedReplies,
    workspace,
  ]);

  if (workspaceMembers === undefined) {
    return null;
  }

  if (!loaded) {
    return null;
  }

  if (!workspaceMemberSelf) {
    throw new Error("User not found in workspace members");
  }

  return (
    <IDBContext.Provider value={idb!}>
      <ApiClientContext.Provider value={apiClient}>
        <AppDataCtx.Provider value={ctxVal}>{children}</AppDataCtx.Provider>
      </ApiClientContext.Provider>
    </IDBContext.Provider>
  );
};

export const useAppData = () => React.useContext(AppDataCtx);

export default AppData;
