import { Contact } from "common/src/types";
import React from "react";
import { AssigneePicker } from "./assignConversation";

const AssignContactCommandWindow = ({
  contact,
  onContactAssigned,
}: {
  contact: { id: string; data: Contact };
  onContactAssigned: () => void;
}) => {
  const assignContact = React.useCallback(
    async (assignee: string[]) => {
      // await db.contacts.doc(contact.id).update({
      //   assignee: assignee[0],
      // });
      onContactAssigned();
    },
    [onContactAssigned]
  );

  return (
    <AssigneePicker
      assignee={contact.data.assignee}
      onAssigneePicked={assignContact}
    />
  );
};

export default AssignContactCommandWindow;
