import OutsideClickHandler from "react-outside-click-handler";
import Picker from "@emoji-mart/react";
import React, { FormEvent } from "react";
import styled from "styled-components";
import Box from "ui/Box";
import Button from "ui/Button";
import { PickerWindow, PickerWrapper } from "ui/emojis";
import Header from "ui/Header";
import IconButton from "ui/IconButton";
import Input from "ui/Input";
import Modal from "ui/Modal";
import { Table, TBody, Td, Th, THead, Tr } from "ui/Table";
import { useAppData } from "common/src/lib/appData";
import Dropdown from "ui/Dropdown";
import Icon from "ui/Icon";
import { getRandomEmoji } from "lib/utils";
import { Team } from "common/src/types";
import { useApiClient } from "common";

const isMac = navigator.platform.includes("Mac");

const Wrapper = styled.div`
  flex: 1;
  padding: 0rem 2rem;
`;

const DropdownTrigger = styled.button`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  height: 40px;
  width: 40px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.gray50};
  }
`;

const TeamModal = (props: {
  open: boolean;
  team?: Team | undefined;
  onOpenChange: (newOpen: boolean) => void;
}) => {
  const [emojiPickerOpen, setEmojiPickerOpen] = React.useState(false);
  const [teamName, setTeamName] = React.useState(props.team?.name ?? "");
  const apiClient = useApiClient();
  const [emoji, setEmoji] = React.useState(
    props.team?.emoji ?? getRandomEmoji()
  );

  React.useEffect(() => {
    if (props.open && props.team === undefined) {
      setEmoji(getRandomEmoji());
    } else if (props.open && props.team !== undefined) {
      setEmoji(props.team.emoji);
      setTeamName(props.team.name);
    } else {
      setTeamName("");
    }
  }, [props.open, props.team]);

  const onOpenChange = props.onOpenChange;

  const handleCloseModal = React.useCallback(() => {
    onOpenChange(false);
  }, [onOpenChange]);

  const handleSubmit = React.useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      if (props.team === undefined) {
        await apiClient.teams.createTeam({
          name: teamName,
          emoji,
        });
      } else {
        // TODO: implement
        await apiClient.teams.updateTeam(props.team.id, {
          name: teamName,
          emoji,
        });
      }
      handleCloseModal();
    },
    [props, handleCloseModal, teamName, emoji, apiClient]
  );

  return (
    <Modal open={props.open} onOpenChange={props.onOpenChange}>
      <Modal.FeaturedIcon icon="usersPlus" />
      <Modal.Title>
        {props.team ? "Modificar equipo " : "Crea un equipo"}
      </Modal.Title>
      <form onSubmit={handleSubmit}>
        <Box flex marginTop="1.25rem">
          <PickerWrapper>
            <Button
              kind="secondary"
              size="md"
              type="button"
              style={{
                width: 40,
                height: 40,
                boxSizing: "border-box",
                display: "flex",
                justifyContent: "center",
              }}
              onClick={() => setEmojiPickerOpen((o) => !o)}
            >
              {emoji}
            </Button>
            {emojiPickerOpen && (
              <OutsideClickHandler
                onOutsideClick={() => setEmojiPickerOpen(false)}
              >
                <PickerWindow>
                  <Picker
                    theme="light"
                    set={isMac ? "native" : "twitter"}
                    onEmojiSelect={(e: any) => {
                      setEmoji(e.native);
                      setEmojiPickerOpen(false);
                    }}
                  />
                </PickerWindow>
              </OutsideClickHandler>
            )}
          </PickerWrapper>

          <Box marginLeft="0.5rem" cols={1}>
            <Input
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
              autoFocus
              required
            />
          </Box>
        </Box>

        <Box flex marginTop="1.25rem">
          <Box cols={1}>
            <Button
              kind="secondary"
              size="md"
              type="button"
              onClick={handleCloseModal}
              block
            >
              Cancelar
            </Button>
          </Box>
          <Box cols={1} marginLeft="0.75rem">
            <Button kind="primary" size="md" type="submit" block>
              {props.team ? "Modificar" : "Crear"}
            </Button>
          </Box>
        </Box>
      </form>
    </Modal>
  );
};

const TeamsList = () => {
  const [createTeamModalOpen, setCreateTeamModalOpen] = React.useState(false);
  const [teamToEdit, setTeamToEdit] = React.useState<Team>();
  const [teamToDelete, setTeamToDelete] = React.useState<Team>();
  const appData = useAppData();
  return (
    <>
      <Wrapper>
        <Header
          title="Equipos"
          subtitle="Los usarios tendrán acceso a las conversaciones y contactos asignadas a los equipos que pertenezcan."
          badge={null}
          actions={
            <Button
              icon="plus"
              size="md"
              onClick={() => setCreateTeamModalOpen(true)}
            >
              Nuevo equipo
            </Button>
          }
        />

        <Table>
          <THead>
            <Tr>
              <Th>Nombre</Th>
              <Th>Miembros</Th>
              <Th></Th>
            </Tr>
          </THead>

          <TBody>
            {Object.values(appData.teams).map((team) => {
              const memberCount = Object.values(
                appData.workspaceMembers
              ).filter((member) => member.teams?.includes(team.id)).length;
              return (
                <Tr>
                  <Td>
                    {team.emoji} {team.name}
                  </Td>
                  <Td>
                    {memberCount} {memberCount === 1 ? "miembro" : "miembros"}
                  </Td>
                  <Td>
                    <Box flex justifyContent="flex-end">
                      <Dropdown
                        trigger={
                          <DropdownTrigger>
                            <IconButton size="sm" icon="dotsVertical" />
                          </DropdownTrigger>
                        }
                      >
                        <Dropdown.Item onClick={() => setTeamToEdit(team)}>
                          <div className="LeftSlot">
                            <Icon icon="edit" stroke="gray700" />
                          </div>
                          Editar equipo
                        </Dropdown.Item>

                        <Dropdown.Item onClick={() => setTeamToDelete(team)}>
                          <div className="LeftSlot">
                            <Icon icon="trash" />
                          </div>
                          Eliminar equipo
                        </Dropdown.Item>
                      </Dropdown>
                    </Box>
                  </Td>
                </Tr>
              );
            })}
          </TBody>
        </Table>
      </Wrapper>
      <DeleteTeamModal
        onOpenChange={() => setTeamToDelete(undefined)}
        team={teamToDelete}
      />
      <TeamModal
        open={createTeamModalOpen || teamToEdit !== undefined}
        onOpenChange={(newOpen) => {
          setCreateTeamModalOpen(newOpen);

          if (newOpen === false) {
            setTeamToEdit(undefined);
          }
        }}
        team={teamToEdit}
      />
    </>
  );
};

export const DeleteTeamModal = ({
  team,
  onOpenChange,
}: {
  team: Team | undefined;
  onOpenChange: (newOpen: boolean) => void;
}) => {
  const [submitting, setSubmitting] = React.useState(false);
  const apiClient = useApiClient();

  const handleDeactivate = React.useCallback(async () => {
    if (team !== undefined) {
      try {
        setSubmitting(true);
        debugger;
        await apiClient.teams.deleteTeam(team.id);
        onOpenChange(false);
      } catch (err) {}
      setSubmitting(false);
    }
  }, [team, onOpenChange, apiClient]);

  return (
    <Modal open={Boolean(team)} onOpenChange={onOpenChange}>
      <Modal.FeaturedIcon icon="thrash" />
      <Modal.Title>
        ¿Estás seguro de que quieres eliminar el equipo?
      </Modal.Title>
      <Box flex marginTop="2rem">
        <Box marginRight="12px" cols={1}>
          <Button
            kind="secondary"
            block
            size="md"
            onClick={() => onOpenChange(false)}
          >
            Cancelar
          </Button>
        </Box>
        <Box cols={1}>
          <Button
            kind="danger"
            block
            size="md"
            disabled={submitting}
            isLoading={submitting}
            onClick={handleDeactivate}
          >
            {submitting ? "Eliminando" : "Eliminar"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default TeamsList;
