import { AxiosInstance } from "axios";
import { Dayjs } from "dayjs";
import {
  ImageMessage,
  VideoMessage,
  FileMessage,
  ScheduledMessage,
  TextMessage,
  AudioMessage,
} from "../types";

export default class ScheduledMessagesClient {
  constructor(private axios: AxiosInstance) {}

  async createScheduledMessage(
    contactId: string,
    channelId: string,
    sendAt: Dayjs,
    message:
      | TextMessage
      | AudioMessage
      | ImageMessage
      | VideoMessage
      | FileMessage
  ) {
    const response = await this.axios.post<ScheduledMessage>(
      "/scheduled-messages",
      {
        contactId,
        channelId,
        sendAt: sendAt.toISOString(),
        message,
      }
    );

    if (response.status !== 201) {
      throw new Error("Failed to create scheduled message");
    }

    return response.data;
  }

  async listScheduledMessages(contactId: string, channelId: string) {
    const response = await this.axios.get<ScheduledMessage[]>(
      "/scheduled-messages",
      {
        params: {
          contactId,
          channelId,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to fetch scheduled messages");
    }

    return response.data;
  }

  async deleteScheduledMessage(id: string) {
    const response = await this.axios.delete(`/scheduled-messages/${id}`);

    if (response.status !== 200) {
      throw new Error("Failed to delete scheduled message");
    }
  }
}
