import React from "react";
import {
  Breadcrumbs,
  BreadcrumbChevron,
  BreadcrumbCurrentPage,
  BreadcrumbLink,
} from "ui/Breadcrumbs";
import { ReactComponent as WhatsappLogo } from "assets/channelLogos/whatsapp.svg";
import styled from "styled-components";
import Box from "ui/Box";
import Text from "ui/Text";
import Button from "ui/Button";
import config from "config";
import { WhatsappBusinessAccount } from "common/src/types";
import { useAppData } from "common/src/lib/appData";

const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  max-width: 70rem;
  padding: 0rem 2rem;
  margin: 0 auto;
`;

const Heading = styled.div`
  padding-top: 2rem;
  padding-bottom: 1.5rem;
  border-bottom: solid 1px ${(props) => props.theme.colors.gray200};
`;

const IGWrapper = styled.div`
  padding: 1rem 0rem;
  border-bottom: solid 1px ${(props) => props.theme.colors.gray200};
`;

const WhatsappSettings = () => {
  const appData = useAppData();
  const [fbAccessToken, setFbAccessToken] = React.useState<string>();
  const [loginLoading, setLoginLoading] = React.useState(false);
  const [whatsappConnectionStatuses, setWhatsappConnectionStatuses] =
    React.useState<Record<string, undefined | "loading">>({});
  const [whatsappBusinessAccounts] =
    React.useState<{ name: string; id: string }[]>();
  const [
    connectedWhatsappBusinessAccounts,
    setConnectedWhatsappBusinessAccounts,
  ] = React.useState<Record<string, WhatsappBusinessAccount>>();

  // React.useEffect(() => {
  //   if (connectedWhatsappBusinessAccounts === undefined) {
  //     (async () => {
  //       const whatsappBusinessAccountsQuery = await db.whatsappBusinessAccounts
  //         .where("workspaceId", "==", auth.workspaceId)
  //         .get();

  //       setConnectedWhatsappBusinessAccounts(
  //         whatsappBusinessAccountsQuery.docs.reduce((acc, curr) => {
  //           return {
  //             ...acc,
  //             [curr.data().accountId]: curr.data(),
  //           };
  //         }, {})
  //       );
  //     })();
  //   }
  // }, [connectedWhatsappBusinessAccounts, auth.workspaceId]);

  React.useEffect(() => {
    FB.init({
      appId: config.fbAppId,
      cookie: true,
      xfbml: true,
      version: "v16.0",
    });
  }, []);

  const handleConnect = React.useCallback(() => {
    setLoginLoading(true);
    FB.login(
      (response) => {
        const accessToken = response.authResponse?.accessToken;
        console.log(accessToken);
        setFbAccessToken(accessToken);
        setLoginLoading(false);
      },
      {
        scope:
          "business_management,whatsapp_business_management,whatsapp_business_messaging",
        extras: {
          feature: "whatsapp_embedded_signup",
          setup: {},
        },
      }
    );
  }, []);

  React.useEffect(() => {
    if (fbAccessToken) {
      (async () => {
        // const result =
        //   await functions.getWhatasppBusinessAccountsForAccessToken({
        //     accessToken: fbAccessToken,
        //   });
        // setWhatsappBusinessAccounts(result);
      })();
    }
  }, [fbAccessToken]);

  const handleConnectWba = React.useCallback(
    (whatsappBusinessAccount: { name: string; id: string }) => {
      (async () => {
        if (fbAccessToken && appData.workspace.id) {
          setWhatsappConnectionStatuses((statuses) => ({
            ...statuses,
            [whatsappBusinessAccount.id]: "loading",
          }));

          const result = null as any;

          console.log(result);

          if (result.ok) {
            setConnectedWhatsappBusinessAccounts(
              result.whatsappBusinessAccounts.reduce((acc: any, curr: any) => {
                return {
                  ...acc,
                  [curr.accountId]: curr,
                };
              }, {})
            );
          }

          setWhatsappConnectionStatuses((statuses) => ({
            ...statuses,
            [whatsappBusinessAccount.id]: undefined,
          }));
        }
      })();
    },
    [fbAccessToken, appData.workspace.id]
  );

  if (connectedWhatsappBusinessAccounts === undefined) {
    return null;
  }

  return (
    <Wrapper>
      <Heading>
        <Breadcrumbs marginBottom="20px">
          <BreadcrumbLink to="/settings/channels">Canales</BreadcrumbLink>
          <BreadcrumbChevron />
          <BreadcrumbCurrentPage>Whatsapp</BreadcrumbCurrentPage>
        </Breadcrumbs>
        <Box flex alignItems="center">
          <WhatsappLogo />
          <Box marginLeft="20px" cols={1}>
            <Text size="display_sm" weight="semibold">
              Whatsapp
            </Text>
            <Text size="text_md" color="gray600">
              Recibe y responde a tus mensajes con Zami.
            </Text>
          </Box>
          <Box flex alignItems="center">
            <Button
              icon="puzzlePiece"
              size="md"
              onClick={handleConnect}
              isLoading={loginLoading}
              disabled={loginLoading}
            >
              Conectar Whatsapp
            </Button>
          </Box>
        </Box>
      </Heading>

      {whatsappBusinessAccounts !== undefined && (
        <Box marginTop="2rem">
          <Text size="text_xl" weight="semibold" marginBottom="0.25rem">
            Selecciona la cuenta de Whatsapp que quieres conectar a Zami.
          </Text>
          <Text size="text_md" color="gray600" marginBottom="1.5rem">
            Encontramos {whatsappBusinessAccounts.length} cuentas de Whatsapp
            vinculadas con tu cuenta de Facebook.
          </Text>

          {whatsappBusinessAccounts.map((wbAccount) => {
            return (
              <IGWrapper>
                <Box flex alignItems="center">
                  <Text size="text_md" weight="semibold" cols={1}>
                    {wbAccount.name}
                  </Text>
                  <Button
                    size="sm"
                    disabled={Boolean(
                      whatsappConnectionStatuses[wbAccount.id] === "loading" ||
                        connectedWhatsappBusinessAccounts[wbAccount.id]
                    )}
                    isLoading={
                      whatsappConnectionStatuses[wbAccount.id] === "loading"
                    }
                    onClick={() => handleConnectWba(wbAccount)}
                  >
                    {connectedWhatsappBusinessAccounts[wbAccount.id]
                      ? "Conectado"
                      : "Conectar"}
                  </Button>
                </Box>
              </IGWrapper>
            );
          })}
        </Box>
      )}
    </Wrapper>
  );
};

export default WhatsappSettings;
